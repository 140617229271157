import React, { useState } from "react";
import { Grid, Box } from "@mui/material";
import CustomBreadcrumbs from "components/atoms/bread-crumbs";
import CustomCheckBox from "components/atoms/check-box";
import StoreCard from "components/molecules/store-card";
import { getAllRegions, getAllStores } from "components/particles/APIs/API";
import http from "components/particles/http";
import FilterTemplate from "components/templates/filter";
import SecondaryPageTemplate from "components/templates/secondary-page";
import { getHome, getStores } from "constants/navigation";
import { useTranslation } from "react-i18next";
import Styles from "./styles";
import AppDrawer from "components/organisms/drawer-menu";
import { AppContext } from "components/particles/providers/app-context";

function AllStoresPage() {
  const [state, setState] = useState({
    stores: [],
    regions: [],
    filter: [],
  });
  const { t } = useTranslation();
  const { dispatch } = React.useContext(AppContext);

  React.useEffect(() => {
    http
      .get(getAllStores)
      .then((response) => {
        let { list } = response.data.data;
        setState((prevState) => ({ ...prevState, stores: list }));
      })
      .catch((error) => {
        console.log(error);
      });
    http
      .get(getAllRegions)
      .then((response) => {
        let { list } = response.data.data;
        setState((prevState) => ({ ...prevState, regions: list }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const closeDrawer = () => {
    dispatch({
      type: "setOpenDrawer",
      payload: {
        open: false,
      },
    });
  };

  const onFilter = () => {
    closeDrawer();
    http
      .get(getAllStores, { params: { regions: `[${state.filter.toString()}]` } })
      .then((response) => {
        let { list } = response.data.data;
        setState((prevState) => ({ ...prevState, stores: list }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onResetFilter = () => {
    setState({ ...state, filter: [] });
    http
      .get(getAllStores)
      .then((response) => {
        let { list } = response.data.data;
        setState((prevState) => ({ ...prevState, stores: list }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSelectRegion = (regionId) => {
    let filterList = [...state.filter];
    if (!filterList.includes(regionId)) filterList.push(regionId);
    else {
      filterList = filterList.filter((id) => id !== regionId);
    }
    setState({ ...state, filter: filterList });
  };

  const StoresCardGrid = () => {
    return (
      <>
        {state.stores.map((store, index) => {
          return (
            <Grid key={index} item>
              <StoreCard
                name={store.name}
                username={store.username}
                avatar={null}
                to={`${getStores().path}/${store.username}`}
              />
            </Grid>
          );
        })}
      </>
    );
  };

  const RegionFilter = ({ withoutBorder }) => {
    return (
      <FilterTemplate
        withoutBorder={withoutBorder}
        onResetFilter={onResetFilter}
        onFilter={onFilter}
      >
        <div className="regions-filter">
          <p className="filter-header">{t("regions")}</p>
          {state.regions.map((region, index) => {
            return (
              <CustomCheckBox
                key={index}
                label={region.name}
                checked={state.filter.includes(region.id)}
                onChange={() => onSelectRegion(region.id)}
              />
            );
          })}
        </div>
      </FilterTemplate>
    );
  };

  return (
    <SecondaryPageTemplate>
      <Styles>
        <div className="breadcrumbs">
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <AppDrawer>
              <RegionFilter withoutBorder />
            </AppDrawer>
          </Box>

          <CustomBreadcrumbs routes={[getHome()]} pageName={t(getStores().name)} />
        </div>
        <div className="wrapper">
          <Box className="filter-section" sx={{ display: { xs: "none", md: "block" } }}>
            <RegionFilter />
          </Box>
          <Grid container justifyContent="center">
            <StoresCardGrid />
          </Grid>
        </div>
      </Styles>
    </SecondaryPageTemplate>
  );
}

export default AllStoresPage;
