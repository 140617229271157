import styled from "styled-components";

const Styles = styled.div`
  h1 {
    margin-bottom: 40px;
  }
  .paggination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }
  .search-container {
    display: flex;
    justify-content: space-between;
  }
  .no-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    @media (max-width: 900px) {
      margin-bottom: 100px;
    }
  }
  .no-item-icon {
    font-size: 120px;
    color: #a6a6a666;
  }
  .no-item-message {
    font-size: 20px;
    color: #a7a7a7;
  }

  .MuiFormControl-root {
    @media (max-width: 600px) {
      max-width: 100%;
    }
    max-width: 400px;
  }
`;

export default Styles;
