import styled from "styled-components";

const Styles = styled.div`
  margin: 40px 0px;
  .step-button {
    .MuiSvgIcon-root {
      width: 28px;
      height: 28px;
      color: white;
      border: 2px solid #d0d0d2;
      border-radius: 100px;
      .MuiStepIcon-text {
        fill: #d0d0d2;
      }
    }
    .MuiSvgIcon-root.Mui-active {
      color: white;
      border: 2px solid ${(props) => props.theme.palette.primary.main};
      border-radius: 100px;
      .MuiStepIcon-text {
        fill: ${(props) => props.theme.palette.primary.main};
        font-size: 13px;
      }
    }
  }
`;

export default Styles;
