import React from "react";
import { getStoresUrl } from "components/particles/APIs/API";
import StoreDashboardTemplate from "components/templates/store-dashboard";
import { useTranslation } from "react-i18next";

import http from "components/particles/http";
import toastError from "components/particles/toast/error";
import Styles from "./styles";

import {
  getHome,
  getMyStore,
  getMyStoreOrderDetails,
  getMyStoreOrders,
} from "constants/navigation";
import { useLocation, useParams } from "react-router-dom";
import ProductOrderItem from "components/molecules/product-order-item";

function StoreOrderDetailsPage() {
  const [state, setState] = React.useState({
    currentStore: {},
    productOrderList: [],
  });
  const { t } = useTranslation();
  const { orderId } = useParams();
  const search = useLocation().search;
  const customerId = new URLSearchParams(search).get("customer");

  const updateCurrentStore = (store) => {
    setState((prevState) => ({ ...prevState, currentStore: store }));
  };

  // get order Info
  React.useEffect(() => {
    if (state.currentStore && state.currentStore.id) {
      http
        .get(
          `${getStoresUrl}/${state.currentStore.id}/orders/${orderId}/products${
            customerId ? "?customer=" + customerId : ""
          }`
        )
        .then((response) => {
          let { list } = response.data.data;
          console.log(list);
          setState((prevState) => ({ ...prevState, productOrderList: list }));
        })
        .catch(() => {
          toastError("an_error_happens");
        });
    }
  }, [state.currentStore, orderId, customerId]);

  let { currentStore } = state;
  if (!currentStore) return null;
  return (
    <StoreDashboardTemplate
      breadcrumbsRoutes={[
        getHome(),
        getMyStore(currentStore.username),
        getMyStoreOrders(currentStore.username),
      ]}
      pageName={t(getMyStoreOrderDetails().name)}
      onLoadStore={updateCurrentStore}
    >
      <Styles>
        <h1>{t("order_items")}</h1>
        {state.productOrderList.map((orderItem, index) => {
          return (
            <ProductOrderItem
              key={index}
              imageUrl={orderItem.product.pictures[0].url}
              name={orderItem.product.name}
              status={orderItem.status.name}
            />
          );
        })}
      </Styles>
    </StoreDashboardTemplate>
  );
}

export default StoreOrderDetailsPage;
