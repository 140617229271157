import React from "react";
import { ShoppingCartContext } from "components/particles/providers/cart-context";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getHome } from "constants/navigation";
import SummaryTemplate from "components/templates/summary";
import CustomTextField from "components/atoms/text-field";
import CustomSelect from "components/atoms/select";
import http from "components/particles/http";
import * as ENDPIONTS from "components/particles/APIs/API";
import toastError from "components/particles/toast/error";
import * as Yup from "yup";

function CartCheckout({ formatPrice, onSubmitOrder }) {
  const [state, setState] = React.useState({
    regionsList: [],
    citiesList: [],
    region: 0,
    city_town_id: 0,
    delivery_address: "",
    errorWhileLoading: false,
  });
  const [errors, setErrors] = React.useState({
    delivery_address: "",
  });
  const { cartItems, cartTotalPrice } = React.useContext(ShoppingCartContext);
  const { t } = useTranslation();

  React.useEffect(() => {
    http
      .get(ENDPIONTS.getAllRegions)
      .then((response) => {
        let { list } = response.data.data;
        let regions = list.map((region) => ({ value: region.id, name: region.name }));
        setState((prevState) => ({
          ...prevState,
          errorWhileLoading: false,
          regionsList: regions,
          region: regions[0].value,
        }));
      })
      .catch((error) => {
        setState((prevState) => ({ ...prevState, errorWhileLoading: true }));
        console.log("an error happens while regions load", error);
      });
  }, []);

  // get cities by region, base on region id change
  React.useEffect(() => {
    if (!state.region) return;
    http
      .get(ENDPIONTS.getCitiesByRegion(state.region))
      .then((response) => {
        let { list } = response.data.data;
        let cities = list.map((city) => ({ value: city.id, name: city.name }));
        setState((prevState) => ({
          ...prevState,
          errorWhileLoading: false,
          citiesList: cities,
          city_town_id: cities[0].value,
        }));
      })
      .catch((error) => {
        setState((prevState) => ({ ...prevState, errorWhileLoading: true }));
        console.log("an error happens while cities load", error);
      });
  }, [state.region]);

  const orderValidationSchema = Yup.object().shape({
    delivery_address: Yup.string().required(
      t("is_required", { field: t("delivery_address") })
    ),
  });

  const onChangeInput = ({ target: input }) => {
    if (input.name === "city_town_id" || input.name === "region")
      setState({
        ...state,
        [input.name]: parseInt(input.value),
      });

    setState({
      ...state,
      [input.name]: input.value,
    });
    setErrors({ ...errors, [input.name]: "" });
  };

  const handleOrderSubmit = () => {
    let shoppingCart = {};
    cartItems.map((item) => {
      shoppingCart[item.id] = item.quantity;
    });

    orderValidationSchema
      .validate({
        shopping_cart: shoppingCart,
        delivery_city_town_id: state.city_town_id,
        delivery_address: state.delivery_address,
      })
      .then((order) => {
        if (state.errorWhileLoading) {
          toastError(t("an_error_happens"));
          return;
        }

        onSubmitOrder(order);
      })
      .catch((err) => {
        setErrors({ ...errors, [err.path]: err.errors[0] });
      });
  };

  if (cartItems.length === 0) return null;
  // else
  return (
    <Grid container className="checkout-container">
      <Grid item xs={12} md={5} lg={6} xl={4} className="order-info">
        <CustomSelect
          name="region"
          label={t("regions")}
          optionList={state.regionsList}
          value={state.region}
          onChange={onChangeInput}
        />
        <CustomSelect
          name="city_town_id"
          label={t("delivery_city")}
          optionList={state.citiesList}
          value={state.city_town_id}
          onChange={onChangeInput}
        />
        <CustomTextField
          name="delivery_address"
          label={t("delivery_address")}
          onChange={onChangeInput}
          error={!!errors.delivery_address}
          helperText={errors.delivery_address}
        />
      </Grid>
      <Grid item xs={12} md={7} lg={6} xl={6} sx={{ paddingLeft: { md: 4 }, margin: "auto" }}>
        <SummaryTemplate
          title={t("summary")}
          actionLabel={t("checkout")}
          onStartAction={handleOrderSubmit}
          linkLabel={t("continue_shopping")}
          link={getHome().path}
        >
          <div className="items-list">
            {cartItems.map((item, index) => {
              return (
                <Grid container key={index} mb={1} mt={1}>
                  <Grid item xs={2}>
                    X{item.quantity}
                  </Grid>
                  <Grid item xs>
                    {item.product.name}
                  </Grid>
                  <Grid item xs={2} textAlign="end">
                    {formatPrice(item.price)}
                  </Grid>
                </Grid>
              );
            })}
          </div>
          <div className="total-price-checkout">
            <div>{t("total")}</div>
            <div className="total-price">{formatPrice(cartTotalPrice)}</div>
          </div>
        </SummaryTemplate>
      </Grid>
    </Grid>
  );
}

export default CartCheckout;
