import React from "react";

import Styles from "./styles";

import http from "components/particles/http";

import * as ENDPIONTS from "components/particles/APIs/API";
import { useTranslation } from "react-i18next";
import UserProfileTemplate from "components/templates/profile";
import { getHome, getMyOrderDetails, getMyOrders } from "constants/navigation";
import StoreOrderStack from "components/organisms/store-orders-stack";
import { AuthContext } from "components/particles/providers/auth-context";
import paggination from "constants/paggination";
import { Pagination } from "@mui/material";
import { useHistory } from "react-router-dom";

const MyOrderPage = () => {
  const [state, setState] = React.useState({
    orderList: [],
    pageCount: 1,
    currentPage: 1,
  });
  const { user } = React.useContext(AuthContext);
  let history = useHistory();
  const { t } = useTranslation();

  React.useEffect(() => {
    let params = {
      count: paggination.pageSize,
      page: state.currentPage,
    };

    http
      .get(`${ENDPIONTS.getCustomersUrl}/${user.id}/orders`, { params })
      .then((response) => {
        let { data } = response.data;
        setState((prevState) => ({
          ...prevState,
          orderList: data.list || [],
          currentPage: data.current_page,
          pageCount: data.current_page + data.next_pages_count,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [user.id, state.currentPage]);

  const redirectToDetails = (orderId) => {
    history.push(getMyOrderDetails(orderId).path);
  };

  const handlePageChange = (event, page) => {
    setState({ ...state, currentPage: page });
  };

  return (
    <UserProfileTemplate pageName={t(getMyOrders().name)} routeList={[getHome()]}>
      <Styles>
        <h1>{t("my_orders")}</h1>
        <StoreOrderStack orderList={state.orderList} onClickViewItem={redirectToDetails} />
        {state.pageCount !== 1 && (
          <div className="paggination-container">
            <Pagination
              color="primary"
              count={state.pageCount}
              variant="outlined"
              shape="rounded"
              page={state.currentPage}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Styles>
    </UserProfileTemplate>
  );
};

export default MyOrderPage;
