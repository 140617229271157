import React from "react";

import { Button } from "@mui/material";

import Styles from "./styles";
import { Link } from "react-router-dom";
import { useTheme } from "@emotion/react";

function SummaryTemplate({
  sx,
  title,
  actionLabel,
  onStartAction,
  children,
  linkLabel,
  link,
  actionDisabled,
}) {
  const theme = useTheme();
  return (
    <Styles style={sx} theme={theme}>
      <h2>{title}</h2>
      {children}
      <Button
        onClick={onStartAction}
        color="secondary"
        variant="contained"
        disableElevation
        fullWidth
        disabled={actionDisabled}
      >
        {actionLabel}
      </Button>
      <div className="link-container">
        <Link to={link}>{linkLabel}</Link>
      </div>
    </Styles>
  );
}

export default SummaryTemplate;
