import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px 0px;
  width: inherit;
  overflow-x: auto;
  ::-webkit-scrollbar {
    height: 5px;
    display: none;
  }
`;

export default Styles;
