import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom:16px;
  
  a {
      text-decoration:none;
      font-size:20px;
      color:${(props) => props.theme.palette.primary.main};
      margin-top:8px;
      white-space: nowrap;
  }
}
`;

export default Styles;
