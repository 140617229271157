import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  align-items: stretch;
  margin: 32px 0px;

  .navigator-area {
    display: flex;
    align-items: stretch;
    height: 41px;
    margin: auto;
    border: 1px solid ${(props) => props.theme.palette.primary.main};
    border-radius: 32px;
    overflow-x: auto;
    ::-webkit-scrollbar {
      display: none;
    }
  }

  .store-nav-item {
    text-decoration: none;
    padding: 10px 32px;
    color: inherit;
    min-width: 67px;
    text-align: center;
    border-radius: 32px;
  }
  .store-nav-item.active {
    background: ${(props) => props.theme.palette.primary.main};

    color: white;
  }
`;

export default Styles;
