import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  & > a {
    display: flex;
    text-decoration: none;
  }
  & > a > span {
    display: flex;
    alignitems: center;
    padding-right: 8px;
  }

  & > a > span:first-child {
    color: ${(props) => props.theme.palette.secondary.main};
  }

  & > a > span:nth-child(2) {
    color: #383838;
    font-size: 14px;
  }
`;

export default Styles;
