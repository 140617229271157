import React from "react";
import { Grid } from "@mui/material";
import CustomButton from "components/atoms/button";
import { getBrandsUrl, getStoresUrl } from "components/particles/APIs/API";
import StoreDashboardTemplate from "components/templates/store-dashboard";
import { useTranslation } from "react-i18next";
import CustomTextField from "components/atoms/text-field";
import { CategoriesContext } from "components/particles/providers/categories-context";
import CustomSelect from "components/atoms/select";
import http from "components/particles/http";
import toastError from "components/particles/toast/error";
import toastSuccess from "components/particles/toast/success";
import Styles from "./styles";
import * as Yup from "yup";
import {
  getHome,
  getMyStore,
  getMyStoreProducts,
  getUpdateProduct,
} from "constants/navigation";
import { useParams } from "react-router-dom";

function UpdateProductPage() {
  const [state, setState] = React.useState({
    currentStore: {},
    brandsOption: [],
    selectedBrandId: 0,
    categoriesOption: [],
    selectedCategoryId: 0,
    subCategoriesOption: [],
    selectedSubCategoryId: 0,
    PictureFileList: null,
    name: "",
    quantity: "",
    base_price: "",
    sale: "",
    description: "",
    sku: "",
    pictures: [],
  });
  const [errors, setErrors] = React.useState({});
  const [isUpdatingProduct, setUpdatingProduct] = React.useState(false);
  const { t } = useTranslation();
  const { categories } = React.useContext(CategoriesContext);
  const { productId } = useParams();

  const updateCurrentStore = (store) => {
    setState((prevState) => ({ ...prevState, currentStore: store }));
  };

  // get product Info
  React.useEffect(() => {
    if (state.currentStore) {
      http
        .get(`${getStoresUrl}/${state.currentStore.id}/products/${productId}`)
        .then((response) => {
          let product = response.data.data;
          console.log(product);
          setState((prevState) => ({
            ...prevState,
            name: product.name,
            quantity: product.quantity,
            base_price: product.base_price,
            sale: product.sale,
            description: product.description,
            sku: product.sku,
            selectedCategoryId: product.category_id,
            selectedBrandId: product.brand_id,
            pictures: product.pictures,
          }));
        })
        .catch(() => {
          toastError("an_error_happens");
        });
    }
  }, [state.currentStore, productId]);

  // get brands and set it as brand option list
  React.useEffect(() => {
    http
      .get(getBrandsUrl)
      .then((response) => {
        let { list } = response.data.data;
        let brandsOption = [];
        brandsOption.push({
          value: 0,
          name: t("none"),
        });

        if (list) {
          let options = list.map((brand) => ({
            value: brand.id,
            name: brand.name,
          }));
          brandsOption = brandsOption.concat(options);
        }

        setState((prevState) => ({
          ...prevState,
          brandsOption,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [t]);

  // get categories and set it as category option list
  React.useEffect(() => {
    let categoriesOption = [];
    let selectedCategoryId = 0;
    if (categories && categories.length > 0) {
      categoriesOption = categories.map((category) => ({
        value: category.id,
        name: category.name,
      }));
      selectedCategoryId = categoriesOption[0].value;
    }

    setState((prevState) => ({
      ...prevState,
      categoriesOption,
      selectedCategoryId,
    }));
  }, [categories]);

  // sub categories update base on selected category
  React.useEffect(() => {
    let subCategoriesOption = [];
    let selectedCategory = categories.find(
      (category) => category.id === parseInt(state.selectedCategoryId)
    );
    if (selectedCategory && selectedCategory.sub_categories) {
      subCategoriesOption = selectedCategory.sub_categories.map((subCategory) => ({
        value: subCategory.id,
        name: subCategory.name,
      }));
    }
    setState((prevState) => ({ ...prevState, subCategoriesOption }));
  }, [categories, state.selectedCategoryId]);

  const ProductValidationSchema = Yup.object().shape({
    // selectedBrandId: Yup.number()
    //   .not([0], t("an_error_happens"))
    //   .required(t("is_required", { field: t("brand") })),
    selectedCategoryId: Yup.number()
      .not([0], t("an_error_happens"))
      .required(t("is_required", { field: t("category") })),
    description: Yup.string().required(t("is_required", { field: t("description") })),
    sale: Yup.number()
      .typeError(t("is_required", { field: t("sale") }))
      .min(0, t("min_value_warning", { field: t("sale"), min: 0 })),
    base_price: Yup.number()
      .typeError(t("is_required", { field: t("price") }))
      .min(1, t("min_value_warning", { field: t("price"), min: 1 })),
    quantity: Yup.number()
      .typeError(t("is_required", { field: t("quantity") }))
      .min(1, t("min_value_warning", { field: t("quantity"), min: 1 })),
    name: Yup.string().required(t("is_required", { field: t("product_name") })),
  });

  const onChangeInput = ({ target: input }) => {
    let numberInputList = [
      "sale",
      "base_price",
      "quantity",
      "selectedSubCategoryId",
      "selectedCategoryId",
      "selectedBrandId",
    ];
    if (numberInputList.includes(input.name) && !isNaN(input.value) && input.value !== "") {
      setState({ ...state, [input.name]: parseInt(input.value) });
      setErrors({ ...errors, [input.name]: "" });
    } else {
      setState({ ...state, [input.name]: input.value });
      setErrors({ ...errors, [input.name]: "" });
    }
  };

  // const onChangeImageInput = (event) => {
  //   let PictureFileList = event.target.files;
  //   setState({ ...state, PictureFileList });
  // };

  const onUpdateProduct = () => {
    const {
      name,
      quantity,
      base_price,
      sale,
      description,
      sku,
      selectedBrandId,
      selectedCategoryId,
      selectedSubCategoryId,
      currentStore,
    } = state;

    ProductValidationSchema.validate({
      name,
      quantity,
      base_price,
      sale,
      description,
      sku,
      selectedBrandId,
      selectedCategoryId,
      selectedSubCategoryId,
    })
      .then((product) => {
        // if (!PictureFileList) {
        //   toastError(t("please_select_product_image"));
        //   return;
        // } else {
        setUpdatingProduct(true);
        // let formData = new FormData();
        // Array.from(PictureFileList).forEach((pic) => {
        //   formData.append("pictures", pic);
        // });
        // upload the picture and get names
        // http
        //   .post(`${getProductsUrl}/pictures`, formData)
        //   .then((response) => {
        //     let { list } = response.data.data;
        //     let picture_ids = list.map((pic) => pic.id);
        let { selectedCategoryId, selectedSubCategoryId, selectedBrandId, ...prod } = product;
        let newProduct = {
          ...prod,
          category_id: selectedCategoryId,
          sub_category_id: selectedSubCategoryId,
          brand_id: selectedBrandId,
        };
        console.log(newProduct);

        // lets call the update product api
        http
          .post(`${getStoresUrl}/${currentStore.id}/products/${productId}/details`, {
            ...newProduct,
          })
          .then((response) => {
            console.log(response);
            setUpdatingProduct(false);
            toastSuccess(t("product_updated_successfuly"));
          })
          .catch((error) => {
            setUpdatingProduct(false);
            error.response.data.errors.forEach((err) => {
              toastError(err.message);
            });
          });
        // update price and sale - dump way but i don't have a choice
        http
          .post(`${getStoresUrl}/${currentStore.id}/products/${productId}/price`, {
            base_price: product.base_price,
            sale: product.sale,
          })
          .catch((error) => {
            error.response.data.errors.forEach((err) => {
              toastError(err.message);
            });
          });
        // update quantity - dump way but i don't have a choice
        http
          .post(`${getStoresUrl}/${currentStore.id}/products/${productId}/quantity`, {
            quantity: product.quantity,
          })
          .catch((error) => {
            error.response.data.errors.forEach((err) => {
              toastError(err.message);
            });
          });

        // })
        // .catch((error) => {
        //   setUpdatingProduct(false);
        //   error.response.data.errors.forEach((err) => {
        //     toastError(err.message);
        //   });
        // });
        // }
      })
      .catch((err) => {
        console.log(err);
        setErrors({ ...errors, [err.path]: err.errors[0] });
      });
  };

  let { currentStore } = state;
  if (!currentStore) return null;
  return (
    <StoreDashboardTemplate
      breadcrumbsRoutes={[
        getHome(),
        getMyStore(currentStore.username),
        getMyStoreProducts(currentStore.username),
      ]}
      pageName={t(getUpdateProduct(state.currentStore.username).name)}
      onLoadStore={updateCurrentStore}
    >
      <Styles>
        <h1>{t("update_product")}</h1>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <CustomTextField
              fullWidth
              name="name"
              value={state.name}
              label={t("product_name")}
              onChange={onChangeInput}
              error={!!errors.name}
              helperText={errors.name}
            />
            <CustomTextField
              fullWidth
              name="quantity"
              type="number"
              min={0}
              value={state.quantity}
              label={t("quantity")}
              onChange={onChangeInput}
              error={!!errors.quantity}
              helperText={errors.quantity}
            />
            <CustomTextField
              fullWidth
              name="base_price"
              type="number"
              value={state.base_price}
              label={t("price")}
              onChange={onChangeInput}
              error={!!errors.base_price}
              helperText={errors.base_price}
            />
            <CustomTextField
              fullWidth
              name="sale"
              value={state.sale}
              type="number"
              label={t("sale")}
              onChange={onChangeInput}
              endAdornment={<div>%</div>}
              error={!!errors.sale}
              helperText={errors.sale}
            />
            <CustomTextField
              fullWidth
              multiline
              rows={2}
              name="description"
              value={state.description}
              label={t("description")}
              onChange={onChangeInput}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <CustomSelect
              label={t("category")}
              name="selectedCategoryId"
              optionList={state.categoriesOption}
              value={state.selectedCategoryId}
              onChange={onChangeInput}
              error={!!errors.selectedCategoryId}
              helperText={errors.selectedCategoryId}
            />
            <CustomSelect
              label={t("sub_category")}
              name="selectedSubCategoryId"
              optionList={state.subCategoriesOption}
              value={state.selectedSubCategoryId}
              onChange={onChangeInput}
              error={!!errors.selectedSubCategoryId}
              helperText={errors.selectedSubCategoryId}
            />
            <CustomSelect
              label={t("brand")}
              name="selectedBrandId"
              optionList={state.brandsOption}
              value={state.selectedBrandId}
              onChange={onChangeInput}
              error={!!errors.selectedBrandId}
              helperText={errors.selectedBrandId}
            />

            <CustomTextField
              fullWidth
              name="sku"
              label={t("sku")}
              value={state.sku}
              onChange={onChangeInput}
              error={!!errors.sku}
              helperText={errors.sku}
            />
            {/* <div>
              {state.pictures.map((picture, index) => {
                return (
                  <img
                    key={index}
                    width="80px"
                    height="80px"
                    src={picture.url}
                    alt={picture.name}
                  />
                );
              })}
            </div>
            <FileUploader
              multiple
              maxWidth="400px"
              accept="image/png, image/jpeg"
              label={t("add_new")}
              color="secondary"
              onChange={onChangeImageInput}
            /> */}
          </Grid>
        </Grid>
        <CustomButton color="primary" onClick={onUpdateProduct} disabled={isUpdatingProduct}>
          {t("update")}
        </CustomButton>
      </Styles>
    </StoreDashboardTemplate>
  );
}

export default UpdateProductPage;
