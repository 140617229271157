import React, { useEffect } from "react";
import { Pagination } from "@mui/material";
import CustomButton from "components/atoms/button";
import ProductSearchBox from "components/molecules/product-search-box";
import StoreProductStack from "components/organisms/product-store-stack";
import { getStoresUrl } from "components/particles/APIs/API";
import http from "components/particles/http";
import StoreDashboardTemplate from "components/templates/store-dashboard";
import paggination from "constants/paggination";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";

import SearchOffIcon from "@mui/icons-material/SearchOff";
import { useHistory } from "react-router";
import { getCreateProduct, getUpdateProduct } from "constants/navigation";
import toastError from "components/particles/toast/error";
import toastSuccess from "components/particles/toast/success";
import deleteConfirmationDialog from "components/particles/delete-confirmation";
import Styles from "./styles";

function StoreProductPage() {
  const [state, setState] = React.useState({
    currentStore: null,
    productList: [],
    pageCount: 1,
    currentPage: 1,
    keyword: "",
    currentProductId: null,
  });

  const { t } = useTranslation();
  let history = useHistory();

  useEffect(() => {
    let currentStore = state.currentStore;
    let params = null;

    if (state.keyword)
      params = {
        search: state.keyword,
      };

    params = {
      ...params,
      count: paggination.pageSize,
      page: state.currentPage,
    };
    if (currentStore) {
      http
        .get(`${getStoresUrl}/${currentStore.id}/products`, { params })
        .then((response) => {
          let { data } = response.data;

          setState((prevState) => ({
            ...prevState,
            productList: data.list || [],
            currentPage: data.current_page,
            pageCount: data.current_page + data.next_pages_count,
          }));
        })
        .then((error) => {
          console.log(error);
        });
    }
  }, [state.currentStore, state.currentPage, state.keyword]);

  const handlePageChange = (event, page) => {
    setState({ ...state, currentPage: page });
  };

  const handleSearch = (searchKeyword) => {
    setState({ ...state, keyword: searchKeyword });
  };

  const updateCurrentStore = (store) => {
    setState({ ...state, currentStore: store });
  };

  const handleDelete = (productId) => {
    deleteConfirmationDialog().then((result) => {
      if (result.isConfirmed) {
        http
          .delete(`${getStoresUrl}/${state.currentStore.id}/products/${productId}`)
          .then(() => {
            toastSuccess("operation_done_successfully");
            let newProductList = state.productList.filter(
              (product) => product.id !== productId
            );
            setState({
              ...state,
              productList: newProductList,
              currentProductId: null,
            });
          })
          .catch((error) => {
            if (error.response.data.errors) {
              error.response.data.errors.forEach((err) => {
                toastError(err.message);
              });
            } else {
              toastError(error.response.data.message);
            }
          });
      }
    });
  };

  const redirectToEdit = (productId) => {
    history.push(getUpdateProduct(state.currentStore.username, productId).path);
  };

  const redirectToCreate = () => {
    history.push(`${getCreateProduct(state.currentStore.username).path}`);
  };

  const NoItemFound = () => {
    return (
      <div className="no-item-container">
        <SearchOffIcon className="no-item-icon" />
        <p className="no-item-message">{t("no_item_found")}</p>
      </div>
    );
  };

  return (
    <StoreDashboardTemplate onLoadStore={updateCurrentStore}>
      <Styles>
        <h1>{t("products")}</h1>
        <div className="search-container">
          <ProductSearchBox onClickSearch={handleSearch} />
          <CustomButton
            color="secondary"
            startIcon={<AddIcon />}
            onClick={redirectToCreate}
            className="add-item-btn"
          >
            {t("add_new")}
          </CustomButton>
        </div>
        {state.productList.length === 0 ? (
          <NoItemFound />
        ) : (
          <StoreProductStack
            productList={state.productList}
            onClickEditItem={redirectToEdit}
            onclickDeleteItem={handleDelete}
          />
        )}

        {state.pageCount !== 1 && (
          <div className="paggination-container">
            <Pagination
              color="primary"
              count={state.pageCount}
              variant="outlined"
              shape="rounded"
              page={state.currentPage}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Styles>
    </StoreDashboardTemplate>
  );
}

export default StoreProductPage;
