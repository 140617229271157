import styled from "styled-components";

const Styles = styled.div`
  h1 {
    margin-block-start: 0em;
    margin-block-end: 0em;
    font-weight: 700;
  }
  .no-items {
    color: #a7a7a7;
  }
  .total-price-container {
    display: flex;
    justify-content: space-between;
  }

  .total-price-checkout {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    border-top: 1px solid #c7c7c7;
    border-bottom: 1px solid #c7c7c7;
    padding: 12px 0px;
  }

  .total-price {
    font-weight: 600;
    color: ${(props) => props.theme.palette.secondary.main};
  }
  .gray-total {
    color: #a7a7a7;
  }
  .order-details {
    display: flex;
  }
  .items-list {
    margin-bottom: 32px;
  }
  .order-info {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
  }
  .checkout-container {
    margin-top: 56px;
  }
`;

export default Styles;
