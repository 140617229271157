import React from "react";
import { Avatar } from "@mui/material";
import { deepOrange } from "@mui/material/colors";
import { useTheme } from "@emotion/react";

import { getLogout, userProfileNavList } from "constants/navigation";
import NavMenuItem from "components/atoms/nav-menu-item";
import { useTranslation } from "react-i18next";
import Styles from "./styles";

function ProfileMenuNavigation({ user, logoutHanlder }) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Styles>
      <div className="profile-info">
        <Avatar
          sx={{
            width: 80,
            height: 80,
            fontSize: 32,
            borderRadius: 2,
            boxShadow: "0 0 0 2px #f3f5f6 inset",
            border: `2px solid ${theme.palette.primary.main}`,
            bgcolor: deepOrange[500],
          }}
          variant="rounded"
        >
          {user.name.charAt(0)}
        </Avatar>
        <h2>{user.name}</h2>
        <span>@{user.username}</span>
      </div>
      <div className="nav-container">
        {userProfileNavList.map((navItem, index) => {
          return (
            <NavMenuItem key={index} to={navItem.path} fontSize="15px">
              {t(navItem.name)}
            </NavMenuItem>
          );
        })}
        {logoutHanlder && (
          <NavMenuItem onClick={logoutHanlder} to={getLogout().path}>
            {t(getLogout().name)}
          </NavMenuItem>
        )}
      </div>
    </Styles>
  );
}

export default ProfileMenuNavigation;
