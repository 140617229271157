import styled from "styled-components";

const Styles = styled.div`
  padding: 32px 0px;
  h1 {
    font-weight: 600;
    color: #3d3d3d;
  }
  .top-rated__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    a {
      color: ${(props) => props.theme.palette.primary.main};
      text-decoration: none;
      font-weight: 500;
    }
  }
`;

export default Styles;
