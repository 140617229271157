import React from "react";
import { getHome, getLogin } from "constants/navigation";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../providers/auth-context";

const PrivateStoreRoute = ({ component: Component, render, ...rest }) => {
  const { isAuthenticated, user } = React.useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated)
          return (
            <Redirect
              to={{
                pathname: getLogin().path,
                state: { from: props.location },
              }}
            />
          );
        else if (user.user_type !== "S")
          return (
            <Redirect
              to={{
                pathname: getHome().path,
                state: { from: props.location },
              }}
            />
          );
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default PrivateStoreRoute;
