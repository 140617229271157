import React from "react";
import { StyledStack } from "./styles";
import NotificationCard from "components/molecules/notification-card";

function NotificationStack({ notificationList = [], onClickNotification = null }) {
  if (notificationList.length === 0) return null;
  // else
  return (
    <StyledStack direction="column">
      {notificationList.map((notification, index) => {
        return (
          <NotificationCard
            key={index}
            user={notification.customer.name}
            event={notification.event.details}
            seen={notification.is_seen}
            onClick={() =>
              onClickNotification(notification.order_id, notification.customer_id)
            }
          />
        );
      })}
    </StyledStack>
  );
}

export default NotificationStack;
