import React from "react";

function RubbishBinIcon() {
  return (
    <svg
      id="rubbish-bin"
      xmlns="http://www.w3.org/2000/svg"
      width="16.471"
      height="22.471"
      viewBox="0 0 16.471 22.471"
    >
      <path
        id="Shape"
        d="M12.235,17H1.852A1.119,1.119,0,0,1,.741,15.917L0,0H14.087l-.741,15.916A1.119,1.119,0,0,1,12.235,17ZM9.844,3.494a.456.456,0,0,0-.452.459v9.094a.456.456,0,0,0,.452.459h.722a.456.456,0,0,0,.452-.459V3.953a.456.456,0,0,0-.452-.459Zm-3.162,0a.456.456,0,0,0-.452.459v9.094a.456.456,0,0,0,.452.459H7.4a.456.456,0,0,0,.452-.459V3.953A.456.456,0,0,0,7.4,3.494Zm-3.162,0a.456.456,0,0,0-.452.459v9.094a.456.456,0,0,0,.452.459h.722a.456.456,0,0,0,.452-.459V3.953a.456.456,0,0,0-.452-.459Z"
        transform="translate(1.192 5.472)"
        fill="#fff"
      />
      <path
        id="Shape-2"
        data-name="Shape"
        d="M15.772,1.158h-4.79V.237A.235.235,0,0,0,10.75,0H5.72a.235.235,0,0,0-.233.237v.921H.7a.7.7,0,0,0-.7.71V4.1H16.47V1.867A.7.7,0,0,0,15.772,1.158Z"
        fill="#fff"
      />
    </svg>
  );
}

export default RubbishBinIcon;
