import styled from "styled-components";

const Styles = styled.div`
  margin-top: 20px;
  max-width: ${(props) => props.maxWidth || "100%"};
  .input-uploder {
    border: 1px solid #0000003b;
    border-radius: 4px;
    padding: 8.5px 14px;
  }

  input {
    font-family: ${(props) => props.theme.typography.fontFamily};
  }
  input::file-selector-button {
    color: white;
    background: ${(props) => props.theme.palette.secondary.main};
    border: none;
    border-radius: 4px;
    padding: 4px 8px;
    font-family: ${(props) => props.theme.typography.button.fontFamily};
    font-weight: 500;
    cursor: pointer;
    margin-inline-end: 12px;
  }
  .label-uploder {
    margin-bottom: 8px;
    display: flex;
    margin-inline-start: 16px;
    font-size: 15px;
    line-height: 1.4375em;
    position: absolute;
    transform: translateY(-28px);
  }
`;

export default Styles;
