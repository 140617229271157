import React from "react";

import { useTranslation } from "react-i18next";

import StarIcon from "@mui/icons-material/Star";

import { useTheme } from "@emotion/react";

import Styles from "./styles";
import CustomButton from "components/atoms/button";

import { Link, useHistory } from "react-router-dom";
import WishlistButton from "components/atoms/wishlist-button";
import * as ENDPIONTS from "components/particles/APIs/API";
import http from "components/particles/http";
import toastError from "components/particles/toast/error";
import { ShoppingCartContext } from "components/particles/providers/cart-context";
import { CurrencyContext } from "components/particles/providers/currency-context";
import { getCustomerRegister, getLogin, getProducts } from "constants/navigation";
import { WishListContext } from "components/particles/providers/wishlist-context";
import { AuthContext } from "components/particles/providers/auth-context";
import sweetAlert from "components/particles/sweet-alert";
import { Tooltip } from "@mui/material";

function ProductCard({ productObject, sx, clickable = true, isNew = false }) {
  const [state, setState] = React.useState({
    addToCartLoading: false,
    addToWishesLoading: false,
  });
  const { currency } = React.useContext(CurrencyContext);
  const { cartItems, dispatch: cartDispatch } = React.useContext(ShoppingCartContext);
  const { wishlistItems, dispatch: wishlistDispatch } = React.useContext(WishListContext);
  const { isAuthenticated } = React.useContext(AuthContext);

  const { t } = useTranslation();
  let history = useHistory();
  const theme = useTheme();

  const setLoadingAddToCart = (status) => {
    setState({ ...state, addToCartLoading: status });
  };

  const setLoadingAddToWish = (status) => {
    setState({ ...state, addToWishesLoading: status });
  };

  const getItemQuantityInCart = () => {
    let item = cartItems.find((item) => item.product_id === productObject.id);
    return item ? item.quantity : 0;
  };

  const getWishItemByProductId = () => {
    let item = wishlistItems.find((item) => item.product_id === productObject.id);
    return item.id;
  };

  const isAddedToWishlist = () => {
    let itemIndex = wishlistItems.findIndex((item) => item.product_id === productObject.id);
    return itemIndex !== -1 ? true : false;
  };

  const formatePriceWithCurrency = (price) => {
    switch (currency.position) {
      case "start":
        return currency.symbol + price;

      default:
        return price + currency.symbol;
    }
  };

  const isUserAuthenticated = () => {
    if (!isAuthenticated) {
      sweetAlert(
        t("login_first_title"),
        t("login_first_message"),
        "warning",
        t("login"),
        theme.palette.primary.main,
        null,
        null,
        t("register"),
        theme.palette.secondary.main
      )
        // isDismissed = register or isConfirmed = login
        .then((result) => {
          if (result.isConfirmed) history.push(getLogin().path);
          if (result.isDenied) {
            history.push(getCustomerRegister().path);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      return false;
    }
    return true;
  };

  const addToCart = () => {
    if (!isUserAuthenticated()) {
      return;
    }
    setLoadingAddToCart(true);
    http
      .post(ENDPIONTS.getShoppingCartUrl, {
        product_id: productObject.id,
        store_id: productObject.store_id,
        quantity: 1,
      })
      .then((response) => {
        setLoadingAddToCart(false);
        let { data } = response.data;
        let cartItem = {
          ...data,
          product: productObject,
          price: productObject.final_price,
          total_price: productObject.final_price * data.quantity,
        };
        cartDispatch({
          type: "addToCart",
          payload: { cartItem },
        });
      })
      .catch((error) => {
        setLoadingAddToCart(false);
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err) => {
            toastError(err.message);
          });
        } else {
          toastError(error.response.data.message);
        }
      });
  };

  const addToWishlist = () => {
    if (!isUserAuthenticated()) {
      return;
    }
    setLoadingAddToWish(true);
    http
      .post(ENDPIONTS.getWishlistUrl, {
        product_id: productObject.id,
        store_id: productObject.store_id,
        quantity: 1,
      })
      .then((response) => {
        setLoadingAddToWish(false);
        let { data } = response.data;

        let wishlistItem = {
          ...data,
          product: productObject,
          price: productObject.final_price,
          total_price: productObject.final_price * data.quantity,
        };
        wishlistDispatch({
          type: "addToWishlist",
          payload: { wishlistItem },
        });
      })
      .catch((error) => {
        setLoadingAddToWish(false);
        if (error.response.data.errors) {
          error.response.data.errors.forEach((err) => {
            toastError(err.message);
          });
        } else {
          toastError(error.response.data.message);
        }
      });
  };

  const removeFromWishlist = () => {
    let itemId = getWishItemByProductId();
    http
      .delete(ENDPIONTS.getWishlistUrl + `/${itemId}`)
      .then(() => {
        wishlistDispatch({ type: "removeFromWishlist", payload: { itemId } });
      })
      .catch((error) => {
        toastError(
          error.response.data.message ? error.response.data.message : t("an_error_occure")
        );
      });
  };

  let link = getProducts().path + "/" + productObject.id;
  let itemQuantity = getItemQuantityInCart();
  let isAddedToWishes = isAddedToWishlist();
  return (
    <Styles theme={theme} style={sx}>
      <div className="image-container">
        {isNew && <div className="circle">{t("new")}</div>}
        {clickable ? (
          <Link to={link}>
            <img src={productObject.pictures[0].url} alt={productObject.name} />
          </Link>
        ) : (
          <img src={productObject.pictures[0].url} alt={productObject.name} />
        )}
      </div>
      <div className="product-info">
        {clickable ? (
          <Tooltip title={productObject.name}>
            <Link to={link} className="product-link">
              {productObject.name.length > 32
                ? productObject.name.substring(0, 32) + ".."
                : productObject.name}
            </Link>
          </Tooltip>
        ) : (
          <p className="product-name">{productObject.name}</p>
        )}
        <p className="product-price">{formatePriceWithCurrency(productObject.final_price)}</p>
        <div className="product-rate-sold">
          <StarIcon fontSize="small" style={{ color: "#FFD687" }} />
          {productObject.rating} | {t("sold")} {productObject.solded_quantity}
        </div>
        <div className="actions">
          <CustomButton
            disableRipple
            color="secondary"
            onClick={addToCart}
            className="cart-button"
            disabled={state.addToCartLoading}
          >
            {t("add_to_cart")}
            {itemQuantity ? ` (${itemQuantity})` : ""}
          </CustomButton>
          <WishlistButton
            addedToWishlist={isAddedToWishes}
            onClick={isAddedToWishes ? removeFromWishlist : addToWishlist}
          />
        </div>
      </div>
    </Styles>
  );
}

export default ProductCard;
