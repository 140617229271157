import styled from "styled-components";

const Styles = styled.div`
  border: 1px solid #c7c7c7;
  padding: 16px;
  border-radius: 6px;
  max-width: 400px;
  margin: auto;
  h2 {
    font-weight: 500;
    margin-block-start: 0em;
    margin-block-end: 1em;
  }
  button {
    margin-top: 24px;
    margin-bottom: 16px;
    color: white;
  }
  .link-container {
    text-align: center;
    a {
      text-decoration: none;
      font-weight: 500;
      color: ${(props) => props.theme.palette.primary.main};
    }
  }
`;

export default Styles;
