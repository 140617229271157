import styled from "styled-components";

const Styles = styled.div`
  .breadcrumbs {
    display: flex;
    margin-bottom: 32px;
  }
  .wrapper {
    display: flex;
  }
  .filter-section {
    padding-top: 14px;
    max-width: 246px;
    margin-inline-end: 32px;
  }
  .filter-header {
    font-weight: 600;
  }
  .regions-filter {
    padding: 0px 8px;
    margin-bottom: 32px;
  }
`;

export default Styles;
