import React from "react";
import StoreProductCard from "components/molecules/store-product-card";
import { StyledStack } from "./styles";

function StoreProductStack({ productList, onclickDeleteItem, onClickEditItem }) {
  if (productList.length === 0) return null;
  // else
  return (
    <StyledStack direction="column">
      <StoreProductCard />
      {productList.map((product, index) => {
        return (
          <StoreProductCard
            key={index}
            productId={product.id}
            productName={product.name}
            quantity={product.quantity}
            productPrice={product.final_price}
            maxQuantity={product.quantity}
            productImages={product.pictures}
            onClickDelete={() => onclickDeleteItem(product.id)}
            onClickEdit={() => onClickEditItem(product.id)}
          />
        );
      })}
    </StyledStack>
  );
}

export default StoreProductStack;
