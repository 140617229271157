import React from "react";
import { StyledStack } from "./styles";
import StoreOrderRow from "components/molecules/order-row";

function StoreOrderStack({ orderList, onClickViewItem }) {
  if (orderList.length === 0) return null;
  // else
  return (
    <StyledStack direction="column">
      <StoreOrderRow />
      {orderList.map((order, index) => {
        return (
          <StoreOrderRow
            key={index}
            orderId={order.id}
            orderCode={order.status.code}
            totalPrice={order.total_price}
            onClickView={() => onClickViewItem(order.id)}
          />
        );
      })}
    </StyledStack>
  );
}

export default StoreOrderStack;
