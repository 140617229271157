import { Stack } from "@mui/material";
import { styled } from "@mui/system";

const StyledStack = styled(Stack)({
  padding: "0px 8px",
  alignItems: "center",
  overflowX: "auto",
  height: 244,
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

export default StyledStack;
