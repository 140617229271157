import { useTheme } from "@emotion/react";
import React from "react";

import { NavLink } from "react-router-dom";

import Styles from "./styles";

function NavItem({ to, children }) {
  const theme = useTheme();

  return (
    <Styles theme={theme}>
      <NavLink exact to={to}>
        <span>{children}</span>
      </NavLink>
    </Styles>
  );
}

export default NavItem;
