import React from "react";

import IconButton from "@mui/material/IconButton";

import MenuItem from "@mui/material/MenuItem";

import MenuIcon from "@mui/icons-material/Menu";

import { navigationList } from "../../../constants/navigation";

import NavItem from "components/atoms/nav-item";

import { useTranslation } from "react-i18next";

import Styles from "./styles";
import { Fade, MenuList } from "@mui/material";

import ClickAwayListener from "@mui/material/ClickAwayListener";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";

function NavigationMenu() {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const { t } = useTranslation();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Styles>
      <IconButton
        ref={anchorRef}
        id="navigation-button"
        aria-controls={open ? "navigation-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <MenuIcon style={{ color: "#383838", fontSize: 28 }} />
      </IconButton>

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-start"
        transition
        disablePortal
        style={{ zIndex: 999 }}
      >
        {({ TransitionProps, placement }) => (
          <Fade
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
            }}
          >
            <Paper style={{ border: "1px solid #D7D7D7" }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="navigation-menu"
                  MenuListProps={{
                    "aria-labelledby": "navigation-menu",
                  }}
                  elevation={0}
                  open={open}
                  onClose={handleClose}
                >
                  {navigationList.map((nav, index) => (
                    <MenuItem key={index}>
                      <NavItem to={nav.path}>{t(nav.name)}</NavItem>
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </Styles>
  );
}

export default NavigationMenu;
