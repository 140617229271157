import styled from "styled-components";

const Styles = styled.div`
  display: flex;

  .product-id {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.palette.primary.main};
    font-weight: 600;
    min-width: 60px;
  }
  .image-container {
    display: flex;
  }
  .image-container > *:first-child {
    width: 86px;
    padding: 16px 64px;
  }

  .product-price {
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
  .product-name {
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-width: 240px;
    padding: 16px 32px;
    margin-block-start: 0em;
    font-size: 18px;
  }
  .total-price-container {
    display: flex;
    justify-content: center;
    padding: 16px 32px;
    min-width: 100px;
  }
  .total-price {
    font-size: 16px;
    font-weight: 600;
    margin-right: 4px;
    align-self: center;
  }
  .currency-code {
    font-size: 16px;
    font-weight: 600;
    color: #a7a7a7;
    align-self: center;
  }
  .action-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 32px;
    min-width: 120px;

    button.edit-btn {
      padding: 0px;
      min-width: 40px;
      height: 40px;
      border-radius: 8px;
      border: 2px solid ${(props) => props.theme.palette.primary.main};
      margin-inline-end: 8px;
    }
    button.delete-btn {
      padding: 0px;
      min-width: 40px;
      height: 40px;
      border-radius: 8px;
      background: ${(props) => props.theme.palette.secondary.main};
    }
  }
  .proudct-header {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    background: #a7a7a7;
    border-radius: 8px;
    font-weight: 600;
    font-size: 18px;
    color: white;

    > div.product-id {
      color: white;
    }

  }

  @media screen and (max-width: 900px) {
    .proudct-header {
      width:auto;
    }

 
`;

export default Styles;
