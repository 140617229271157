import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  .image-container {
    margin-inline-end: 16px;
  }
  img {
    width: 86px;
  }
  .product-name {
    font-size: 18px;
    margin-bottom: 12px;
  }
  .product-status-chip {
    border-radius: 6px;
    color: white;
    font-weight: 500;
    height: 22px;
  }
`;

export default Styles;
