import { useTheme } from "@emotion/react";
import React from "react";
import { useTranslation } from "react-i18next";
import Styles from "./styles";

function StringField({ label, children }) {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Styles theme={theme}>
      <div className="label">{label}</div>
      <div className="children-container">{children || t("not_set_yet")}</div>
    </Styles>
  );
}

export default StringField;
