import styled from "styled-components";

const Styles = styled.div`
  a {
    text-decoration: none;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #c7c7c7;
  border-radius: 8px;
  margin: 16px 8px;
  padding: 24px 16px;
  max-width: 192px;
  .name {
    color: #707070;
    font-size: 18px;
    margin-block-start: 1em;
    margin-block-end: 0.25em;
    font-weight: 600;
  }
  .username {
    color: #a7a7a7;
    margin-block-start: 0.25em;
    margin-block-end: 0.25em;
    font-size: 12px;
  }
`;

export default Styles;
