import { styled } from "@mui/system";

import { OutlinedInput } from "@mui/material";

import Button from "@mui/material/Button";

export const SearchBar = styled(OutlinedInput)({
  maxWidth: 478,
  paddingLeft: 8,
  paddingRight: 0,
  width: "100%",
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#00000017",
  },
});

export const SearchButton = styled(Button)({
  color: "white",
  borderTopLeftRadius: 0,
  borderBottomLeftRadius: 0,
  padding: 8.5,
  minWidth: 40,
});
