import React from "react";
import { Chip, Grid } from "@mui/material";
import { CategoriesContext } from "components/particles/providers/categories-context";
import StoreDashboardTemplate from "components/templates/store-dashboard";
import { useTranslation } from "react-i18next";
import StringField from "components/molecules/string-field";
import CustomButton from "components/atoms/button";
import CustomTextField from "components/atoms/text-field";
import CustomMultiSelect from "components/molecules/multi-select";
import Styles from "./styles";
import * as Yup from "yup";
import http from "components/particles/http";
import { getStoresUrl } from "components/particles/APIs/API";
import toastSuccess from "components/particles/toast/success";
import toastError from "components/particles/toast/error";

function MyStoreProfilePage() {
  const [state, setState] = React.useState({
    currentStore: null,
    displayMode: "view",
    categoriesOption: [],
    profile: null,
    originalProfile: null,
    category_ids: [],
  });

  const [errors, setErrors] = React.useState({});
  const { categories } = React.useContext(CategoriesContext);
  const { t } = useTranslation();

  React.useEffect(() => {
    let categoriesOption = [];
    if (categories && categories.length > 0) {
      categoriesOption = categories.map((category) => ({
        value: category.id,
        name: category.name,
      }));
    }

    setState((prevState) => ({
      ...prevState,
      categoriesOption,
    }));
  }, [categories]);

  const updateCurrentStore = (store) => {
    let storeProfile = {
      name: store.name,
      email: store.email,
      description: store.description,
      phone_number: store.phone_number,
      city_town_id: store.city_town_id,
      address: store.address,
      delivery_email: store.delivery_email,
      delivery_fees: store.delivery_fees,
      username: store.username,
      open_at: store.open_at,
      close_at: store.close_at,
      category_ids: store.category_ids,
    };
    setState((prevState) => ({
      ...prevState,
      currentStore: store,
      profile: storeProfile,
      originalProfile: storeProfile,
    }));
  };

  const toggleDisplayMode = () => {
    let newMode = "view";
    if (state.displayMode === "view") {
      setErrors({});
      newMode = "edit";
    }
    setState((prevState) => ({
      ...prevState,
      displayMode: newMode,
      profile: { ...prevState.originalProfile },
    }));
  };

  const getCategoriesName = () => {
    if (!state.currentStore || !state.currentStore.category_ids || categories.length === 0)
      return [t("no_categories_yet")];
    let categoriesName = state.currentStore.category_ids.map((categoryId) => {
      return categories.find((category) => category.id === categoryId).name;
    });
    return categoriesName;
  };

  const onChangeInput = ({ target: input }) => {
    setState({ ...state, profile: { ...state.profile, [input.name]: input.value } });
    setErrors({ ...errors, [input.name]: "" });
  };

  const onChangeCategories = (value) => {
    let categoryIds = [...state.profile.category_ids];
    if (categoryIds.includes(value)) {
      categoryIds = categoryIds.filter((categoryId) => categoryId !== value);
    } else {
      categoryIds.push(value);
    }
    setState({ ...state, profile: { ...state.profile, category_ids: categoryIds } });
    setErrors({ ...errors, category_ids: "" });
  };

  const ProfileValidationSchema = Yup.object().shape({
    category_ids: Yup.array()
      .min(1, t("select_category_at_least"))
      .required(t("is_required", { field: t("category") })),
    delivery_fees: Yup.number()
      .typeError(t("is_required", { field: t("delivery_fees") }))
      .min(0, t("is_invalid", { field: t("delivery_fees") })),
    delivery_email: Yup.string()
      .email(t("is_invalid", { field: t("delivery_email") }))
      .required(t("is_required", { field: t("delivery_email") })),
    address: Yup.string().required(t("is_required", { field: t("address") })),
    description: Yup.string().required(t("is_required", { field: t("description") })),
    // phone_number: Yup.string().required(t("is_required", { field: t("phone_number") })),
    username: Yup.string().required(t("is_required", { field: t("username") })),
    name: Yup.string().required(t("is_required", { field: t("store_name") })),
  });

  const handleUpdate = () => {
    const { profile } = state;
    ProfileValidationSchema.validate(profile)
      .then((response) => {
        console.log(response);
        http
          .post(`${getStoresUrl}/${state.currentStore.id}`, profile)
          .then((response) => {
            console.log(response);
            toastSuccess("operation_done_successfully");
            setState({
              ...state,
              currentStore: { ...state.currentStore, ...state.profile },
              originalProfile: { ...state.profile },
            });
          })
          .catch((error) => {
            if (error.response.data.errors) {
              error.response.data.errors.forEach((err) => {
                toastError(err.message);
              });
            } else {
              toastError(error.response.data.message);
            }
          });
      })
      .catch((err) => {
        console.log(err.path, err);
        setErrors({ ...errors, [err.path]: err.errors[0] });
      });
  };

  const renderViewStoreInfoBlock = () => {
    if (state.displayMode !== "view") return null;
    const { currentStore } = state;
    console.log(currentStore);
    if (!currentStore) return null;
    return (
      <Grid container className="container-profile">
        <Grid item xs={12} sm={6} md={4} lg={3} className="border-right-grid">
          <StringField label={t("store_name")}>{currentStore.name}</StringField>
          <StringField label={t("email")}>{currentStore.email}</StringField>
          <StringField label={t("description")}>{currentStore.description}</StringField>
          <StringField label={t("phone_number")}>{currentStore.phone_number}</StringField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} className="border-right-grid">
          <StringField label={t("username")}>@{currentStore.username}</StringField>
          <StringField label={t("address")}>{currentStore.address}</StringField>
          <StringField label={t("open_at")}>{currentStore.open_at}</StringField>
          <StringField label={t("close_at")}>{currentStore.close_at}</StringField>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3} sx={{ paddingLeft: 3 }}>
          {getCategoriesName().map((category, index) => (
            <Chip className="category-chip" key={index} label={category} />
          ))}
        </Grid>
        <Grid item xs={12} sx={{ paddingLeft: 3, paddingTop: 3 }}>
          <CustomButton color="secondary" style={{ minWidth: 90 }} onClick={toggleDisplayMode}>
            {t("edit")}
          </CustomButton>
        </Grid>
      </Grid>
    );
  };

  const renderTextFeild = (name, label = name, type) => {
    return (
      <CustomTextField
        key={name}
        fullWidth
        name={name}
        label={t(label)}
        type={type !== "textarea" ? type : ""}
        multiline={type === "textarea"}
        rows={type === "textarea" ? 2 : 1}
        value={state.profile[name]}
        onChange={onChangeInput}
        error={!!errors[name]}
        helperText={errors[name]}
      />
    );
  };

  const renderEditStoreInfoBlock = () => {
    return (
      <Grid container className="container-profile">
        <Grid item xs={12} sm={6} md={4} className="border-right-grid">
          {renderTextFeild("name", "store_name")}
          {renderTextFeild("username")}
          {renderTextFeild("phone_number", "phone_number", "number")}
          {renderTextFeild("description", "description", "textarea")}
        </Grid>
        <Grid item xs={12} sm={6} md={4} className="border-right-grid">
          {renderTextFeild("address")}
          {renderTextFeild("delivery_email")}
          {renderTextFeild("delivery_fees", "delivery_fees", "number")}
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{ paddingLeft: 3 }}>
          {renderTextFeild("open_at")}
          {renderTextFeild("close_at")}
          <CustomMultiSelect
            name="category_ids"
            optionList={state.categoriesOption}
            value={state.profile.category_ids || []}
            onChange={onChangeCategories}
            label={t("category")}
            error={!!errors.category_ids}
            helperText={errors.category_ids}
          />
        </Grid>
        <Grid item xs={12} sx={{ paddingLeft: 3, paddingTop: 3 }}>
          <CustomButton
            color="primary"
            style={{ minWidth: 90, marginInlineEnd: 8 }}
            onClick={handleUpdate}
          >
            {t("update")}
          </CustomButton>
          <CustomButton color="secondary" style={{ minWidth: 90 }} onClick={toggleDisplayMode}>
            {t("cancel")}
          </CustomButton>
        </Grid>
      </Grid>
    );
  };

  return (
    <StoreDashboardTemplate onLoadStore={updateCurrentStore}>
      <Styles>
        <h1>{t("store_profile")}</h1>
        {state.displayMode === "view"
          ? renderViewStoreInfoBlock()
          : renderEditStoreInfoBlock()}
      </Styles>
    </StoreDashboardTemplate>
  );
}

export default MyStoreProfilePage;
