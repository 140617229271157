import { Stack } from "@mui/material";
import { styled } from "@mui/styles";

const StyledStack = styled(Stack)({
  margin: "auto",
  width: "90%",
  padding: "0 32px",
  overflowX: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

export default StyledStack;
