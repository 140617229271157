import styled from "styled-components";

const Styles = styled.div`
  .title {
    font-weight: 600;
  }
  .categories-container {
    height: 320px;
    overflow-y: auto;
    ::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #d0d0d2;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.palette.primary.main};
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #32a4a6;
    }
  }
  .category-item {
    margin-bottom: 12px;
    cursor: pointer;
    &:hover {
      color: black;
    }
  }
  .category-item.selected::before {
    content: " ";
    width: 0;
    height: 0;
    border-left: 3px solid ${(props) => props.theme.palette.secondary.main};
    padding-right: 3px;
  }
  .category-item.selected {
    font-weight: 500;
  }
  .price-range > span:not(:first-child) {
    font-weight: 600;
    margin: 0px 4px;
  }
  .price-range > span:first-child {
    color: red;
    color: #a7a7a7;
    margin-inline-end: 4px;
    ::after {
      content: ":";
    }
  }
  .price-thumb {
    width: 15px;
    height: 15px;
  }
`;

export default Styles;
