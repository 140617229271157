import React from "react";

import CloseIcon from "@mui/icons-material/Close";

import MenuIcon from "@mui/icons-material/Menu";

import CustomSwipeableDrawer from "./styles";

import { IconButton } from "@mui/material";
import { AppContext } from "components/particles/providers/app-context";

function AppDrawer({ children }) {
  const { appDrawerOpen, dispatch } = React.useContext(AppContext);

  const toggleDrawer = (open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    dispatch({
      type: "setOpenDrawer",
      payload: {
        open,
      },
    });
  };
  return (
    <>
      <IconButton onClick={toggleDrawer(true)}>
        <MenuIcon />
      </IconButton>

      <CustomSwipeableDrawer
        className="app-drawer"
        anchor="left"
        open={appDrawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <IconButton onClick={toggleDrawer(false)} className="close-button">
          <CloseIcon />
        </IconButton>
        {children}
      </CustomSwipeableDrawer>
    </>
  );
}

export default AppDrawer;
