import React from "react";

import StyledButton from "./styles";

function CustomButton({ variant = "contained", color = "primary", children, ...props }) {
  return (
    <StyledButton disableElevation color={color} variant={variant} {...props}>
      {children}
    </StyledButton>
  );
}

export default CustomButton;
