import { useTheme } from "@emotion/react";
import CustomButton from "components/atoms/button";
import React from "react";

import Styles from "./styles";

function BannerCard({ title, image, to, height, style }) {
  const theme = useTheme();

  return (
    <Styles background={image} theme={theme} height={height} style={style}>
      <CustomButton
        href={to}
        className="banner-button"
        size="large"
        disableElevation
        color="inherit"
      >
        {title}
      </CustomButton>
    </Styles>
  );
}

export default BannerCard;
