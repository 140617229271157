import styled from "styled-components";

const Styles = styled.div`
  .paggination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }
  .search-container {
    display: flex;
    justify-content: space-between;
  }
  .no-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 50px;
  }
  .no-item-icon {
    font-size: 120px;
    color: #a6a6a666;
  }
  .no-item-message {
    font-size: 20px;
    color: #a7a7a7;
  }
  .add-item-btn {
    height: 39px;
    min-width: 100px;
    white-space: nowrap;
  }
`;

export default Styles;
