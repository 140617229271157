import styled from "styled-components";

export const Styles = styled.div`
  .app-template__header {
    top: 0;
    width: 100%;
    z-index: 999;
    position: fixed;
    background: white;
  }

  .app-template__nav {
  }

  .app-template__content {
    overflow-x: hidden;

    margin-top: 9rem;
    @media only screen and (max-width: 600px) {
      margin-top: 13rem;
    }
  }

  .app-template__footer {
  }
`;
