import React from "react";
import { getStoreProfilePictureByName } from "components/particles/APIs/API";
import StoreAvatarLink from "../store-avatar-link";
import StyledStack from "./styles";
import { getStores } from "constants/navigation";

function StoreAvatarStack({ storeList }) {
  return (
    <StyledStack direction="row" spacing={8}>
      {storeList.map((store, index) => {
        return (
          <StoreAvatarLink
            key={index}
            storeName={store.name}
            imageSrc={
              store.picture ? getStoreProfilePictureByName(store.picture, "medium") : null
            }
            to={`${getStores().path}/${store.username}`}
          />
        );
      })}
    </StyledStack>
  );
}

export default StoreAvatarStack;
