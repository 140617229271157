import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  padding: 16px;
  .check-container {
    display: flex;
    align-items: center;
  }
  .image-container {
    display: flex;
  }
  img {
    width: 86px;
    padding: 16px 64px;
  }

  .product-name {
    margin-block-start: 0em;
    font-size: 18px;
  }
  .quantity-space {
    display: flex;
    align-items: center;
  }
  .product-price {
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
  .product-name-quantity {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 240px;
    padding: 16px 32px;
  }
  .total-price-container {
    display: flex;
    justify-content: center;
    padding: 16px 32px;
    min-width: 100px;
  }
  .total-price {
    font-size: 16px;
    font-weight: 600;
    margin-right: 4px;
    align-self: center;
  }
  .currency-code {
    font-size: 16px;
    font-weight: 600;
    color: #a7a7a7;
    align-self: center;
  }
  .action-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 32px;
    min-width: 120px;
    button {
      width: 40px;
      height: 40px;
      border-radius: 8px;
      background: ${(props) => props.theme.palette.secondary.main};
    }
  }
`;

export default Styles;
