import React from "react";
import { Grid } from "@mui/material";
import CustomBreadcrumbs from "components/atoms/bread-crumbs";
import CategoryCard from "components/molecules/category-card";
import { getCategoryIconByName } from "components/particles/APIs/API";
import { CategoriesContext } from "components/particles/providers/categories-context";
import SecondaryPageTemplate from "components/templates/secondary-page";
import { getCategories, getHome } from "constants/navigation";
import { useTranslation } from "react-i18next";
import Styles from "./styles";

function CategoriesPage() {
  const { categories } = React.useContext(CategoriesContext);
  const { t } = useTranslation();

  const CategoriesCardGrid = () => {
    return (
      <>
        {categories.map((category, index) => {
          return (
            <Grid key={index} item xs={6} sm={4} md={3} lg={2}>
              <CategoryCard
                name={category.name}
                icon={getCategoryIconByName(category.icon)}
                to={`${getCategories().path}/${category.reference}`}
              />
            </Grid>
          );
        })}
      </>
    );
  };

  return (
    <SecondaryPageTemplate>
      <Styles>
        <div className="breadcrumbs">
          <CustomBreadcrumbs routes={[getHome()]} pageName={t(getCategories().name)} />
        </div>
        <div className="wrapper">
          {/* <div className="filter-section"></div> */}
          <Grid container>
            <CategoriesCardGrid />
          </Grid>
        </div>
      </Styles>
    </SecondaryPageTemplate>
  );
}

export default CategoriesPage;
