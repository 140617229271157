import React from "react";

import ProductCard from "../product-card";

import StyledStack from "./styles";

function TopRatedProductStack({ productList }) {
  return (
    <StyledStack direction="row" spacing={2}>
      {productList.map((product, index) => {
        return <ProductCard key={index} productObject={product} />;
      })}
    </StyledStack>
  );
}

export default TopRatedProductStack;
