import React, { useEffect, useState } from "react";

import Styles from "./styles";

import CustomTextField from "components/atoms/text-field";

import { HiOutlineUserCircle, HiOutlineLockClosed } from "react-icons/hi";

import CustomButton from "components/atoms/button";

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import { useTheme } from "@emotion/react";

import { getHome, getLogin, getStoreSignUp, getCustomerSignUp } from "constants/navigation";

import AuthTemplate from "components/templates/auth";
import { Box } from "@mui/system";
import * as Yup from "yup";
import http from "components/particles/http";
import toastSuccess from "components/particles/toast/success";
import toastError from "components/particles/toast/error";
import { getCustomersUrl } from "components/particles/APIs/API";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";

const CustomerRegisterPage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [state, setState] = useState({
    name: "",
    email: "",
    password: "",
    isLoading: false,
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    password: "",
  });

  useEffect(() => {
    return function cleanup() {
      toast.dismiss();
    };
  });

  const RegisterValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("is_required", { field: t("password") }))
      .min(8, t("password_invalid", { minChar: 8 })),
    email: Yup.string()
      .email(t("is_invalid", { field: t("email") }))
      .required(t("is_required", { field: t("email") })),
    name: Yup.string().required(t("is_required", { field: t("name") })),
  });

  const onChangeInput = ({ target: input }) => {
    setState({ ...state, [input.name]: input.value });
    setErrors({ ...errors, [input.name]: "" });
    // for testing input
    // console.log(input.name, input.value);
  };

  const setLoading = (isLoading) => {
    setState({ ...state, isLoading: isLoading });
  };

  const RegisterCustomerHandler = (event) => {
    event.preventDefault();
    let { name, email, password } = state;

    // validate inputs
    RegisterValidationSchema.validate({ name, email, password })
      .then((userInfo) => {
        http
          .post(getCustomersUrl, userInfo)
          .then((response) => {
            setLoading(false);
            console.log(response);
            toastSuccess(t("done_check_email"));
          })
          .catch((error) => {
            setLoading(false);
            // console.log(error.response.data.message);
            // console.log(error.response.data.errors);
            error.response.data.errors.forEach((err) => {
              toastError(err.message);
            });
          });
      })
      .catch((err) => {
        setErrors({ [err.path]: err.errors[0] });
      });
  };

  return (
    <Styles theme={theme}>
      <AuthTemplate
        headerTitle={t("sign_up")}
        pageName={t(getCustomerSignUp().name)}
        routeList={[getHome()]}
        subHeader={
          <div className="create-account">
            {t("have_store")}
            <Link to={getStoreSignUp().path}>{t("create_store_account_here")}</Link>
          </div>
        }
      >
        <form onSubmit={RegisterCustomerHandler}>
          <CustomTextField
            fullWidth
            name="name"
            label={t("name")}
            placeholder={t("name_placeholder")}
            value={state.name}
            onChange={onChangeInput}
            error={!!errors.name}
            helperText={errors.name}
          />

          <CustomTextField
            fullWidth
            name="email"
            label={t("email")}
            placeholder={t("email_hint")}
            value={state.email}
            onChange={onChangeInput}
            error={!!errors.email}
            helperText={errors.email}
            startAdornment={
              <HiOutlineUserCircle fontSize={24} color={theme.palette.secondary.main} />
            }
          />

          <CustomTextField
            fullWidth
            type="password"
            name="password"
            label={t("password")}
            placeholder={t("enter_password")}
            value={state.password}
            onChange={onChangeInput}
            error={!!errors.password}
            helperText={errors.password}
            startAdornment={
              <HiOutlineLockClosed fontSize={24} color={theme.palette.secondary.main} />
            }
          />

          <CustomButton
            fullWidth
            disableElevation
            style={{ marginTop: 20 }}
            type="submit"
            disabled={state.isLoading}
          >
            {state.isLoading && <CircularProgress size="16px" sx={{ margin: "0px 8px" }} />}{" "}
            {t("register")}
          </CustomButton>
          <Box mt={2} className="create-account">
            {t("have_an_account")}
            <Link to={getLogin().path}>{t("login_here")}</Link>
          </Box>
        </form>
      </AuthTemplate>
    </Styles>
  );
};

export default CustomerRegisterPage;
