import styled from "styled-components";

const Styles = styled.div`
  background: ${(props) => props.color};
  margin: auto;
  height: 1px;
  width: 90%;
`;

export default Styles;
