import { Box } from "@mui/system";
import CustomBreadcrumbs from "components/atoms/bread-crumbs";
import AppDrawer from "components/organisms/drawer-menu";
import ProductFilter from "components/organisms/product-filter";
import { getAllStores, getProductsUrl, getStoresUrl } from "components/particles/APIs/API";
import http from "components/particles/http";
import React from "react";
import { useParams } from "react-router";
import SecondaryPageTemplate from "../secondary-page";
import PhotoSizeSelectActualOutlinedIcon from "@mui/icons-material/PhotoSizeSelectActualOutlined";

import Styles from "./styles";
import FileUploader from "components/molecules/file-uploder";
import { useTranslation } from "react-i18next";
import { AuthContext } from "components/particles/providers/auth-context";
import toastError from "components/particles/toast/error";
import MyStoreNavbar from "components/organisms/mystore-navbar";

function StoreDashboardTemplate({
  filter,
  onFilter,
  onResetFilter,
  pageName,
  breadcrumbsRoutes,
  onLoadStore,
  children,
}) {
  const [state, setState] = React.useState({
    currentStore: null,
    isUplodingCover: false,
    maxPrice: 0,
    minPrice: 0,
  });
  const { user } = React.useContext(AuthContext);
  const { username } = useParams();
  const { t } = useTranslation();

  const isOwnerUser = user && user.username === username;

  React.useEffect(() => {
    http
      .get(`${getAllStores}/${username}`)
      .then((response) => {
        let { data: store } = response.data;
        setState((prevState) => ({ ...prevState, currentStore: store }));
        onLoadStore(store);
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line
  }, [username]);

  React.useEffect(() => {
    // calling api from backend
    if (!state.currentStore) return;
    http
      .get(`${getProductsUrl}/price-range`, { params: { store_id: state.currentStore.id } })
      .then((response) => {
        let { max_price, min_price } = response.data.data;
        setState((prevState) => ({ ...prevState, minPrice: min_price, maxPrice: max_price }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [state.currentStore]);

  const setUploadingCover = (status) => {
    setState({ ...state, isUplodingCover: status });
  };

  const onChangeCoverPhoto = (file) => {
    var formData = new FormData();
    let { currentStore } = state;
    formData.append("picture", file);
    setUploadingCover(true);
    http
      .post(getStoresUrl + "/cover-pictures", formData)
      .then((response) => {
        let { name } = response.data.data;
        http
          .post(`${getStoresUrl}/${currentStore.id}/cover-picture`, { cover_picture: name })
          .then(() => {
            setUploadingCover(false);
            setState({ ...state, currentStore: { ...currentStore, cover_picture: name } });
          });
      })
      .catch((error) => {
        setUploadingCover(false);
        if (error.response.data.error) {
          error.response.data.errors.forEach((err) => {
            toastError(err.message);
          });
        } else {
          toastError(error.response.data.message);
        }
      });
  };

  const StoreHeader = () => {
    if (!state.currentStore) return null;
    const { currentStore } = state;
    return (
      <Styles headerCoverPicture={currentStore.cover_picture}>
        <div className="store-header">
          <div>
            <h1>{currentStore.name}</h1>
            <p>{currentStore.description}</p>
          </div>
          <div className="header-action">
            {isOwnerUser && (
              <FileUploader
                label={t("edit_image")}
                accept="image/png, image/jpeg"
                color="primary"
                startIcon={<PhotoSizeSelectActualOutlinedIcon />}
                onChange={onChangeCoverPhoto}
                disabled={state.isUplodingCover}
              />
            )}
          </div>
        </div>
      </Styles>
    );
  };

  return (
    <SecondaryPageTemplate withoutBanner={true}>
      <Styles>
        <div className="breadcrumbs">
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <AppDrawer>
              <ProductFilter
                onFilter={onFilter}
                onResetFilter={onResetFilter}
                minPrice={state.minPrice}
                maxPrice={state.maxPrice}
                withoutBorder
              />
            </AppDrawer>
          </Box>

          <CustomBreadcrumbs routes={breadcrumbsRoutes} pageName={pageName} />
        </div>
        <div className="header-wrapper">
          <StoreHeader />
          <MyStoreNavbar />
        </div>
        <div className="wrapper">
          {filter && (
            <Box className="filter-section" sx={{ display: { xs: "none", md: "block" } }}>
              <ProductFilter
                onFilter={onFilter}
                onResetFilter={onResetFilter}
                minPrice={state.minPrice}
                maxPrice={state.maxPrice}
              />
            </Box>
          )}
          <div className="right-side">{children}</div>
        </div>
      </Styles>
    </SecondaryPageTemplate>
  );
}

export default StoreDashboardTemplate;
