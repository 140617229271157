import { Button } from "@mui/material";
import React from "react";
import Styles from "./styles";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useTheme } from "@emotion/react";

function QuantityButtonGroup({ quantity = 1, onChange, maxQuantity = 100 }) {
  const theme = useTheme();
  return (
    <Styles theme={theme}>
      <Button
        variant="contained"
        color="secondary"
        disableElevation
        disabled={quantity === 1}
        onClick={() => {
          onChange(quantity - 1);
        }}
      >
        <RemoveIcon />
      </Button>
      <div className="qantity">{quantity}</div>
      <Button
        variant="contained"
        color="secondary"
        disableElevation
        disabled={quantity === maxQuantity}
        onClick={() => {
          onChange(quantity + 1);
        }}
      >
        <AddIcon />
      </Button>
    </Styles>
  );
}

export default QuantityButtonGroup;
