import React from "react";

import Styles from "./styles";
import * as Yup from "yup";

import * as ENDPIONTS from "components/particles/APIs/API";
import { useTranslation } from "react-i18next";
import UserProfileTemplate from "components/templates/profile";
import { getHome, getMyProfile } from "constants/navigation";
import { AuthContext } from "components/particles/providers/auth-context";
import CustomTextField from "components/atoms/text-field";
import { Grid } from "@mui/material";
import CustomButton from "components/atoms/button";
import http from "components/particles/http";
import toastError from "components/particles/toast/error";

const MyProfilePage = () => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [state, setState] = React.useState({
    account: user,
  });
  const [errors, setErrors] = React.useState({});
  const [isUpdating, setUpdating] = React.useState(false);

  const { t } = useTranslation();

  React.useEffect(() => {
    http
      .get(`${ENDPIONTS.getCustomersUrl}/${user.id}`)
      .then((response) => {
        let user = response.data.data;
        dispatch({ type: "updateUser", payload: { user } });
        setState((prevState) => ({ ...prevState, account: user }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [user.id, dispatch]);

  const ProfileValidationSchema = Yup.object().shape({
    // category_ids: Yup.array()
    //   .min(1, t("select_category_at_least"))
    //   .required(t("is_required", { field: t("category") })),

    address: Yup.string().required(t("is_required", { field: t("address") })),
    // phone_number: Yup.string().required(t("is_required", { field: t("phone_number") })),
    username: Yup.string().required(t("is_required", { field: t("username") })),
    name: Yup.string().required(t("is_required", { field: t("name") })),
  });

  const onChangeInput = ({ target: input }) => {
    setState({ ...state, account: { ...state.account, [input.name]: input.value } });
    setErrors({ ...errors, [input.name]: "" });
  };

  const updateUserAccount = () => {
    ProfileValidationSchema.validate(state.account)
      .then((userProfile) => {
        setUpdating(true);
        http
          .post(`${ENDPIONTS.getCustomersUrl}/${user.id}`, userProfile)
          .then((response) => {
            console.log(response);
            setUpdating(false);
            dispatch({ type: "updateUser", payload: { user: userProfile } });
          })
          .catch((error) => {
            setUpdating(false);
            if (error.response.data.errors) {
              error.response.data.errors.forEach((err) => {
                toastError(err.message);
              });
            } else {
              toastError(error.response.data.message);
            }
          });
      })
      .catch((err) => {
        console.log(err.path, err);
        setErrors({ ...errors, [err.path]: err.errors[0] });
      });
  };

  return (
    <UserProfileTemplate pageName={t(getMyProfile().name)} routeList={[getHome()]}>
      <Styles>
        <h1>{t("my_profile")}</h1>
        <Grid container sx={{ marginTop: 6 }}>
          <Grid item xs={12} sm={12} md={6} lg={4} className="col-input-container">
            <CustomTextField
              fullWidth
              name="name"
              label={t("name")}
              value={state.account.name}
              onChange={onChangeInput}
              error={!!errors.name}
              helperText={errors.name}
            />
            <CustomTextField
              fullWidth
              name="username"
              label={t("username")}
              value={state.account.username}
              onChange={onChangeInput}
              error={!!errors.username}
              helperText={errors.username}
            />
            <CustomTextField
              fullWidth
              name="address"
              label={t("address")}
              value={state.account.address}
              onChange={onChangeInput}
              error={!!errors.address}
              helperText={errors.address}
            />
            {/* <CustomTextField
              fullWidth
              name="phone_number"
              label={t("phone_number")}
              value={state.account.phone_number}
              onChange={onChangeInput}
              error={!!errors.phone_number}
              helperText={errors.phone_number}
            /> */}
          </Grid>
          {/* <Grid item xs={12} sm={6} md={4} className="col-input-container">
            <CustomTextField fullWidth name="name" label={t("name")} value={user.name} />
          </Grid> */}
        </Grid>
        <CustomButton
          color="secondary"
          onClick={updateUserAccount}
          disabled={isUpdating || state.account === user}
        >
          {t("update")}
        </CustomButton>
      </Styles>
    </UserProfileTemplate>
  );
};

export default MyProfilePage;
