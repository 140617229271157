import Swal from "sweetalert2";
import i18next from "i18next";

const deleteConfirmationDialog = () => {
  return Swal.fire({
    title: i18next.t("are_you_sure"),
    text: i18next.t("permanent_delete_warning"),
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!",
  });
};

export default deleteConfirmationDialog;
