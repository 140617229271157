import { useTheme } from "@emotion/react";
import CustomButton from "components/atoms/button";
import React from "react";
import { useTranslation } from "react-i18next";

import Styles from "./styles";

function FilterTemplate({ withoutBorder, onFilter, onResetFilter, children }) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Styles theme={theme} withoutBorder={withoutBorder}>
      {children}
      <CustomButton onClick={onFilter} color="primary" sx={{ minWidth: 180, marginTop: 3 }}>
        {t("filter")}
      </CustomButton>
      <CustomButton
        onClick={onResetFilter}
        variant="link"
        className="clear-filter-btn"
        disableRipple
      >
        {t("reset_filter")}
      </CustomButton>
    </Styles>
  );
}

export default FilterTemplate;
