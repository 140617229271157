import React from "react";

import Styles from "./styles";

import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import { BsPerson } from "react-icons/bs";
import { useTheme } from "@emotion/react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";

function AccountMenu({ startIcon, asLink, title, to, children }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const theme = useTheme();

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Styles>
      <Button
        ref={anchorRef}
        to={to}
        component={asLink ? Link : null}
        id="account-button"
        aria-controls={open ? "account-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={!asLink ? handleToggle : null}
        color="inherit"
        disableRipple={true}
        startIcon={startIcon ? startIcon : <BsPerson color={theme.palette.secondary.main} />}
        endIcon={!asLink ? <KeyboardArrowDownIcon /> : null}
      >
        {title}
      </Button>
      {!asLink && (
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          style={{
            zIndex: 999,
          }}
        >
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="account-menu"
                    aria-labelledby="account-menu"
                    onKeyDown={handleListKeyDown}
                    sx={{ minWidth: 150, border: "1px solid #00000017" }}
                    dense
                  >
                    {children}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      )}
    </Styles>
  );
}

export default AccountMenu;
