import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  div.header:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  div.header:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .order-id {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.palette.primary.main};
    font-weight: 600;
    min-width: 60px;
  }

  .order-total {
    font-weight: 600;
    font-size: 16px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
  .order-status {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-width: 240px;
    padding: 16px 32px;
    margin-block-start: 0em;
    font-size: 18px;
  }
  .total-price-container {
    display: flex;
    justify-content: center;
    padding: 16px 32px;
    min-width: 200px;
  }
  .total-price {
    font-size: 16px;
    font-weight: 600;
    margin-right: 4px;
    align-self: center;
  }
  .currency-code {
    font-size: 16px;
    font-weight: 600;
    color: #a7a7a7;
    align-self: center;
  }
  .action-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 16px 32px;
    min-width: 160px;

    button.view-btn {
      min-width: 40px;
      height: 40px;
      border-radius: 8px;
      margin-inline-end: 8px;
      color: ${(props) => props.theme.palette.primary.main};
    }
    button.delete-btn {
      padding: 0px;
      min-width: 40px;
      height: 40px;
      border-radius: 8px;
      background: ${(props) => props.theme.palette.secondary.main};
    }
  }
  .header {
    height: 40px;
    display: flex;
    align-items: center;
    background: #a7a7a7;
    font-weight: 600;
    font-size: 18px;
    color: white;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .status-chip {
    color: white;
    border-radius: 8px;
    height: 24px;
  }
`;

export default Styles;
