import React from "react";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import i18n from "components/particles/i18n";

import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";

// initial state
const initialState = { lang: i18n.language, dir: i18n.dir(), appDrawerOpen: false };

const reducer = (state, action) => {
  switch (action.type) {
    case "changeLanguage":
      i18n.changeLanguage(action.payload.language);

      return {
        ...state,
        lang: i18n.language,
        dir: i18n.dir(),
      };
    case "setOpenDrawer":
      return {
        ...state,
        appDrawerOpen: action.payload.open,
      };
    default:
      return state;
  }
};

const AppContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  const theme = createTheme({
    direction: state.dir,
    palette: {
      mode: "light",
      primary: {
        main: "#5CBFC0",
        dark: "#32a4a6",
      },
      secondary: {
        main: "#F46B5B",
      },
    },
    typography: {
      fontFamily: `Poppins`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <AppContext.Provider
        value={{
          lang: state.lang,
          dir: state.dir,
          appDrawerOpen: state.appDrawerOpen,
          dispatch,
        }}
      >
        {children}
        <ToastContainer rtl={state.lang === "ar"} />
      </AppContext.Provider>
    </ThemeProvider>
  );
};

export default AppContextProvider;

export const AppContext = React.createContext();
AppContext.displayName = "AppContext";
