import React from "react";

import Styles from "./styles";

import { IconButton, Stack } from "@mui/material";

import { getProductImageByName } from "components/particles/APIs/API";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useTheme } from "@emotion/react";
import i18n from "components/particles/i18n";

function ItemGallary({ imageList, primaryImgSize = "medium", secondaryImgSize = "small" }) {
  const [state, setState] = React.useState({
    mainImage: imageList[0] || null,
  });
  const theme = useTheme();
  const stackRef = React.useRef(null);

  const setMainIamge = (picture) => {
    setState({ ...state, mainImage: picture });
  };

  const scrollValue = 200;
  const scrollStackLeft = () => {
    stackRef.current.scroll({
      left: stackRef.current.scrollLeft + scrollValue,
      behavior: "smooth",
    });
  };

  const scrollStackRight = () => {
    stackRef.current.scroll({
      left: stackRef.current.scrollLeft - scrollValue,
      behavior: "smooth",
    });
  };

  const { mainImage } = state;

  return (
    <Styles theme={theme}>
      <img
        src={getProductImageByName(mainImage.name, primaryImgSize)}
        alt={mainImage.name}
        className="main-img"
      />
      <div className="image-stack-container">
        <IconButton
          color="primary"
          onClick={i18n.language === "ar" ? scrollStackLeft : scrollStackRight}
        >
          {i18n.language === "ar" ? <NavigateNextIcon /> : <NavigateBeforeIcon />}
        </IconButton>
        <Stack direction="row" className="mui-stack" ref={stackRef}>
          {imageList.map((picture) => {
            return (
              <input
                type="image"
                key={picture.name}
                className={
                  mainImage.name === picture.name ? "mini-image active" : "mini-image"
                }
                style={imageList.length === 1 ? { marginInlineEnd: 0 } : null}
                src={getProductImageByName(picture.name, secondaryImgSize)}
                alt={picture.name}
                onClick={() => setMainIamge(picture)}
              />
            );
          })}
        </Stack>
        <IconButton
          color="primary"
          onClick={i18n.language === "ar" ? scrollStackRight : scrollStackLeft}
        >
          {i18n.language === "ar" ? <NavigateBeforeIcon /> : <NavigateNextIcon />}
        </IconButton>
      </div>
    </Styles>
  );
}

export default ItemGallary;
