import styled from "styled-components";

const Styles = styled.div`
  .breadcrumbs {
    display: flex;
    margin-bottom: 32px;
  }
  .wrapper {
    display: flex;
  }
  .header-wrapper {
    margin-bottom: 40px;
  }
  .filter-section {
    max-width: 246px;
    margin-inline-end: 28px;
  }
  .right-side {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

export default Styles;
