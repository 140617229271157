import React from "react";

import PropTypes from "prop-types";

import Styles from "./styles";
import { useTheme } from "@emotion/react";

function ContactInfo({ type, contact, children, style }) {
  const theme = useTheme();
  return (
    <Styles style={style} theme={theme}>
      <a href={`${type}:${contact}`}>
        <span>{children}</span>
        <span dir="ltr">{contact}</span>
      </a>
    </Styles>
  );
}

ContactInfo.propTypes = {
  type: PropTypes.oneOf(["mailto", "tel"]),
  contact: PropTypes.string.isRequired,
  pr: PropTypes.string,
};

export default ContactInfo;
