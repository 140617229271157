import React from "react";
import { getAllCategories } from "components/particles/APIs/API";
import http from "components/particles/http";

const initialState = {
  categories: [],
  isLoaded: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setCategories":
      return {
        ...state,
        categories: action.payload.categories,
        isLoaded: action.payload.isLoaded || false,
      };
    default:
      return state;
  }
};

function CategoriesContextProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  React.useEffect(() => {
    http
      .get(getAllCategories, { params: { with_subcategories: true } })
      .then((response) => {
        let { list } = response.data.data;

        dispatch({
          type: "setCategories",
          payload: {
            categories: list,
            isLoaded: true,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <CategoriesContext.Provider
      value={{ categories: state.categories, isLoaded: state.isLoaded, dispatch }}
    >
      {props.children}
    </CategoriesContext.Provider>
  );
}

export default CategoriesContextProvider;

export const CategoriesContext = React.createContext();
CategoriesContext.displayName = "CategoriesContext";
