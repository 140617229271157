import React from "react";
import CustomButton from "components/atoms/button";
import MuiCarousel from "components/atoms/carousel";
import { getBannerImageByName } from "components/particles/APIs/API";
import { useTranslation } from "react-i18next";
import { StyledImage, StyledHeader, StyledHeaderWithButton } from "./styles";
import { useTheme } from "@emotion/react";

// imagelist [{id:1, name:"image name",title:"image title", description:"description for the image"}]
function HomeCarousel({ withButton, imageList, height = 425 }) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <MuiCarousel interval={10000}>
      {imageList.map((pic) => {
        return (
          <div key={pic.id}>
            <StyledImage
              height={height}
              src={getBannerImageByName(pic.name)}
              alt={pic.title}
            />
            {withButton ? (
              <StyledHeaderWithButton theme={theme}>
                <h2>{pic.title}</h2>
                <p>{pic.description}</p>
                <CustomButton size="large" href={pic.link} variant="contained" color="primary">
                  {t("shop_now")}
                </CustomButton>
              </StyledHeaderWithButton>
            ) : (
              <StyledHeader>
                {pic.link ? <a href={pic.link}>{pic.title}</a> : <h2>{pic.title}</h2>}
                <p>{pic.description}</p>
              </StyledHeader>
            )}
          </div>
        );
      })}
    </MuiCarousel>
  );
}

export default HomeCarousel;
