import React from "react";

import Styles from "./styles";

import pawpawLogo from "images/pawLogo.svg";

import PawLogo from "components/atoms/paw-logo";

import Navigator from "../navigator/index";

import SearchBox from "components/molecules/search-box/index";

import IconNavButton from "components/atoms/icon-nav-button";

import { MdOutlineShoppingBag } from "react-icons/md";

import { HiOutlineMail } from "react-icons/hi";

import { Grid } from "@mui/material";

import NavigationMenu from "../navigator-menu";

import { useTranslation } from "react-i18next";

import {
  getCart,
  getNotification,
  getProducts,
  getWishlist,
  // getCustomerSignUp,
  // getLogin,
  // getStoreSignUp,
} from "constants/navigation";

import { Box } from "@mui/system";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { useHistory } from "react-router-dom";

function LowerNavbar({ isAuthenticated, cartItemsCount }) {
  const { t } = useTranslation();
  let history = useHistory();

  const onSearch = (keyword) => {
    if (keyword) history.push(`${getProducts().path}?search=${keyword}`);
  };

  const isAuthPage = () => {
    // let { pathname } = window.location;
    // return (
    //   pathname === getLogin().path ||
    //   pathname === getStoreSignUp().path ||
    //   pathname === getCustomerSignUp().path
    // );
    return false;
  };

  return (
    <Styles>
      <Grid container className="container">
        <Grid item xs sm={3} md={3} lg={2}>
          <PawLogo src={pawpawLogo} />
        </Grid>

        <Grid item xs sx={{ display: { lg: "block", xs: "none" } }}>
          <Navigator />
        </Grid>

        {!isAuthPage() && (
          <Grid item xs sx={{ display: { xs: "none", sm: "block" } }} pl={1} pr={1}>
            <SearchBox placeholder={t("search_here")} onClickSearch={onSearch} />
          </Grid>
        )}

        {isAuthenticated && (
          <Grid item sm={3} md={2} xl={1} className="icon-nav-container">
            <IconNavButton itemCount={cartItemsCount} to={getCart().path}>
              <MdOutlineShoppingBag size={28} />
            </IconNavButton>
            <IconNavButton to={getWishlist().path}>
              <FavoriteBorderIcon style={{ fontSize: 28 }} />
            </IconNavButton>

            <IconNavButton to={getNotification().path}>
              <HiOutlineMail size={28} />
            </IconNavButton>
          </Grid>
        )}

        <Grid item sx={{ display: { xs: "block", lg: "none" } }}>
          <NavigationMenu />
        </Grid>
      </Grid>

      {!isAuthPage() && (
        <Box
          width="100%"
          sx={{
            display: { xs: "flex", sm: "none" },
            justifyContent: "center",
            margin: "16px 0",
          }}
        >
          <SearchBox placeholder={t("search_here")} onClickSearch={onSearch} />
        </Box>
      )}
    </Styles>
  );
}

export default LowerNavbar;
