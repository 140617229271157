import React from "react";

import { useTheme } from "@emotion/react";

import { Avatar } from "@mui/material";

import Styles from "./styles";
import { Link } from "react-router-dom";

function StoreAvatarLink({ imageSrc, storeName, to }) {
  const theme = useTheme();
  return (
    <Styles theme={theme}>
      <Link to={to}>
        <Avatar
          src={imageSrc}
          alt={storeName}
          sx={{
            width: 96,
            height: 96,
            fontSize: 32,
            bgcolor: theme.palette.secondary.main,
            boxShadow: "0 0 0 3px #f3f5f6 inset",
            border: `2px solid ${theme.palette.primary.main}`,
          }}
        >
          {storeName.charAt(0)}
        </Avatar>
      </Link>
      <Link to={to}>{storeName}</Link>
    </Styles>
  );
}

export default StoreAvatarLink;
