import React, { useEffect, useState } from "react";
import { Grid, Hidden } from "@mui/material";
import HomeCarousel from "components/molecules/home-carousel";
import BannerCard from "components/molecules/banner-card";
import { getBannerImageByName, getBannersByref } from "components/particles/APIs/API";
import http from "components/particles/http";

const homeBannerRef = "home_main_banner";

function HomeBanner() {
  const [state, setState] = useState({
    height: 425,
    pictures: [],
  });

  useEffect(() => {
    http
      .get(getBannersByref(homeBannerRef))
      .then((response) => {
        setState(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const isMoreThreeBanner = state.pictures.length > 2;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={isMoreThreeBanner ? 8 : 12} lg={isMoreThreeBanner ? 9 : 12}>
        {state.pictures.length > 0 && (
          <HomeCarousel imageList={state.pictures} height={state.height} />
        )}
      </Grid>
      <Hidden mdDown>
        <Grid item md={4} lg={3}>
          {state.pictures.length > 2 && (
            <>
              <BannerCard
                title={state.pictures[state.pictures.length - 2].title}
                image={getBannerImageByName(state.pictures[state.pictures.length - 2].name)}
                to={state.pictures[state.pictures.length - 2].link}
                height={state.height / 2 - 6}
                style={{ marginBottom: 16 }}
              />
              <BannerCard
                title={state.pictures[state.pictures.length - 1].title}
                image={getBannerImageByName(state.pictures[state.pictures.length - 1].name)}
                to={state.pictures[state.pictures.length - 1].link}
                height={state.height / 2 - 6}
              />
            </>
          )}
        </Grid>
      </Hidden>
    </Grid>
  );
}

export default HomeBanner;
