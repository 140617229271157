import styled from "styled-components";

import bgStore from "images/bg_store.jpg";

const Styles = styled.div`
  .no-item-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    @media (max-width: 900px) {
      margin-bottom: 100px;
    }
  }
  .no-item-icon {
    font-size: 120px;
    color: #a6a6a666;
  }
  .no-item-message {
    font-size: 20px;
    color: #a7a7a7;
  }
  .search-sort-container {
    display: flex;
    justify-content: space-between;
  }
  .sort-select {
    max-width: 120px;
    div.MuiOutlinedInput-root {
      margin-top: 0px;
    }
    select {
      padding: 8px 14px;
    }
  }
  .store-header {
    padding: 16px 48px;
    background-image: url(${bgStore});
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    border-radius: 16px;
    min-height: 160px;
    h1 {
      font-size: 2.5rem;
      margin-block-end: 0em;
    }
    p {
      color: #707070;
      padding-inline-start: 8px;
    }
  }
  .paggination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;
  }
`;

export default Styles;
