import React from "react";

import { getBannersByref } from "components/particles/APIs/API";
import HomeCarousel from "components/molecules/home-carousel";
import http from "components/particles/http";
import Styles from "./styles";

const homeBannerRef = "home_ads_banner";

function HomeAdsBanner() {
  const [state, setState] = React.useState({
    height: 425,
    pictures: [],
  });

  React.useEffect(() => {
    let isMounted = true;

    http
      .get(getBannersByref(homeBannerRef))
      .then((response) => {
        if (isMounted) setState(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <Styles>
      <HomeCarousel withButton imageList={state.pictures} height={state.height} />
    </Styles>
  );
}

export default HomeAdsBanner;
