import React from "react";
import { Box } from "@mui/system";
import Footer from "components/organisms/footer";
import HomeAdsBanner from "components/organisms/home-staff/home-ads-banner";
import Navbar from "components/organisms/navbar";
import AppTemplate from "../app";

import Styles from "./styles";

function SecondaryPageTemplate({ children, withoutBanner }) {
  return (
    <AppTemplate header={<Navbar />} footer={<Footer />}>
      <Styles>
        {children}
        {!withoutBanner && (
          <Box mt={8}>
            <HomeAdsBanner />
          </Box>
        )}
      </Styles>
    </AppTemplate>
  );
}

export default SecondaryPageTemplate;
