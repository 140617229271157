import React from "react";

import { useTheme } from "@emotion/react";

import { getAllProducts } from "components/particles/APIs/API";

import { useTranslation } from "react-i18next";

import TopRatedProductStack from "components/molecules/top-rated-product-stack";

import { getProducts } from "constants/navigation";

import { Link } from "react-router-dom";

import http from "components/particles/http";

import Styles from "./styles";
import paggination from "constants/paggination";

function HomeTopRatedProduct() {
  const [state, setState] = React.useState({
    productList: [],
  });

  const { t } = useTranslation();
  const theme = useTheme();

  React.useEffect(() => {
    http
      .get(getAllProducts, {
        params: {
          sort_by: "rating",
          enabled_only: true,
          order: "desc",
          page: 1,
          count: paggination.pageSize,
        },
      })
      .then((response) => {
        let { list } = response.data.data;
        setState((prevState) => ({ ...prevState, productList: list }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Styles theme={theme}>
      <div className="top-rated__header">
        <h1>{t("top_rated_product")}</h1>
        <Link to={`${getProducts().path}?sort=rating`}>{t("view_all")}</Link>
      </div>
      <TopRatedProductStack productList={state.productList} />
    </Styles>
  );
}

export default HomeTopRatedProduct;
