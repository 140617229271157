import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  button {
    margin: 8px;
  }

  .mini-image {
    margin-inline-end: 24px;
    opacity: 0.5;
    width: 100%;
  }
  .mini-image.active {
    border: 2px solid ${(props) => props.theme.palette.primary.main};
    border-radius: 8px;
    opacity: 1;
  }
  .image-stack-container {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
  }
  .mui-stack {
    overflow-x: auto;
  }
  .mui-stack::-webkit-scrollbar {
    display: none;
  }
  .main-img {
    width: 100%;
    max-width: fit-content;
  }
`;

export default Styles;
