import React, { useContext, useEffect, useState } from "react";

import Styles from "./styles";

import http from "components/particles/http";

import * as ENDPIONTS from "components/particles/APIs/API";
import { useTranslation } from "react-i18next";
import UserProfileTemplate from "components/templates/profile";
import { getHome, getNotification } from "constants/navigation";
import { AuthContext } from "components/particles/providers/auth-context";

const NotificationPage = () => {
  const [state, setState] = useState({
    notificationList: [],
  });
  const { user } = useContext(AuthContext);

  const { t } = useTranslation();

  useEffect(() => {
    http
      .get(ENDPIONTS.getUserNotificationById(user.id))
      .then((response) => {
        let { list } = response.data.data;

        console.log(response, list);
        setState((prevState) => ({ ...prevState, notificationList: list || [] }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [user.id]);

  return (
    <UserProfileTemplate pageName={t(getNotification().name)} routeList={[getHome()]}>
      <Styles>
        <h1>{t("notification")}</h1>
        {state.notificationList.length === 0 && (
          <p className="no-items">{t("no_notification")}</p>
        )}
      </Styles>
    </UserProfileTemplate>
  );
};

export default NotificationPage;
