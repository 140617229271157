import React from "react";
import { useTheme } from "@emotion/react";
import RubbishBinIcon from "components/atoms/rubbish-bin";
import { CurrencyContext } from "components/particles/providers/currency-context";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CustomButton from "components/atoms/button";
import { useTranslation } from "react-i18next";
import Styles from "./styles";
import { getOrderStatusBycode } from "constants/order-status";
import { Chip } from "@mui/material";

function StoreOrderRow({ orderId, orderCode, totalPrice, onClickDelete, onClickView }) {
  let theme = useTheme();
  const { currency } = React.useContext(CurrencyContext);
  const { t } = useTranslation();
  const OrderStatus = getOrderStatusBycode(orderCode);

  if (!orderId && !totalPrice) {
    return (
      <Styles theme={theme}>
        {/* <div className="order-header"> */}
        <div className="order-id header">{"#" + t("id")}</div>
        <div className="order-status header">{t("order_status")}</div>
        <div className="total-price-container header">{t("total_price")}</div>
        <div className="action-container header">{t("actions")}</div>
        {/* </div> */}
      </Styles>
    );
  }
  return (
    <Styles theme={theme}>
      <div className="order-id">{orderId}</div>
      <div className="order-status">
        <Chip color={OrderStatus.color} label={t(OrderStatus.key)} className="status-chip" />
      </div>
      <div className="total-price-container">
        <span className="total-price">{totalPrice}</span>
        <span className="currency-code">{currency.code.toUpperCase()}</span>
      </div>
      <div className="action-container">
        {onClickView && (
          <CustomButton
            color="primary"
            variant="text"
            className="view-btn"
            onClick={onClickView}
            startIcon={<VisibilityIcon />}
          >
            {t("view")}
          </CustomButton>
        )}
        {onClickDelete && (
          <CustomButton
            color="secondary"
            variant="outlined"
            className="delete-btn"
            onClick={onClickDelete}
          >
            <RubbishBinIcon />
          </CustomButton>
        )}
      </div>
    </Styles>
  );
}

export default StoreOrderRow;
