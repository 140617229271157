import React from "react";

import { FormControl, NativeSelect, FormHelperText } from "@mui/material";

import { InputField, InputFieldLabel } from "./styles";

// optionList is an array [{value:1,name:"that what user see in option"}]
const CustomSelect = ({
  label,
  name,
  optionList = [],
  className,
  placeholder,
  value,
  onChange,
  error,
  helperText,
  style = { marginBottom: 24 },
  color = "secondary",
  firstChildHidden,
}) => {
  return (
    <FormControl
      sx={{ width: "100%" }}
      style={style}
      color={color}
      error={error}
      className={className}
    >
      <InputFieldLabel shrink>{label}</InputFieldLabel>
      <NativeSelect
        name={name}
        value={value}
        onChange={onChange}
        input={<InputField placeholder={placeholder} />}
      >
        {optionList.map((option, index) => {
          return (
            <option
              key={option.value}
              value={option.value}
              hidden={index === 0 && firstChildHidden}
            >
              {option.name}
            </option>
          );
        })}
      </NativeSelect>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};

export default CustomSelect;
