import React from "react";
import FilterTemplate from "components/templates/filter";
import { CategoriesContext } from "components/particles/providers/categories-context";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import { BsCheck } from "react-icons/bs";
import { Slider, Box } from "@mui/material";
import { CurrencyContext } from "components/particles/providers/currency-context";
import Styles from "./styles";

function ProductFilter({ withoutBorder, onFilter, onResetFilter, minPrice, maxPrice }) {
  const [state, setState] = React.useState({
    selectedCategoryIds: [],
    minPrice,
    maxPrice,
  });
  const { categories } = React.useContext(CategoriesContext);
  const { currency } = React.useContext(CurrencyContext);
  const theme = useTheme();
  const { t } = useTranslation();

  React.useEffect(() => {
    setState((prevState) => ({ ...prevState, minPrice, maxPrice }));
  }, [minPrice, maxPrice]);

  const formatePriceWithCurrency = (price) => {
    switch (currency.position) {
      case "start":
        return currency.symbol + price;

      default:
        return price + currency.symbol;
    }
  };

  const selectCategory = (categoryId) => {
    let selectedIds = [...state.selectedCategoryIds];
    if (!selectedIds.includes(categoryId)) selectedIds.push(categoryId);
    else {
      selectedIds = selectedIds.filter((id) => id !== categoryId);
    }
    setState({ ...state, selectedCategoryIds: selectedIds });
  };

  const handleChangePriceRange = (event, newRange, activeThumb) => {
    let minDistance = 10;
    if (!Array.isArray(newRange)) {
      return;
    }

    if (activeThumb === 0) {
      setState({
        ...state,
        minPrice: Math.min(newRange[0], state.maxPrice - minDistance),
      });
    } else {
      setState({
        ...state,
        maxPrice: Math.max(newRange[1], state.minPrice + minDistance),
      });
    }
  };
  const resetFilter = () => {
    setState({ selectedCategoryIds: [], minPrice, maxPrice });
    onResetFilter();
  };

  return (
    <FilterTemplate
      withoutBorder={withoutBorder}
      onFilter={() => onFilter(state)}
      onResetFilter={resetFilter}
    >
      <Styles theme={theme}>
        <div>
          <p className="title">{t("all_categories")}</p>
          <div className="categories-container">
            {categories.map((category, index) => {
              return (
                <div
                  key={index}
                  onClick={() => selectCategory(category.id)}
                  onKeyDown={() => selectCategory(category.id)}
                  role="button"
                  tabIndex={0}
                  className="category-item"
                >
                  {category.name}
                  {state.selectedCategoryIds.includes(category.id) ? <BsCheck /> : null}
                </div>
              );
            })}
          </div>
          <div>
            <p className="title">{t("price")}</p>
            <Box width="90%" sx={{ margin: "auto" }}>
              <Slider
                min={minPrice}
                max={maxPrice}
                value={[state.minPrice, state.maxPrice]}
                disableSwap
                isRtl={true}
                onChange={handleChangePriceRange}
                classes={{ thumb: "price-thumb" }}
                disabled={maxPrice === 0}
              />
              <div className="price-range">
                <span>{t("price")}</span>
                <span>{formatePriceWithCurrency(state.minPrice)}</span>-
                <span>{formatePriceWithCurrency(state.maxPrice)}</span>
              </div>
            </Box>
          </div>
        </div>
      </Styles>
    </FilterTemplate>
  );
}

export default ProductFilter;
