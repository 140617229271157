import React from "react";
import { Grid } from "@mui/material";
import CustomButton from "components/atoms/button";
import { getBrandsUrl, getProductsUrl, getStoresUrl } from "components/particles/APIs/API";
import StoreDashboardTemplate from "components/templates/store-dashboard";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import CustomTextField from "components/atoms/text-field";
import { CategoriesContext } from "components/particles/providers/categories-context";
import CustomSelect from "components/atoms/select";
import http from "components/particles/http";
import Styles from "./styles";
import CustomInputFileUploder from "components/molecules/input-file-uploder";
import * as Yup from "yup";
import toastError from "components/particles/toast/error";
import toastSuccess from "components/particles/toast/success";
import {
  getCreateProduct,
  getHome,
  getMyStore,
  getMyStoreProducts,
} from "constants/navigation";

function CreateProductPage() {
  const [state, setState] = React.useState({
    currentStore: {},
    brandsOption: [],
    selectedBrandId: 0,
    categoriesOption: [],
    selectedCategoryId: 0,
    subCategoriesOption: [],
    selectedSubCategoryId: 0,
    pictures: null,
    name: "",
    quantity: "",
    base_price: "",
    sale: 0,
    description: "",
    sku: "",
    isUploadingProduct: false,
  });
  const [errors, setErrors] = React.useState({});
  const [isUploadingProduct, setUploadingProduct] = React.useState(false);
  const { t } = useTranslation();
  const { categories } = React.useContext(CategoriesContext);

  const updateCurrentStore = (store) => {
    setState((prevState) => ({ ...prevState, currentStore: store }));
  };

  // get brands and set it as brand option list
  React.useEffect(() => {
    http
      .get(getBrandsUrl)
      .then((response) => {
        let { list } = response.data.data;
        let brandsOption = [];
        brandsOption.push({
          value: 0,
          name: t("none"),
        });

        if (list) {
          let options = list.map((brand) => ({
            value: brand.id,
            name: brand.name,
          }));
          brandsOption = brandsOption.concat(options);
        }

        setState((prevState) => ({
          ...prevState,
          brandsOption,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [t]);

  // get categories and set it as category option list
  React.useEffect(() => {
    let categoriesOption = [];
    let selectedCategoryId = 0;
    if (categories && categories.length > 0) {
      categoriesOption = categories.map((category) => ({
        value: category.id,
        name: category.name,
      }));
      selectedCategoryId = categoriesOption[0].value;
    }

    setState((prevState) => ({
      ...prevState,
      categoriesOption,
      selectedCategoryId,
    }));
  }, [categories]);

  // sub categories update base on selected category
  React.useEffect(() => {
    let subCategoriesOption = [];
    let selectedCategory = categories.find(
      (category) => category.id === parseInt(state.selectedCategoryId)
    );
    if (selectedCategory && selectedCategory.sub_categories) {
      subCategoriesOption = selectedCategory.sub_categories.map((subCategory) => ({
        value: subCategory.id,
        name: subCategory.name,
      }));
    }
    setState((prevState) => ({ ...prevState, subCategoriesOption }));
  }, [categories, state.selectedCategoryId]);

  const ProductValidationSchema = Yup.object().shape({
    // selectedBrandId: Yup.number()
    //   .not([0], t("an_error_happens"))
    //   .required(t("is_required", { field: t("brand") })),
    selectedCategoryId: Yup.number()
      .not([0], t("an_error_happens"))
      .required(t("is_required", { field: t("category") })),
    description: Yup.string().required(t("is_required", { field: t("description") })),
    sale: Yup.number().min(0, t("min_value_warning", { field: t("sale"), min: 0 })),
    base_price: Yup.number()
      .typeError(t("is_required", { field: t("price") }))
      .min(1, t("min_value_warning", { field: t("price"), min: 1 })),
    quantity: Yup.number()
      .typeError(t("is_required", { field: t("quantity") }))
      .min(1, t("min_value_warning", { field: t("quantity"), min: 1 })),
    name: Yup.string().required(t("is_required", { field: t("product_name") })),
  });

  const onChangeInput = ({ target: input }) => {
    let numberInputList = [
      "sale",
      "base_price",
      "quantity",
      "selectedSubCategoryId",
      "selectedCategoryId",
      "selectedBrandId",
    ];
    if (numberInputList.includes(input.name) && !isNaN(input.value)) {
      setState({ ...state, [input.name]: parseInt(input.value) });
      setErrors({ ...errors, [input.name]: "" });
    } else {
      setState({ ...state, [input.name]: input.value });
      setErrors({ ...errors, [input.name]: "" });
    }
  };

  const onChangeImageInput = (event) => {
    const images = event.target.files;
    setState({ ...state, pictures: images });
  };

  const onSubmitCreateProduct = () => {
    const {
      name,
      quantity,
      base_price,
      sale,
      description,
      sku,
      selectedBrandId,
      selectedCategoryId,
      selectedSubCategoryId,
      pictures,
      currentStore,
    } = state;

    ProductValidationSchema.validate({
      name,
      quantity,
      base_price,
      sale,
      description,
      sku,
      selectedBrandId,
      selectedCategoryId,
      selectedSubCategoryId,
    })
      .then((product) => {
        if (!pictures) {
          toastError(t("please_select_product_image"));
          return;
        } else {
          setUploadingProduct(true);
          let formData = new FormData();
          Array.from(pictures).forEach((pic) => {
            formData.append("pictures", pic);
          });
          // upload the picture and get names
          http
            .post(`${getProductsUrl}/pictures`, formData)
            .then((response) => {
              let { list } = response.data.data;
              let picture_ids = list.map((pic) => pic.id);
              // lets call the create product api
              http
                .post(`${getStoresUrl}/${currentStore.id}/products`, {
                  ...newProduct,
                  picture_ids,
                })
                .then((response) => {
                  console.log(response);
                  setUploadingProduct(false);
                  toastSuccess(t("product_added_successfuly"));
                })
                .catch((error) => {
                  setUploadingProduct(false);
                  error.response.data.errors.forEach((err) => {
                    toastError(err.message);
                  });
                });
            })
            .catch((error) => {
              setUploadingProduct(false);
              error.response.data.errors.forEach((err) => {
                toastError(err.message);
              });
            });
        }
        let { selectedCategoryId, selectedSubCategoryId, selectedBrandId, ...prod } = product;
        let newProduct = {
          ...prod,
          category_id: selectedCategoryId,
          sub_category_id: selectedSubCategoryId,
          brand_id: selectedBrandId,
        };
        console.log(newProduct);
      })
      .catch((err) => {
        console.log(err);
        setErrors({ ...errors, [err.path]: err.errors[0] });
      });
  };

  let { currentStore } = state;
  return (
    <StoreDashboardTemplate
      breadcrumbsRoutes={[
        getHome(),
        getMyStore(currentStore.username),
        getMyStoreProducts(currentStore.username),
      ]}
      pageName={t(getCreateProduct(state.currentStore.username).name)}
      onLoadStore={updateCurrentStore}
    >
      <Styles>
        <h1>{t("create_product")}</h1>

        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <CustomTextField
              fullWidth
              name="name"
              value={state.name}
              label={t("product_name")}
              onChange={onChangeInput}
              error={!!errors.name}
              helperText={errors.name}
            />
            <CustomTextField
              fullWidth
              name="quantity"
              type="number"
              min={0}
              value={state.quantity}
              label={t("quantity")}
              onChange={onChangeInput}
              error={!!errors.quantity}
              helperText={errors.quantity}
            />
            <CustomTextField
              fullWidth
              name="base_price"
              type="number"
              value={state.base_price}
              label={t("price")}
              onChange={onChangeInput}
              error={!!errors.base_price}
              helperText={errors.base_price}
            />
            <CustomTextField
              fullWidth
              name="sale"
              value={state.sale}
              type="number"
              label={t("sale")}
              onChange={onChangeInput}
              endAdornment={<div>%</div>}
              error={!!errors.sale}
              helperText={errors.sale}
            />
            <CustomTextField
              fullWidth
              multiline
              rows={2}
              name="description"
              value={state.description}
              label={t("description")}
              onChange={onChangeInput}
              error={!!errors.description}
              helperText={errors.description}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={5} lg={4}>
            <CustomSelect
              label={t("category")}
              name="selectedCategoryId"
              optionList={state.categoriesOption}
              value={state.selectedCategoryId}
              onChange={onChangeInput}
              error={!!errors.selectedCategoryId}
              helperText={errors.selectedCategoryId}
            />
            <CustomSelect
              label={t("sub_category")}
              name="selectedSubCategoryId"
              optionList={state.subCategoriesOption}
              value={state.selectedSubCategoryId}
              onChange={onChangeInput}
              error={!!errors.selectedSubCategoryId}
              helperText={errors.selectedSubCategoryId}
            />
            <CustomSelect
              label={t("brand")}
              name="selectedBrandId"
              optionList={state.brandsOption}
              value={state.selectedBrandId}
              onChange={onChangeInput}
              // error={!!errors.selectedBrandId}
              // helperText={errors.selectedBrandId}
            />

            <CustomTextField
              fullWidth
              name="sku"
              label={t("sku")}
              value={state.sku}
              onChange={onChangeInput}
              error={!!errors.sku}
              helperText={errors.sku}
            />
            <CustomInputFileUploder
              multiple
              maxWidth="400px"
              accept="image/png, image/jpeg"
              label={t("pictures")}
              onChange={onChangeImageInput}
            />
          </Grid>
        </Grid>
        <CustomButton
          color="primary"
          startIcon={<AddIcon />}
          onClick={onSubmitCreateProduct}
          disabled={isUploadingProduct}
        >
          {t("add_product")}
        </CustomButton>
      </Styles>
    </StoreDashboardTemplate>
  );
}

export default CreateProductPage;
