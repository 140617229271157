import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0px;
  width: 90%;
  margin: auto;
  @media screen and (min-width: 348px) {
    .auth-link-container {
      display: flex;
      align-items: center;
    }
  }
`;

export default Styles;
