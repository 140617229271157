import styled from "styled-components";

const Styles = styled.div`
  .container-profile {
    padding: 40px 16px;
    border: 1px solid #c7c7c7;
    border-radius: 8px;
  }
  h1 {
    font-weight: 600;
  }

  .MuiFormControl-root {
    @media (max-width: 600px) {
      max-width: 100%;
    }
    max-width: 400px;
  }
  div.select {
    max-width: 358px;
  }

  .border-right-grid {
    padding-left: 24px;
    padding-right: 24px;
    border-right: 1px solid #c7c7c7;
    @media (max-width: 600px) {
      border-right: none;
    }
  }
  .category-chip {
    border-radius: 8px;
    margin-bottom: 8px;
    margin-inline-end: 8px;
  }
`;

export default Styles;
