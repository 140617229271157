import React from "react";
import { getUsersUrl } from "components/particles/APIs/API";
import StoreDashboardTemplate from "components/templates/store-dashboard";
import { useTranslation } from "react-i18next";
import http from "components/particles/http";
import Styles from "./styles";

import {
  getHome,
  getMyStore,
  getMyStoreBalance,
  getMyStoreOrderDetails,
} from "constants/navigation";
import paggination from "constants/paggination";
import { Pagination } from "@mui/material";
import NotificationStack from "components/organisms/notification-stack";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";

function MyStoreNotificationPage() {
  const [state, setState] = React.useState({
    currentStore: null,
    notificationList: [],
    pageCount: 1,
    currentPage: 1,
  });
  const { t } = useTranslation();
  let history = useHistory();

  React.useEffect(() => {
    let currentStore = state.currentStore;
    let params = {
      count: paggination.pageSize,
      page: state.currentPage,
    };
    if (currentStore) {
      http
        .get(`${getUsersUrl}/${currentStore.id}/notifications`, { params })
        .then((response) => {
          let { data } = response.data;
          console.log(data);
          setState((prevState) => ({
            ...prevState,
            notificationList: data.list || [],
            currentPage: data.current_page,
            pageCount: data.current_page + data.next_pages_count,
          }));
        })
        .then((error) => {
          console.log(error);
        });
    }
  }, [state.currentStore, state.currentPage]);

  const handlePageChange = (event, page) => {
    setState({ ...state, currentPage: page });
  };

  const updateCurrentStore = (store) => {
    setState((prevState) => ({ ...prevState, currentStore: store }));
  };

  const redirectToOrderPage = (orderId, customerId) => {
    console.log(orderId, customerId);
    history.push(getMyStoreOrderDetails(state.currentStore.id, orderId, customerId).path);
  };

  let storeUsername = state.currentStore ? state.currentStore.username : "";

  return (
    <StoreDashboardTemplate
      breadcrumbsRoutes={[getHome(), getMyStore(storeUsername)]}
      pageName={t(getMyStoreBalance(storeUsername).name)}
      onLoadStore={updateCurrentStore}
    >
      <Styles>
        <h1>{t("notification")}</h1>
        {state.notificationList.length === 0 ? (
          <Box mb={6}>{t("no_notification")}</Box>
        ) : (
          <NotificationStack
            notificationList={state.notificationList}
            onClickNotification={redirectToOrderPage}
          />
        )}

        {state.pageCount !== 1 && (
          <div className="paggination-container">
            <Pagination
              color="primary"
              count={state.pageCount}
              variant="outlined"
              shape="rounded"
              page={state.currentPage}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Styles>
    </StoreDashboardTemplate>
  );
}

export default MyStoreNotificationPage;
