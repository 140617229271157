import React from "react";
import * as ENDPIONTS from "components/particles/APIs/API";
import { useTranslation } from "react-i18next";
import UserProfileTemplate from "components/templates/profile";
import { getHome, getWishlist } from "constants/navigation";
import { WishListContext } from "components/particles/providers/wishlist-context";
import WishlistStackItem from "components/organisms/wishlist-stack-item";
import { CurrencyContext } from "components/particles/providers/currency-context";
import toastError from "components/particles/toast/error";
import http from "components/particles/http";

import Styles from "./styles";

const WishlistPage = () => {
  const { wishlistItems, dispatch } = React.useContext(WishListContext);
  const { currency } = React.useContext(CurrencyContext);
  const { t } = useTranslation();
  console.log(wishlistItems);

  const RemoveItemFromWishlist = (itemId) => {
    http
      .delete(ENDPIONTS.getWishlistUrl + `/${itemId}`)
      .then((response) => {
        console.log(response);
        dispatch({ type: "removeFromWishlist", payload: { itemId } });
      })
      .catch((error) => {
        toastError(
          error.response && error.response.data.message
            ? error.response.data.message
            : t("an_error_occure")
        );
      });
  };

  const changeItemQuanitiy = (newValue, oldValue, itemId) => {
    dispatch({ type: "changeItemQuantity", payload: { itemId, quantity: newValue } });
    http
      .post(ENDPIONTS.getWishlistUrl + `/${itemId}`, { quantity: parseInt(newValue) })
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        dispatch({ type: "changeItemQuantity", payload: { itemId, quantity: oldValue } });
        toastError(
          error.response && error.response.data.message
            ? error.response.data.message
            : t("an_error_occure")
        );
      });
  };

  return (
    <UserProfileTemplate pageName={t(getWishlist().name)} routeList={[getHome()]}>
      <Styles>
        <h1>{t("wishlist")}</h1>
        {wishlistItems.length === 0 && <p className="no-items">{t("no_item_wishlist")}</p>}
        <WishlistStackItem
          wishlistItem={wishlistItems}
          currency={currency}
          onDeleteItem={RemoveItemFromWishlist}
          changeQuantityHandler={changeItemQuanitiy}
        />
      </Styles>
    </UserProfileTemplate>
  );
};

export default WishlistPage;
