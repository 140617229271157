export const OrderTypes = [
  { status: 1, key: "in_process", color: "secondary" },
  { status: 2, key: "completed", color: "primary" },
  { status: 3, key: "canceled", color: "error" },
];

export const getOrderStatusBycode = (code) => {
  let type = OrderTypes.find((order) => order.status === code);
  return type || code;
};
