import styled from "styled-components";

const Styles = styled.div`
  .breadcrumbs {
    margin-bottom: 32px;
  }
  .wrapper {
    display: flex;
  }
  .filter-section {
    padding: 16px;
  }
`;

export default Styles;
