import styled from "styled-components";

const Styles = styled.div`
  .profile-template {
    display: flex;
    border: 1px solid #d7d7d7;
    border-radius: 16px;
    padding: 48px 24px;
    width: 90%;
    margin: auto;
    margin-bottom: 48px;
  }
  .breadcrumbs {
    display: flex;
    width: 90%;
    margin: auto;
    margin-bottom: 24px;
  }
`;

export default Styles;
