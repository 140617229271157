import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  flex-direction: ${(props) => props.direction};
  div {
    margin-bottom:${(props) => (props.direction === "column" ? "16px" : "0px")}
  }
  }
`;

export default Styles;
