import Stack from "@mui/material/Stack";
import { styled } from "@mui/system";

export const StyledStack = styled(Stack)({
  justifyContent: "center",
  "& nav, & nav li p": {
    fontWeight: 500,
  },
  "& nav  > ol > li > a": {
    color: "#505050",
    "&:hover": {
      textDecoration: "underline",
      color: "#383838",
    },
    textDecoration: "none",
    fontSize: 14,
  },
});
