import React from "react";

import Styles from "./styles";

import SocialMediaBar from "components/molecules/social-icon-bar";

import ContactInfoBar from "../contact-info-bar";

import AccountMenu from "../account-menu";

import { MenuItem } from "@mui/material";
import {
  contactEmail,
  contactPhone,
  facebook,
  instagram,
  twitter,
} from "constants/contact-info/contact";
import { Box } from "@mui/system";
import { getCustomerRegister, getCustomerSignUp } from "constants/navigation";
import { useTranslation } from "react-i18next";
import { BsPersonPlus } from "react-icons/bs";
import { useTheme } from "@emotion/react";

function UpperNavbar({ accountMenuTitle, accountMenuLink, accountMenuItems }) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Styles>
      <SocialMediaBar facebook={facebook} instagram={instagram} twitter={twitter} />
      <div className="auth-link-container">
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <ContactInfoBar email={contactEmail} phone={contactPhone} />
        </Box>
        {!!accountMenuLink && (
          <AccountMenu
            startIcon={<BsPersonPlus color={theme.palette.secondary.main} />}
            asLink={true}
            title={t(getCustomerRegister().name)}
            to={getCustomerSignUp().path}
          />
        )}

        <AccountMenu asLink={!!accountMenuLink} title={accountMenuTitle} to={accountMenuLink}>
          {accountMenuItems.map((menuItem, index) => {
            return (
              <MenuItem key={index} onClick={menuItem.action} sx={{ fontSize: 15 }}>
                {menuItem.title}
              </MenuItem>
            );
          })}
        </AccountMenu>
      </div>
    </Styles>
  );
}

export default UpperNavbar;
