import { Pagination } from "@mui/material";
import { getStoresUrl } from "components/particles/APIs/API";
import http from "components/particles/http";
import StoreDashboardTemplate from "components/templates/store-dashboard";
import paggination from "constants/paggination";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import StoreOrderStack from "components/organisms/store-orders-stack";
import { useHistory } from "react-router-dom";
import { getMyStoreOrderDetails } from "constants/navigation";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import Styles from "./styles";

function StoreOrderPage() {
  const [state, setState] = React.useState({
    currentStore: null,
    orderList: [],
    pageCount: 1,
    currentPage: 1,
  });
  const { t } = useTranslation();
  let history = useHistory();

  useEffect(() => {
    let currentStore = state.currentStore;
    let params = {
      count: paggination.pageSize,
      page: state.currentPage,
    };
    if (currentStore) {
      http
        .get(`${getStoresUrl}/${currentStore.id}/orders`, { params })
        .then((response) => {
          let { data } = response.data;
          console.log(data);
          setState((prevState) => ({
            ...prevState,
            orderList: data.list || [],
            currentPage: data.current_page,
            pageCount: data.current_page + data.next_pages_count,
          }));
        })
        .then((error) => {
          console.log(error);
        });
    }
  }, [state.currentStore, state.currentPage]);

  const handlePageChange = (event, page) => {
    setState({ ...state, currentPage: page });
  };

  const redirctToView = (orderId) => {
    history.push(getMyStoreOrderDetails(state.currentStore.id, orderId).path);
  };

  const updateCurrentStore = (store) => {
    setState((prevState) => ({ ...prevState, currentStore: store }));
  };

  const NoItemFound = () => {
    return (
      <div className="no-item-container">
        <SearchOffIcon className="no-item-icon" />
        <p className="no-item-message">{t("no_order_found")}</p>
      </div>
    );
  };

  return (
    <StoreDashboardTemplate onLoadStore={updateCurrentStore}>
      <Styles>
        <h1>{t("orders")}</h1>
        {state.orderList.length === 0 ? (
          <NoItemFound />
        ) : (
          <StoreOrderStack orderList={state.orderList} onClickViewItem={redirctToView} />
        )}

        {state.pageCount !== 1 && (
          <div className="paggination-container">
            <Pagination
              color="primary"
              count={state.pageCount}
              variant="outlined"
              shape="rounded"
              page={state.currentPage}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Styles>
    </StoreDashboardTemplate>
  );
}

export default StoreOrderPage;
