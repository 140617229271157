// here you can add a nav item and it will render automaticlly
// just define a const and set it as object { title: "Title for line", to: "link to navigate" }
import i18n from "components/particles/i18n";

export const createNav = (name, path) => ({
  name,
  path: "/" + i18n.language + path,
});

export const getHome = () => createNav("home", "");
export const getStores = () => createNav("stores", "/stores");
export const getCategories = () => createNav("categories", "/categories");
export const getContact = () => createNav("contact_us", "/contact-us");

// navigation list for nav bar and footer nav
export const navigationList = [getHome(), getStores(), getCategories(), getContact()];
// --------------------------------------------------------------------------------
export const helpCenter = () => createNav("help_center", "");
export const paymentMethods = () => createNav("payment_methods", "");
export const returnRefund = () => createNav("return_refund", "");
export const privacyPolicy = () => createNav("privacy_policy", "");
// footer info list for information in footer
export const informationNavList = [
  helpCenter(),
  paymentMethods(),
  returnRefund(),
  privacyPolicy(),
];

// routes in easy way to change - make sure to be a function only

export const getLogin = () => createNav("login", "/login");

export const getCustomerSignUp = () => createNav("sign_up", "/signup/customer");

export const getCustomerRegister = () => createNav("register", "/signup/customer");

export const getStoreSignUp = () => createNav("create_store_account", "/signup/store");

export const getForgotPassword = () => createNav("forgot_password", "/password/reset");

export const getMyProfile = () => createNav("my_profile", "/profile");

export const getCart = () => createNav("shopping_cart", "/cart");

export const getMyOrders = () => createNav("order", "/orders");

export const getMyOrderDetails = (orderId) => createNav("order_details", `/orders/${orderId}`);

export const getWishlist = () => createNav("wishlist", "/wishlist");

export const getNotification = () => createNav("notification", "/notification");

export const getLogout = () => createNav("logout", "/logout");

export const userProfileNavList = [
  getMyProfile(),
  getCart(),
  getWishlist(),
  getMyOrders(),
  getNotification(),
];

export const getTopRatedProduct = () => createNav("top_rated", "/top");

export const getProducts = () => createNav("products", "/products");

export const getMyStore = (storeUsername) =>
  createNav("my_store", `/mystore/${storeUsername}`);

export const getMyStoreProfile = (storeUsername) =>
  createNav("store_profile", `/mystore/${storeUsername}/profile`);

export const getMyStoreProducts = (storeUsername) =>
  createNav("products", `/mystore/${storeUsername}/products`);

export const getCreateProduct = (storeUsername) =>
  createNav("create_product", `/mystore/${storeUsername}/products/create`);

export const getUpdateProduct = (storeUsername, productId) =>
  createNav("update_product", `/mystore/${storeUsername}/products/update/${productId}`);

export const getMyStoreOrders = (storeUsername) =>
  createNav("orders", `/mystore/${storeUsername}/orders`);

export const getMyStoreOrderDetails = (storeUsername, orderId, customerId) =>
  createNav(
    "order_details",
    `/mystore/${storeUsername}/orders/${orderId}${customerId ? "?customer=" + customerId : ""}`
  );

export const getMyStoreBalance = (storeUsername) =>
  createNav("balance", `/mystore/${storeUsername}/balance`);

export const getMyStoreNotification = (storeUsername) =>
  createNav("notification", `/mystore/${storeUsername}/notification`);
