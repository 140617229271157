import styled from "styled-components";
import bgStore from "images/bg_store.jpg";
import { getStoreCoverByName } from "components/particles/APIs/API";

const Styles = styled.div`
  .breadcrumbs {
    display: flex;
    margin-bottom: 32px;
  }
  .wrapper {
    display: flex;
  }
  .header-wrapper {
    margin-bottom: 40px;
  }
  .filter-section {
    max-width: 246px;
    margin-inline-end: 28px;
  }
  .right-side {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .store-header {
    display: flex;
    @media (max-width: 524px) {
      flex-direction: column;
    }
    justify-content: space-between;
    align-items: stretch;
    padding: 16px 48px;
    // background-blend-mode: saturation;
    background-image: linear-gradient(#ffffff80, #ffffff80),
      url(${(props) =>
        props.headerCoverPicture ? getStoreCoverByName(props.headerCoverPicture) : bgStore});

    @media (max-width: 600px) {
      background-image: linear-gradient(#ffffff80, #ffffff80),
        url(${(props) =>
          props.headerCoverPicture
            ? getStoreCoverByName(props.headerCoverPicture, "small")
            : bgStore});
    }
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    border-radius: 16px;
    min-height: 150px;
    h1 {
      font-size: 2.5rem;
      margin-block-end: 0em;
    }
    p {
      color: #707070;
      padding-inline-start: 8px;
    }
  }
  .header-action {
    display: flex;
    align-items: flex-end;
    padding-bottom: 16px;
  }
`;

export default Styles;
