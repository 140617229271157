import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import english from "constants/translation/en_GB.json";
import arabic from "constants/translation/ar_AE.json";

i18n.on("languageChanged", (lang) => {
  let dir = lang === "ar" ? "rtl" : "ltr";
  document.documentElement.lang = lang;
  document.body.dir = dir;
  // get the current language in window.pathname (URL)
  var currentLangPathUrl =
    "/" + i18n.options.whitelist.find((lng) => window.location.pathname.includes("/" + lng));

  // if the lang in URL the same as the changed language there is no need to do anything
  if (currentLangPathUrl === `/${lang}`) return;
  // create new language prefix for URL based on changed language
  var newLangPathUrl = "/" + lang;
  var newUrl = window.location.pathname.replace(currentLangPathUrl, newLangPathUrl);
  window.history.replaceState("", "", newUrl);
});

i18n.use(LanguageDetector).init({
  resources: {
    en: english,
    ar: arabic,
  },

  fallbackLng: "en",
  whitelist: ["en", "ar"],

  detection: {
    order: ["cookie", "path"],
  },

  debug: true,
});

export default i18n;
