import React from "react";
import CategoryCardGrid from "components/molecules/category-cards-grid";
import useMediaQuery from "components/particles/hooks/use-media-query";
import { CategoriesContext } from "components/particles/providers/categories-context";
import { useTranslation } from "react-i18next";
import Styles from "./styles";

function HomeCategories() {
  const { categories } = React.useContext(CategoriesContext);
  const { t } = useTranslation();
  const mediaQuery = useMediaQuery();

  const getViewCount = () => (mediaQuery.qMax600 ? 8 : 12);

  return (
    <Styles>
      <h1>{t("categories")}</h1>
      <CategoryCardGrid categoryList={categories.slice(0, getViewCount())} />
    </Styles>
  );
}

export default HomeCategories;
