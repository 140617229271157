import React from "react";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider, useTheme } from "@emotion/react";
import createCache from "@emotion/cache";

function StylesProvider(props) {
  const theme = useTheme();
  // Create cache base on theme direction to support RTL and LTR
  const cache = createCache({
    key: theme.direction === "rtl" ? "muirtl" : "muiltr",
    stylisPlugins: theme.direction === "rtl" ? [rtlPlugin] : [],
  });

  return <CacheProvider value={cache}>{props.children}</CacheProvider>;
}

export default StylesProvider;
