import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  & > a {
    text-decoration: none;
    color: #383838;
    padding: 0 24px;
    white-space: nowrap;
    font-size:${(props) => props.fontSize};
    margin-bottom:16px;
  }
  & > a:hover {
    color: #000000;
  }
  & > a.active {
    color: ${(props) => props.theme.palette.primary.main};
  },
`;

export default Styles;
