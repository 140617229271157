import styled from "styled-components";

export const Styles = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 8px 32px 32px;

  .form-template {
    display: flex;
    align-items: center;
    flex-direction: column;
    border: 1px solid #d7d7d7;
    border-radius: 16px;
    padding: 48px 64px 64px;
    @media only screen and (max-width: 468px) {
      padding: 32px 32px 64px;
    }
    min-width: 25%;
    max-width: 768px;
    align-self: center;
    margin-bottom: 32px;
  }
  .form-template a {
    text-decoration: none;
    color: ${(props) => props.theme.palette.secondary.main};
    padding: 0px 4px;
  }
  .form-header {
    width: 100%;
    margin-block-end: 3em;
  }
  .form-header > p {
    font-size: 2.4rem;
    @media only screen and (max-width: 600px) {
      font-size: 2rem;
    }
    color: #383838;
    margin-block-start: 0em;
    margin-block-end: 0.1em;
    font-weight: 600;
  }
  .breadcrumbs {
    display: flex;
    width: 90%;
    margin: auto;
    margin-bottom: 24px;
  }
`;
