import styled from "styled-components";

const Styles = styled.div`
  padding-left: 24px;
  h1 {
    margin-block-start: 0em;
    margin-block-end: 0em;
    font-weight: 700;
  }
  .no-items {
    color: #a7a7a7;
  }
`;

export default Styles;
