import styled from "styled-components";

const Styles = styled.div`
  .product-container {
    @media (max-width: 900px) {
      flex-direction: column;
    }
  }
  width: 90%;
  margin: auto;
  margin-bottom: 48px;
  .rating-area {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;
    }
  }
  .container {
    @media (max-width: 540px) {
      flex-direction: column-reverse;
    }
  }

  .product-rating {
    span:not(:first-child) {
      color: #ffbf43;
    }
  }
  .product-rating > span:first-child {
    margin-right: 8px;
    font-weight: 500;
    font-size: 18;
  }

  .product-sold-quantity {
    margin-left: 8px;
    font-weight: 500;
    font-size: 16;
    color: #a7a7a7;
  }

  .wishlist {
    padding: 0px 16px;
    @media (max-width: 410px) {
      #wishlistButton_label {
        display: none;
      }
    }
    @media (min-width: 900px) and (max-width: 1050px) {
      #wishlistButton_label {
        display: none;
      }
    }
  }

  .product-name {
    font-weight: 600;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }
  .product-price {
    font-size: 1.75em;
    font-weight: 700;
    margin-bottom: 16px;
  }
  .product-properties {
    display: flex;
    margin-bottom: 24px;
  }
  .description-container {
    border-top: 1px solid #c7c7c7;
  }
  .desc-word {
    font-weight: 500;
  }
  .product-description {
    color: #a7a7a7;
  }
  .add-cart-container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 425px) {
      flex-direction: column;
    }
  }
  .quantity {
    display: flex;
    align-items: center;
    @media (max-width: 425px) {
      margin-bottom: 24px;
    }
  }
  .quantity > span {
    margin-inline-end: 8px;
    color: #a7a7a7;
  }
  button.cart-btn:hover {
    background: #f44336;
  }
`;

export default Styles;
