import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  align-items: center;
  margin-inline-end: 16px;
  span {
    margin-inline-start: 8px;
    font-weight: 500;
    font-size: 18;
  }
`;

export default Styles;
