import styled from "styled-components";

const Styles = styled.div`
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .create-account {
    color: #a7a7a7;
  }
  .remember-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media only screen and (max-width: 420px) {
      flex-direction: column;
      align-items: flex-start;
      a {
        margin-top: 4px;
      }
    }
  }
`;

export default Styles;
