import React, { useEffect, useState } from "react";

import http from "components/particles/http";
import { getAllStores } from "components/particles/APIs/API";

import StoreAvatarStack from "components/molecules/store-avatar-stack";

import Styles from "./styles";

function HomeStoreAvatar() {
  const [state, setState] = useState({
    storeList: [],
  });

  useEffect(() => {
    http
      .get(getAllStores, {
        params: {
          sort_by: "newest",
        },
      })
      .then((response) => {
        let { list } = response.data.data;
        console.log("storeList", list);
        setState((prevState) => ({ ...prevState, storeList: list }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Styles>
      <StoreAvatarStack storeList={state.storeList} />
    </Styles>
  );
}

export default HomeStoreAvatar;
