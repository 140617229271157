import React from "react";
import { Grid, Pagination } from "@mui/material";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { getHome, getStores } from "constants/navigation";
import { getAllStores } from "components/particles/APIs/API";
import ProductPageTemplate from "components/templates/products";
import { AppContext } from "components/particles/providers/app-context";
import ProductSearchBox from "components/molecules/product-search-box";
import ProductCard from "components/molecules/product-card";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import CustomSelect from "components/atoms/select";
import http from "components/particles/http";
import sortOption from "constants/sort-option";
import paggination from "constants/paggination";
import Styles from "./styles";

function StorePage() {
  const [state, setState] = React.useState({
    currentStore: null,
    products: [],
    filter: null,
    keyword: "",
    sortBy: "default",
    currentPage: 1,
    pageCount: 1,
  });
  const { dispatch } = React.useContext(AppContext);
  const { username } = useParams();
  const { t } = useTranslation();

  React.useEffect(() => {
    http
      .get(`${getAllStores}/${username}`)
      .then((response) => {
        let { data: store } = response.data;
        setState((prevState) => ({ ...prevState, currentStore: store }));
        console.log(store);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [username]);

  React.useEffect(() => {
    if (!state.currentStore) return;
    let params = null;
    if (state.keyword)
      params = {
        search: state.keyword,
      };

    if (state.filter)
      params = {
        ...params,
        categories: `[${state.filter.selectedCategoryIds.toString()}]`,
        min_price: state.filter.minPrice,
        max_price: state.filter.maxPrice,
      };

    if (state.sortBy !== "default")
      params = {
        ...params,
        sort_by: state.sortBy,
      };

    params = {
      ...params,
      count: paggination.pageSize,
      page: state.currentPage,
    };

    // calling api from backend
    http
      .get(`${getAllStores}/${state.currentStore.id}/products`, { params })
      .then((response) => {
        let { data } = response.data;
        console.log(response.data);

        setState((prevState) => ({
          ...prevState,
          currentPage: data.current_page,
          pageCount: data.current_page + data.next_pages_count,
          products: data.list || [],
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [state.currentStore, state.keyword, state.filter, state.sortBy, state.currentPage]);

  const closeDrawer = () => {
    dispatch({
      type: "setOpenDrawer",
      payload: {
        open: false,
      },
    });
  };

  const handleSearch = (searchKeyword) => {
    setState({ ...state, keyword: searchKeyword });
  };

  const handleSortProduct = ({ target: input }) => {
    setState({ ...state, sortBy: input.value });
  };

  const onFilter = (filterObject) => {
    closeDrawer();
    setState((prevState) => ({ ...prevState, filter: filterObject }));
  };

  const resetFilter = () => {
    closeDrawer();
    http
      .get(`${getAllStores}/${state.currentStore.id}/products`)
      .then((response) => {
        let { list } = response.data.data;
        setState((prevState) => ({ ...prevState, products: list || [], filter: null }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handlePageChange = (event, page) => {
    setState({ ...state, currentPage: page });
  };

  const ProductCardGrid = () => {
    return (
      <>
        {state.products.map((product, index) => {
          return (
            <Grid key={index} item>
              <ProductCard productObject={product} sx={{ margin: 8 }} />
            </Grid>
          );
        })}
      </>
    );
  };

  const NoItemFound = () => {
    return (
      <div className="no-item-container">
        <SearchOffIcon className="no-item-icon" />
        <p className="no-item-message">{t("no_item_found")}</p>
      </div>
    );
  };

  const StoreHeader = () => {
    if (!state.currentStore) return null;
    const { currentStore } = state;
    return (
      <Styles>
        <div className="store-header">
          <h1>{currentStore.name}</h1>
          <p>{currentStore.description}</p>
        </div>
      </Styles>
    );
  };

  return (
    <ProductPageTemplate
      filter
      onFilter={onFilter}
      onResetFilter={resetFilter}
      header={<StoreHeader />}
      pageName={state.currentStore ? state.currentStore.name : ""}
      breadcrumbsRoutes={[getHome(), getStores()]}
    >
      <Styles>
        <div className="search-sort-container">
          <ProductSearchBox onClickSearch={handleSearch} />
          <CustomSelect
            color="primary"
            className="sort-select"
            value={state.sortBy}
            optionList={sortOption}
            onChange={handleSortProduct}
            firstChildHidden
          />
        </div>
        <Grid container justifyContent="center">
          {state.products.length > 0 ? <ProductCardGrid /> : <NoItemFound />}
        </Grid>

        {state.pageCount !== 1 && (
          <div className="paggination-container">
            <Pagination
              color="primary"
              count={state.pageCount}
              variant="outlined"
              shape="rounded"
              page={state.currentPage}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Styles>
    </ProductPageTemplate>
  );
}

export default StorePage;
