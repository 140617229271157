import React from "react";

import Styles from "./styles";

import InstagramIcon from "@mui/icons-material/Instagram";

import TwitterIcon from "@mui/icons-material/Twitter";

import FacebookIcon from "@mui/icons-material/Facebook";

import SocialMediaIcon from "components/atoms/social-icon/index";

function SocialMediaBar({ size = "medium", facebook, instagram, twitter }) {
  return (
    <Styles>
      {instagram && (
        <SocialMediaIcon to={instagram}>
          <InstagramIcon fontSize={size} />
        </SocialMediaIcon>
      )}
      {facebook && (
        <SocialMediaIcon to={facebook}>
          <FacebookIcon fontSize={size} />
        </SocialMediaIcon>
      )}

      {twitter && (
        <SocialMediaIcon to={twitter}>
          <TwitterIcon fontSize={size} />
        </SocialMediaIcon>
      )}
    </Styles>
  );
}

export default SocialMediaBar;
