import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepButton from "@mui/material/StepButton";
import { useTranslation } from "react-i18next";
import { useTheme } from "@emotion/react";
import Styles from "./styles";

const steps = ["my_cart", "checkout"];

function CustomStepper({ step, onChangeStep, disabled }) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Styles theme={theme}>
      <Box sx={{ width: { xs: "100%", md: "75%", lg: "50%", xl: "40%" }, margin: "0 auto" }}>
        <Stepper nonLinear activeStep={step}>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton
                onClick={() => onChangeStep(index)}
                className="step-button"
                disabled={disabled}
              >
                {t(label)}
              </StepButton>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Styles>
  );
}
export default CustomStepper;
