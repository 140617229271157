import React from "react";

import Cookies from "js-cookie";

import { decodeBase64, encodeBase64 } from "components/particles/base64";
import http from "components/particles/http";

// initial state
var initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
  isAdmin: false,
  isStore: false,
};

// uio user information object - uat user access token
let userBase64Object = Cookies.get("uio");
let base64Token = Cookies.get("uat");

if (userBase64Object && base64Token) {
  let user = JSON.parse(decodeBase64(userBase64Object));
  initialState = {
    isAuthenticated: true,
    user: user,
    token: decodeBase64(base64Token),
    isAdmin: user.accessRole === "a",
    isStore: user.accessRole === "S",
  };
}

const reducer = (state, action) => {
  // 360 days if user select remember me and 30 Min if not select to remember
  switch (action.type) {
    case "login":
      var in30Minutes = action.payload.remember ? { expires: 360 } : { expires: 1 / 48 };
      Cookies.set("uio", encodeBase64(JSON.stringify(action.payload.user)), in30Minutes);
      Cookies.set("uat", encodeBase64(action.payload.token), in30Minutes);
      http.setAuthorizationToken(action.payload.token);
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
        isAdmin: action.payload.accessRole === "a",
        isStore: action.payload.accessRole === "S",
      };

    case "updateUser": {
      Cookies.set("uio", encodeBase64(JSON.stringify(action.payload.user)));
      return { ...state, user: action.payload.user };
    }
    case "logout":
      console.log("calling logout", action);
      Cookies.remove("uio");
      Cookies.remove("uat");
      return { ...initialState, isAuthenticated: false };
    default:
      return state;
  }
};

const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: state.isAuthenticated,
        user: state.user,
        token: state.token,
        isAdmin: state.isAdmin,
        isStore: state.isStore,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;

export const AuthContext = React.createContext();
AuthContext.displayName = "AuthContext";
