import styled from "styled-components";

const Styles = styled.div`
  margin-top: 20px;
  .label {
    margin-bottom: 8px;
    margin-inline-start: 16px;
    position: absolute;
    transform: translateY(-28px);
    color: ${(props) => (props.error ? props.theme.palette.error.main : "inherit")};
  }
  .select {
    height: 80px;
    overflow-y: auto;
    border: 1px solid ${(props) => (props.error ? props.theme.palette.error.main : "#c7c7c7")};
    border-radius: 4px;
    padding: 12px 20px;

    ::-webkit-scrollbar {
      width: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #d0d0d2;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.palette.secondary.main};
      border-radius: 8px;
    }
  }
  .option {
    margin-bottom: 12px;
    cursor: pointer;
    &:hover {
      color: black;
    }
  }
  .option.selected::before {
    content: " ";
    width: 0;
    height: 0;
    border-left: 3px solid ${(props) => props.theme.palette.secondary.main};
    padding-right: 3px;
  }
  .option.selected {
    font-weight: 500;
  }
  .helper-text {
    margin: 3px 14px 0px;
    font-size: 12px;
    color: ${(props) => (props.error ? props.theme.palette.error.main : "inherit")};
  }
`;

export default Styles;
