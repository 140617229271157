import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 4px 24px;
  font-size: 24px;
  background: ${(props) => props.theme.palette.secondary.main};
  border-radius: 8px;
  color: white;

  .total-price-container {
    display: flex;
    justify-content: center;
  }
  .total-price {
    font-weight: 600;
    margin-right: 4px;
    align-self: center;
  }
  .currency-code {
    font-weight: 600;
    align-self: center;
  }
`;

export default Styles;
