import React from "react";

import NavItem from "components/atoms/nav-item/index";

import { navigationList } from "../../../constants/navigation";

import { useTranslation } from "react-i18next";

import Styles from "./styles";

function Navigator() {
  const { t } = useTranslation();
  return (
    <Styles>
      {navigationList.map((nav, index) => (
        <NavItem key={index} to={nav.path}>
          {t(nav.name)}
        </NavItem>
      ))}
    </Styles>
  );
}

export default Navigator;
