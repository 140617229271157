import React from "react";

import { NavLink } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { useTheme } from "@emotion/react";

import { useParams } from "react-router";

import Styles from "./styles";
import {
  getMyStore,
  getMyStoreBalance,
  getMyStoreNotification,
  getMyStoreOrders,
  getMyStoreProducts,
  getMyStoreProfile,
} from "constants/navigation";

function MyStoreNavbar() {
  const { t } = useTranslation();
  const theme = useTheme();
  const { username } = useParams();

  const StoreNavigationList = [
    getMyStore(username),
    getMyStoreProfile(username),
    getMyStoreProducts(username),
    getMyStoreOrders(username),
    getMyStoreBalance(username),
    getMyStoreNotification(username),
  ];

  return (
    <Styles theme={theme}>
      <div className="navigator-area">
        {StoreNavigationList.map((item, index) => {
          return (
            <NavLink exact key={index} to={`${item.path}`} className="store-nav-item">
              {t(item.name)}
            </NavLink>
          );
        })}
      </div>
    </Styles>
  );
}

export default MyStoreNavbar;
