import { useTheme } from "@emotion/react";
import React from "react";

import { NavLink } from "react-router-dom";

import Styles from "./styles";

function NavMenuItem({ to, children, onClick, fontSize = "14px" }) {
  const theme = useTheme();

  return (
    <Styles theme={theme} fontSize={fontSize}>
      <NavLink onClick={onClick} exact to={to}>
        {children}
      </NavLink>
    </Styles>
  );
}

export default NavMenuItem;
