import styled from "styled-components";

const Styles = styled.div`
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .create-account {
    color: #a7a7a7;
  }
  .sub-title {
    width: 100%;
    font-weight: 600;
    font-size: 1.4rem;
    color: #383838;
    margin-bottom: 24px;
    @media only screen and (max-width: 600px) {
      font-size: 1.2rem;
    }
  }
`;

export default Styles;
