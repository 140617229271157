import { Grid } from "@mui/material";
import { getCategoryIconByName } from "components/particles/APIs/API";
import { getCategories } from "constants/navigation";
import React from "react";
import CategoryCard from "../category-card";

function CategoryCardGrid({ categoryList }) {
  return (
    <Grid container spacing={0.5}>
      {categoryList.map((category, index) => {
        return (
          <Grid key={index} item xs={6} sm={4} md={3} lg={2}>
            <CategoryCard
              name={category.name}
              icon={getCategoryIconByName(category.icon)}
              to={`${getCategories().path}/${category.reference}`}
            />
          </Grid>
        );
      })}
    </Grid>
  );
}

export default CategoryCardGrid;
