import { Chip } from "@mui/material";
import React from "react";

import Styles from "./styles";

function ProductOrderItem({ imageUrl, name, status }) {
  return (
    <Styles>
      <div className="image-container">
        <img src={imageUrl} alt="product-img" />
      </div>
      <div>
        <div className="product-name">{name}</div>
        <div className="product-status">
          <Chip color="secondary" label={status} className="product-status-chip" />
        </div>
      </div>
    </Styles>
  );
}

export default ProductOrderItem;
