import React, { useState } from "react";

import { FormControl, FormHelperText, IconButton } from "@mui/material";

import { InputField, InputFieldLabel } from "./styles";

import Visibility from "@mui/icons-material/Visibility";

import VisibilityOff from "@mui/icons-material/VisibilityOff";

function CustomTextField({
  name,
  value,
  onChange,
  label,
  placeholder,
  helperText,
  error,
  fullWidth,
  startAdornment,
  endAdornment,
  type,
  color = "secondary",
  passwordColorIcon = "secondary",
  style = { marginBottom: 24 },
  ...props
}) {
  const [inputType, setInputType] = useState(type);

  const setVisibility = () => {
    inputType === "password" ? setInputType("text") : setInputType("password");
  };

  return (
    <FormControl error={error} style={style} fullWidth={fullWidth} color={color}>
      <InputFieldLabel shrink dir="rtl">
        {label}
      </InputFieldLabel>
      <InputField
        {...props}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        startAdornment={startAdornment}
        type={type === "password" ? inputType : type}
        endAdornment={
          endAdornment ||
          (type === "password" && (
            <IconButton color={passwordColorIcon} onClick={setVisibility}>
              {inputType === "password" ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          ))
        }
      />
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
}

export default CustomTextField;
