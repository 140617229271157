import CustomButton from "components/atoms/button";
import React from "react";

// import Button from "./styles";

const FileUploader = ({
  onChange,
  label,
  accept,
  startIcon,
  color,
  disabled,
  multiple = false,
}) => {
  const hiddenFileInput = React.useRef(null);

  // Programatically click the hidden file input element
  // when the Button component is clicked
  const handleClick = () => {
    hiddenFileInput.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file
  const handleChange = (event) => {
    const fileUploaded = event.target.files[0];
    onChange(fileUploaded);
  };
  return (
    <>
      <CustomButton
        disabled={disabled}
        onClick={handleClick}
        color={color}
        startIcon={startIcon}
      >
        {label}
      </CustomButton>
      <input
        type="file"
        multiple={multiple}
        accept={accept}
        ref={hiddenFileInput}
        onChange={handleChange}
        style={{ display: "none" }}
      />
    </>
  );
};
export default FileUploader;
