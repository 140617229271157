import React, { useContext, useEffect } from "react";

import http from "components/particles/http";
import * as ENDPIONTS from "components/particles/APIs/API";
import { AuthContext } from "../auth-context";

// initial state
var initialState = {
  cartItemsCount: 0,
  cartTotalPrice: 0,
  cartItems: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setItemsCount":
      return {
        ...state,
        cartItemsCount: action.payload.count,
      };
    case "setCartItems":
      return {
        ...state,
        cartItems: action.payload.cartItems,
      };
    case "setCartTotalPrice":
      return {
        ...state,
        cartTotalPrice: action.payload.totalPrice,
      };

    case "addToCart": {
      let itemIndex = state.cartItems.findIndex(
        (item) => item.id === action.payload.cartItem.id
      );
      let cartItemsList = [...state.cartItems];
      let ItemsCount = state.cartItemsCount;
      let totalPrice = 0;
      // if item is not exists
      if (itemIndex === -1) {
        cartItemsList.push(action.payload.cartItem);
        totalPrice = state.cartTotalPrice + action.payload.cartItem.total_price;
        ItemsCount = state.cartItemsCount + 1;
      } else {
        cartItemsList = state.cartItems.map((item) => {
          if (item.id === action.payload.cartItem.id) {
            totalPrice += item.price * action.payload.cartItem.quantity;
            return { ...item, quantity: action.payload.cartItem.quantity };
          } else {
            totalPrice += item.price * item.quantity;
            return item;
          }
        });
      }

      return {
        ...state,
        cartItems: cartItemsList,
        cartTotalPrice: totalPrice,
        cartItemsCount: ItemsCount,
      };
    }
    case "removeFromCart": {
      // payload as {itemId:1}
      let totalPrice = 0;
      let newCartItems = state.cartItems.filter((item) => {
        if (item.id !== action.payload.itemId) {
          totalPrice += item.price * item.quantity;
          return item;
        }
      });

      return {
        ...state,
        cartItems: newCartItems,
        cartTotalPrice: totalPrice,
        cartItemsCount: state.cartItemsCount - 1,
      };
    }
    case "removeItemsFromCart": {
      // payload as {itemsList:[1,2,3] } you can send one item in array also
      let totalPrice = 0;
      let newCartItems = state.cartItems.filter((item) => {
        if (!action.payload.itemsList.include(item.id)) {
          totalPrice += item.price * item.quantity;
          return item;
        }
      });

      return {
        ...state,
        cartItems: newCartItems,
        cartTotalPrice: totalPrice,
        cartItemsCount: state.cartItemsCount - action.payload.itemsList.length,
      };
    }

    case "changeItemQuantity": {
      // payload as {itemId:1, quantity:5}
      let totalPrice = 0;
      var newCartItems = state.cartItems.map((item) => {
        if (item.id === action.payload.itemId) {
          totalPrice += item.price * action.payload.quantity;
          return { ...item, quantity: action.payload.quantity };
        } else {
          totalPrice += item.price * item.quantity;
          return item;
        }
      });

      return {
        ...state,
        cartItems: newCartItems,
        cartTotalPrice: totalPrice,
      };
    }
    case "removeAllItemFromCart": {
      return initialState;
    }
    default:
      return state;
  }
};

const ShoppingCartContextProvider = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const [state, dispatch] = React.useReducer(reducer, initialState);

  useEffect(() => {
    if (isAuthenticated) {
      http
        .get(ENDPIONTS.getShoppingCartUrl)
        .then((response) => {
          let { list } = response.data.data;

          // adding price unit to the array
          let totalPrice = 0;
          if (list) {
            list = list.map((item) => {
              totalPrice += item.total_price;
              return {
                ...item,
                price: item.total_price / item.quantity,
              };
            });
          } else list = [];
          dispatch({ type: "setItemsCount", payload: { count: list.length } });
          dispatch({ type: "setCartTotalPrice", payload: { totalPrice } });
          dispatch({ type: "setCartItems", payload: { cartItems: list } });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isAuthenticated]);

  return (
    <ShoppingCartContext.Provider
      value={{
        cartItemsCount: state.cartItemsCount,
        cartTotalPrice: state.cartTotalPrice,
        cartItems: state.cartItems,
        dispatch,
      }}
    >
      {children}
    </ShoppingCartContext.Provider>
  );
};

export default ShoppingCartContextProvider;

export const ShoppingCartContext = React.createContext();
ShoppingCartContext.displayName = "ShoppingCartContext";
