import React from "react";

import ReactDOM from "react-dom";

import Providers from "components/particles/providers";

import Router from "./router";

import "./index.css";

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <Router />
    </Providers>
  </React.StrictMode>,
  document.getElementById("root")
);
