import styled from "styled-components";

const Styles = styled.div`
  padding: 16px 0px;
  h1 {
    font-weight: 600;
    color: #3d3d3d;
  }
`;

export default Styles;
