import React from "react";
import { useTheme } from "@emotion/react";
import { Link } from "react-router-dom";
import Styles from "./styles";

function CategoryCard({ icon, name, to }) {
  const theme = useTheme();
  return (
    <Styles theme={theme}>
      <Link to={to}>
        <img src={icon} alt={name} width="64px" height="54px" />
        <p>{name}</p>
      </Link>
    </Styles>
  );
}

export default CategoryCard;
