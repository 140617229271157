import React from "react";
import { Box } from "@mui/system";
import CustomBreadcrumbs from "components/atoms/bread-crumbs";
import AppDrawer from "components/organisms/drawer-menu";
import ProductFilter from "components/organisms/product-filter";
import SecondaryPageTemplate from "../secondary-page";
import { getProductsUrl } from "components/particles/APIs/API";
import http from "components/particles/http";
import Styles from "./styles";

function ProductPageTemplate({
  filter,
  onFilter,
  onResetFilter,
  pageName,
  breadcrumbsRoutes,
  header,
  children,
  withoutAdsBanner,
}) {
  const [state, setState] = React.useState({
    minPrice: 0,
    maxPrice: 0,
  });

  React.useEffect(() => {
    // calling api from backend
    http
      .get(`${getProductsUrl}/price-range`)
      .then((response) => {
        let { max_price, min_price } = response.data.data;
        setState((prevState) => ({ ...prevState, minPrice: min_price, maxPrice: max_price }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [state.currentStore]);

  return (
    <SecondaryPageTemplate withoutBanner={withoutAdsBanner}>
      <Styles>
        <div className="breadcrumbs">
          <Box sx={{ display: { xs: "block", md: "none" } }}>
            <AppDrawer>
              <ProductFilter
                onFilter={onFilter}
                onResetFilter={onResetFilter}
                withoutBorder
                minPrice={state.minPrice}
                maxPrice={state.maxPrice}
              />
            </AppDrawer>
          </Box>

          <CustomBreadcrumbs routes={breadcrumbsRoutes} pageName={pageName} />
        </div>
        {header && <div className="header-wrapper">{header}</div>}
        <div className="wrapper">
          {filter && (
            <Box className="filter-section" sx={{ display: { xs: "none", md: "block" } }}>
              <ProductFilter
                onFilter={onFilter}
                onResetFilter={onResetFilter}
                minPrice={state.minPrice}
                maxPrice={state.maxPrice}
              />
            </Box>
          )}
          <div className="right-side">{children}</div>
        </div>
      </Styles>
    </SecondaryPageTemplate>
  );
}

export default ProductPageTemplate;
