import React from "react";

import { I18nextProvider } from "react-i18next";

import i18n from "components/particles/i18n";

import StylesProvider from "./styles-provider";

import AppCurrencyContextProvider from "./currency-context";

import AppContextProvider from "./app-context";

import AuthContextProvider from "./auth-context";
import ShoppingCartContextProvider from "./cart-context";
import CategoriesContextProvider from "./categories-context";
import WishListContextProvider from "./wishlist-context";

const Providers = ({ children }) => {
  return (
    <I18nextProvider i18n={i18n}>
      <AppContextProvider>
        <AuthContextProvider>
          <ShoppingCartContextProvider>
            <WishListContextProvider>
              <AppCurrencyContextProvider>
                <CategoriesContextProvider>
                  <StylesProvider>{children}</StylesProvider>
                </CategoriesContextProvider>
              </AppCurrencyContextProvider>
            </WishListContextProvider>
          </ShoppingCartContextProvider>
        </AuthContextProvider>
      </AppContextProvider>
    </I18nextProvider>
  );
};

export default Providers;
