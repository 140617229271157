import React from "react";

import Styles from "./styles";

import { getHome } from "constants/navigation";

import { Link } from "react-router-dom";

function PawLogo({ src, alt }) {
  return (
    <Styles>
      <Link to={getHome().path}>
        <img src={src} alt={alt} className="app-logo" />
      </Link>
    </Styles>
  );
}

export default PawLogo;
