import React, { useEffect, useState } from "react";

import Styles from "./styles";

import CustomTextField from "components/atoms/text-field";

import { HiOutlineUserCircle, HiOutlineLockClosed } from "react-icons/hi";

import CustomButton from "components/atoms/button";

import { useTranslation } from "react-i18next";

import { Link } from "react-router-dom";

import { useTheme } from "@emotion/react";

import { getHome, getLogin, getCustomerSignUp, getStoreSignUp } from "constants/navigation";

import * as ENDPIONTS from "components/particles/APIs/API";

import http from "components/particles/http";

import AuthTemplate from "components/templates/auth";

import { Box } from "@mui/system";

import SeparatorLine from "components/atoms/separator";

import CustomSelect from "components/atoms/select";

import { CircularProgress, Grid } from "@mui/material";
import * as Yup from "yup";
import toastSuccess from "components/particles/toast/success";
import toastError from "components/particles/toast/error";
import { toast } from "react-toastify";

const initialState = {
  account: {
    name: "",
    email: "",
    password: "",
    region: 0,
    city_town_id: 0,
    address: "",
    delivery_email: "",
    delivery_fees: 0,
  },
  regionsList: [],
  citiesList: [],
  isLoading: false,
};

const initialErrors = {
  name: "",
  email: "",
  password: "",
  address: "",
  region: "",
  city: "",
  delivery_email: "",
  delivery_fees: "",
};
const StoreRegisterPage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [errors, setErrors] = useState(initialErrors);
  const [state, setState] = useState(initialState);

  const RegisterValidationSchema = Yup.object().shape({
    delivery_fees: Yup.number()
      .typeError(t("is_required", { field: t("delivery_fees") }))
      .min(0, t("is_invalid", { field: t("delivery_fees") })),
    delivery_email: Yup.string()
      .email(t("is_invalid", { field: t("delivery_email") }))
      .required(t("is_required", { field: t("delivery_email") })),
    address: Yup.string().required(t("is_required", { field: t("address") })),
    name: Yup.string().required(t("is_required", { field: t("name") })),
    password: Yup.string()
      .required(t("is_required", { field: t("password") }))
      .min(8, t("password_invalid", { minChar: 8 })),
    email: Yup.string()
      .email(t("is_invalid", { field: t("email") }))
      .required(t("is_required", { field: t("email") })),
  });

  // get regions on component mount
  useEffect(() => {
    http
      .get(ENDPIONTS.getAllRegions)
      .then((response) => {
        let { list } = response.data.data;
        let regions = list.map((region) => ({ value: region.id, name: region.name }));
        setState((prevState) => ({
          ...prevState,
          regionsList: regions,
          account: { ...prevState.account, region: regions[0].value },
        }));
      })
      .catch((error) => {
        console.log("an error happens while regions load", error);
        setErrors((prevError) => ({
          ...prevError,
          region: t("an_error_happens"),
        }));
      });
    return function cleanup() {
      toast.dismiss();
    };
  }, [t]);

  // get cities by region, base on region id change
  useEffect(() => {
    if (state.account.region) {
      http
        .get(ENDPIONTS.getCitiesByRegion(state.account.region))
        .then((response) => {
          let { list } = response.data.data;
          let cities = list ? list.map((city) => ({ value: city.id, name: city.name })) : [];
          setState((prevState) => ({
            ...prevState,
            citiesList: cities,
            account: { ...prevState.account, city_town_id: cities[0].value },
          }));
        })
        .catch((error) => {
          console.log("an error happens while cities load", error);
          setErrors((prevError) => ({
            ...prevError,
            city: t("an_error_happens"),
          }));
        });
    }
  }, [t, state.account.region]);

  const onChangeInput = ({ target: input }) => {
    if (input.name === "city_town_id" || input.name === "region")
      setState({
        ...state,
        account: { ...state.account, [input.name]: parseInt(input.value) },
      });
    else setState({ ...state, account: { ...state.account, [input.name]: input.value } });

    setErrors({ ...errors, [input.name]: "" });
  };

  const setLoading = (isLoading) => {
    setState({ ...state, isLoading: isLoading });
  };

  const storeRegisterHandler = (event) => {
    event.preventDefault();
    let {
      name,
      email,
      password,
      address,
      delivery_email,
      delivery_fees,
      city_town_id,
      region,
    } = state.account;

    // validate inputs
    RegisterValidationSchema.validate({
      name,
      email,
      password,
      address,
      delivery_email,
      delivery_fees,
      city_town_id,
      region,
    })
      .then((storeUserInfo) => {
        // check if region list or city list has an error while loading
        if (!!errors.region || !!errors.city) return;
        console.log(storeUserInfo);
        // calling api to create a store
        setLoading(true);
        http
          .post(ENDPIONTS.getStoresUrl, storeUserInfo)
          .then((response) => {
            setLoading(false);
            console.log(response);
            setState({ ...state, account: initialState.account });
            toastSuccess(t("done_check_email"));
          })
          .catch((error) => {
            setLoading(false);
            if (error.response.data.errors) {
              error.response.data.errors.forEach((err) => {
                toastError(err.message);
              });
            } else {
              toastError(error.response.data.message);
            }
          });
      })
      .catch((err) => {
        console.log(err.path, err);
        setErrors({ ...errors, [err.path]: err.errors[0] });
      });
  };

  return (
    <Styles theme={theme}>
      <AuthTemplate
        headerTitle={t("create_store_account")}
        pageName={t(getStoreSignUp().name)}
        routeList={[getHome()]}
        subHeader={
          <div className="create-account">
            {t("dont_have_store")}
            <Link to={getCustomerSignUp().path}>{t("create_customer_account_here")}</Link>
          </div>
        }
      >
        <form onSubmit={storeRegisterHandler}>
          <div className="sub-title">{t("account_details")}</div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                fullWidth
                name="email"
                label={t("email")}
                placeholder={t("email_hint")}
                value={state.account.email}
                onChange={onChangeInput}
                error={!!errors.email}
                helperText={errors.email}
                startAdornment={
                  <HiOutlineUserCircle fontSize={24} color={theme.palette.secondary.main} />
                }
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                fullWidth
                type="password"
                name="password"
                label={t("password")}
                placeholder={t("enter_password")}
                value={state.account.password}
                onChange={onChangeInput}
                error={!!errors.password}
                helperText={errors.password}
                startAdornment={
                  <HiOutlineLockClosed fontSize={24} color={theme.palette.secondary.main} />
                }
              />
            </Grid>
          </Grid>

          <SeparatorLine style={{ marginBottom: 16 }} />

          <div className="sub-title">{t("store_info")}</div>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                fullWidth
                name="name"
                label={t("store_name")}
                placeholder={t("enter_store_name")}
                value={state.account.name}
                onChange={onChangeInput}
                error={!!errors.name}
                helperText={errors.name}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomSelect
                name="region"
                label={t("region")}
                value={state.account.region}
                onChange={onChangeInput}
                optionList={state.regionsList}
                error={!!errors.region}
                helperText={errors.region}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <CustomSelect
                name="city_town_id"
                label={t("city_town")}
                value={state.account.city_town_id}
                onChange={onChangeInput}
                optionList={state.citiesList}
                error={!!errors.city}
                helperText={errors.city}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                fullWidth
                name="address"
                label={t("address")}
                placeholder={t("store_address")}
                value={state.account.address}
                onChange={onChangeInput}
                error={!!errors.address}
                helperText={errors.address}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                fullWidth
                name="delivery_email"
                label={t("delivery_email")}
                placeholder={t("delivery_email_hint")}
                value={state.account.delivery_email}
                onChange={onChangeInput}
                error={!!errors.delivery_email}
                helperText={errors.delivery_email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CustomTextField
                fullWidth
                type="number"
                name="delivery_fees"
                label={t("delivery_fees")}
                placeholder={t("delivery_fees_hint")}
                value={state.account.delivery_fees}
                onChange={onChangeInput}
                error={!!errors.delivery_fees}
                helperText={errors.delivery_fees}
                inputProps={{ min: 0 }}
              />
            </Grid>
          </Grid>

          <CustomButton
            fullWidth
            disableElevation
            style={{ marginTop: 20 }}
            type="submit"
            disabled={state.isLoading}
          >
            {state.isLoading && <CircularProgress size="16px" sx={{ margin: "0px 8px" }} />}
            {t("register")}
          </CustomButton>
          <Box mt={2} className="create-account">
            {t("have_an_account")}
            <Link to={getLogin().path}>{t("login_here")}</Link>
          </Box>
        </form>
      </AuthTemplate>
    </Styles>
  );
};

export default StoreRegisterPage;
