import React from "react";
import { StyledStack } from "./styles";
import StoreBalanceRow from "components/molecules/store-balance-row";
import { Box } from "@mui/material";

function StoreBalanceStack({ balanceList }) {
  if (balanceList.length === 0)
    return (
      <Box mb={6}>
        <StoreBalanceRow key={0} year={new Date().getFullYear()} balance={0} />
      </Box>
    );
  // else
  return (
    <StyledStack direction="column">
      {balanceList.map((item, index) => {
        return <StoreBalanceRow key={index} year={item.year} balance={item.balance} />;
      })}
    </StyledStack>
  );
}

export default StoreBalanceStack;
