import Swal from "sweetalert2";

const sweetAlert = (
  title,
  message,
  icon,
  confirmButtonText,
  confirmButtonColor,
  cancelButtonText,
  cancelButtonColor,
  denyButtonText,
  denyButtonColor
) => {
  return Swal.fire({
    title,
    text: message,
    icon,
    confirmButtonText,
    cancelButtonText,
    denyButtonText,
    showConfirmButton: !!confirmButtonText,
    showCancelButton: !!cancelButtonText,
    showDenyButton: !!denyButtonText,

    confirmButtonColor,
    cancelButtonColor,
    denyButtonColor,
  });
};

export default sweetAlert;
