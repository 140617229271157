import React from "react";

import Breadcrumbs from "@mui/material/Breadcrumbs";

import Typography from "@mui/material/Typography";

import { Link } from "react-router-dom";

import { StyledStack } from "./styles";

import { useTranslation } from "react-i18next";

// routes any array of object from navigation list, page name is the translated page name that we are in it
const CustomBreadcrumbs = ({ routes = [], pageName }) => {
  const { t } = useTranslation();
  const breadcrumbs = routes.map((route, index) => {
    return (
      <Link key={index} to={route.path}>
        {t(route.name)}
      </Link>
    );
  });
  breadcrumbs.push(
    <Typography key={routes.length} sx={{ fontSize: 14 }} color="primary">
      {pageName}
    </Typography>
  );

  return (
    <StyledStack spacing={2}>
      <Breadcrumbs separator="›" aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
    </StyledStack>
  );
};

export default CustomBreadcrumbs;
