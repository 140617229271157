import React from "react";

import Styles from "./styles";

import PropTypes from "prop-types";

function SocialMediaIcon({ to, children, color = "#5cbfc0" }) {
  return (
    <Styles color={color}>
      <a href={to} target="_blank" rel="noreferrer">
        {children}
      </a>
    </Styles>
  );
}

SocialMediaIcon.propTypes = {
  to: PropTypes.string.isRequired,
};

export default SocialMediaIcon;
