import React from "react";

import { Checkbox, FormGroup } from "@mui/material";

import { StyledFormControlLabel } from "./styles";

function CustomCheckBox({ label, ...props }) {
  return (
    <FormGroup>
      <StyledFormControlLabel control={<Checkbox {...props} />} label={label} />
    </FormGroup>
  );
}

export default CustomCheckBox;
