import React from "react";

import Styles from "./styles";

import { RiMastercardLine, RiVisaFill, RiPaypalLine } from "react-icons/ri";

function FooterPaymentIcon() {
  return (
    <Styles>
      <RiMastercardLine size={26} />
      <RiVisaFill size={26} />
      <RiPaypalLine size={26} />
    </Styles>
  );
}

export default FooterPaymentIcon;
