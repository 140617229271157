import React, { useEffect, useState } from "react";
import Footer from "components/organisms/footer";
import Navbar from "components/organisms/navbar";
import { getProductsUrl } from "components/particles/APIs/API";
import http from "components/particles/http";
import AppTemplate from "components/templates/app";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router";
import { Grid, Rating } from "@mui/material";
import { CurrencyContext } from "components/particles/providers/currency-context";
import { useTranslation } from "react-i18next";
import WishlistButton from "components/atoms/wishlist-button";
import ProductProps from "components/atoms/product-props";
import QuantityButtonGroup from "components/molecules/quantity-button-group";
import CustomButton from "components/atoms/button";
import ItemGallary from "components/organisms/gallary-item";
import { ShoppingCartContext } from "components/particles/providers/cart-context";
import * as ENDPIONTS from "components/particles/APIs/API";
import toastError from "components/particles/toast/error";
import Styles from "./styles";
import { WishListContext } from "components/particles/providers/wishlist-context";
import { AuthContext } from "components/particles/providers/auth-context";
import { getCustomerRegister, getLogin } from "constants/navigation";
import sweetAlert from "components/particles/sweet-alert";
import { useTheme } from "@emotion/react";

function ProductPage() {
  const [state, setState] = useState({
    isLoading: true,
    product: {},
    isCarted: false,
    cartedItem: null,
    quantity: 1,
    addToWishesLoading: false,
  });

  const { productId } = useParams();
  const { currency } = React.useContext(CurrencyContext);
  const { cartItems, dispatch: cartDispatch } = React.useContext(ShoppingCartContext);
  const { wishlistItems, dispatch: wishlistDispatch } = React.useContext(WishListContext);
  const { isAuthenticated } = React.useContext(AuthContext);
  const { t } = useTranslation();
  let history = useHistory();
  const theme = useTheme();

  useEffect(() => {
    const inCartItem = cartItems.find(
      (cartItem) => cartItem.product_id === parseInt(productId)
    );
    if (inCartItem)
      setState((prevstate) => ({
        ...prevstate,
        isCarted: true,
        cartedItem: inCartItem,
        quantity: inCartItem.quantity,
      }));
  }, [productId, cartItems]);

  useEffect(() => {
    http
      .get(`${getProductsUrl}/${productId}`)
      .then((response) => {
        let { data } = response.data;
        console.log(data);
        setState((prevstate) => ({ ...prevstate, isLoading: false, product: data }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [productId]);

  const isUserAuthenticated = () => {
    if (!isAuthenticated) {
      sweetAlert(
        t("login_first_title"),
        t("login_first_message"),
        "warning",
        t("login"),
        theme.palette.primary.main,
        null,
        null,
        t("register"),
        theme.palette.secondary.main
      )
        // isDismissed = register or isConfirmed = login
        .then((result) => {
          if (result.isConfirmed) history.push(getLogin().path);
          if (result.isDenied) {
            history.push(getCustomerRegister().path);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      return false;
    }
    return true;
  };

  const isAddedToWishlist = () => {
    if (!product) return null;
    return wishlistItems.findIndex((item) => item.product_id === product.id) === -1
      ? false
      : true;
  };

  const getWishItemByProductId = () => {
    let item = wishlistItems.find((item) => item.product_id === product.id);
    return item.id;
  };

  const setLoadingAddToWish = (status) => {
    setState({ ...state, addToWishesLoading: status });
  };

  // function to change price formate base on currency position
  const formatPriceWithCurrency = (price) => {
    switch (currency.position) {
      case "start":
        return currency.symbol + price;

      default:
        return price + currency.symbol;
    }
  };

  const addToWishlist = () => {
    if (!isUserAuthenticated()) {
      return;
    }
    setLoadingAddToWish(true);
    http
      .post(ENDPIONTS.getWishlistUrl, {
        product_id: product.id,
        store_id: product.store_id,
        quantity: 1,
      })
      .then((response) => {
        setLoadingAddToWish(false);
        let { data } = response.data;

        let wishlistItem = {
          ...data,
          product: product,
          price: product.final_price,
          total_price: product.final_price * data.quantity,
        };
        wishlistDispatch({
          type: "addToWishlist",
          payload: { wishlistItem },
        });
      })
      .catch((error) => {
        setLoadingAddToWish(false);
        error.response.data.errors.forEach((err) => {
          toastError(err.message);
        });
      });
  };

  const removeFromWishlist = () => {
    let itemId = getWishItemByProductId();
    http
      .delete(ENDPIONTS.getWishlistUrl + `/${itemId}`)
      .then(() => {
        wishlistDispatch({ type: "removeFromWishlist", payload: { itemId } });
      })
      .catch((error) => {
        toastError(
          error.response.data.message ? error.response.data.message : t("an_error_occure")
        );
      });
  };

  const onChangeQuantity = (newQuantity) => {
    const { cartedItem } = state;
    cartDispatch({
      type: "changeItemQuantity",
      payload: { itemId: cartedItem.id, quantity: newQuantity },
    });

    http
      .post(ENDPIONTS.getShoppingCartUrl + `/${cartedItem.id}`, {
        quantity: newQuantity,
      })
      .then((response) => {
        console.log(response);
        setState({
          ...state,
          quantity: newQuantity,
          cartedItem: { ...cartedItem, quantity: newQuantity },
        });
      })
      .catch((error) => {
        cartDispatch({
          type: "changeItemQuantity",
          payload: { itemId: cartedItem.id, quantity: cartedItem.quantity },
        });
        toastError(
          error.response.data.message ? error.response.data.message : t("an_error_occure")
        );
      });
  };

  const addToCartHandler = () => {
    if (!isUserAuthenticated()) {
      return;
    }
    http
      .post(ENDPIONTS.getShoppingCartUrl, {
        product_id: state.product.id,
        store_id: state.product.store_id,
        quantity: 1,
      })
      .then((response) => {
        let { data } = response.data;
        let cartItem = {
          ...data,
          product: state.product,
          price: state.product.final_price,
          total_price: state.product.final_price * data.quantity,
        };
        setState({
          ...state,
          isCarted: true,
          cartedItem: cartItem,
          quantity: cartItem.quantity,
        });
        cartDispatch({
          type: "addToCart",
          payload: { cartItem },
        });
      })
      .catch((error) => {
        error.response.data.errors.forEach((err) => {
          toastError(err.message);
        });
      });
  };

  const removeFromCartHandler = () => {
    http
      .delete(ENDPIONTS.getShoppingCartUrl + `/${state.cartedItem.id}`)
      .then(() => {
        setState({ ...state, cartedItem: {}, isCarted: false, quantity: 1 });
        cartDispatch({ type: "removeFromCart", payload: { itemId: state.cartedItem.id } });
      })
      .catch((error) => {
        toastError(
          error.response.data.message ? error.response.data.message : t("an_error_occure")
        );
      });
  };

  const ProductInfo = () => {
    let { product, isCarted, quantity } = state;
    let isAddedToWishes = isAddedToWishlist();
    return (
      <>
        <div className="rating-area">
          <div className="container">
            <div className="product-rating">
              <span>{`${product.rating}`}</span>
              <Rating name="read-only" value={product.rating} readOnly />
            </div>

            <div className="product-sold-quantity">
              {`${t("reviews", { count: product.number_of_reviews })} | ${t("sold")} ${
                product.solded_quantity
              }`}
            </div>
          </div>

          <div className="wishlist">
            <WishlistButton
              addedToWishlist={isAddedToWishes}
              onClick={isAddedToWishes ? removeFromWishlist : addToWishlist}
            >
              {isAddedToWishes ? t("remove_from_wishlist") : t("add_to_wishlist")}
            </WishlistButton>
          </div>
        </div>

        <h1 className="product-name">{product.name}</h1>
        <div className="product-price">{formatPriceWithCurrency(product.final_price)}</div>
        <div className="product-properties">
          {product.has_free_delivery && <ProductProps freeShipping />}
          {product.quantity && <ProductProps inStock />}
        </div>
        <div className="description-container">
          <p className="desc-word">{t("description")}</p>
          <p className="product-description">{product.description}</p>
        </div>
        <div className="add-cart-container">
          <div className="quantity">
            {isCarted && (
              <>
                <span>{t("quantity")}</span>
                <QuantityButtonGroup
                  quantity={quantity}
                  onChange={onChangeQuantity}
                  maxQuantity={product.quantity}
                />
              </>
            )}
          </div>
          <CustomButton
            color="secondary"
            className="cart-btn"
            onClick={isCarted ? removeFromCartHandler : addToCartHandler}
          >
            {isCarted ? t("remove_from_cart") : t("add_to_cart")}
          </CustomButton>
        </div>
      </>
    );
  };

  let { product, isLoading } = state;
  return (
    <AppTemplate header={<Navbar />} footer={<Footer />}>
      <Styles>
        {!isLoading && (
          <Grid container className="product-container">
            <Grid item xs={12} md={6} mt={6}>
              <ItemGallary imageList={product.pictures} />
            </Grid>
            <Grid item xs={12} md={6} mt={4} pl={4}>
              <ProductInfo />
            </Grid>
          </Grid>
        )}
      </Styles>
    </AppTemplate>
  );
}

export default ProductPage;
