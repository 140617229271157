import React from "react";

import { Link } from "react-router-dom";

import Styles from "./styles";

import { useTranslation } from "react-i18next";

function FooterNav({ navigationList }) {
  const { t } = useTranslation();
  return (
    <Styles>
      {navigationList.map((nav, index) => {
        return (
          <Link key={index} to={nav.path}>
            {t(nav.name)}
          </Link>
        );
      })}
    </Styles>
  );
}

export default FooterNav;
