import { Stack } from "@mui/material";
import { styled } from "@mui/system";

const StyledStack = styled(Stack)({
  overflowX: "auto",
  "&::-webkit-scrollbar": {
    display: "none",
  },
});

export default StyledStack;
