import { useTheme } from "@emotion/react";
import React from "react";

import Styles from "./styles";

function CustomInputFileUploder({ accept, multiple, maxWidth, label, onChange }) {
  const theme = useTheme();
  console.log(theme);
  return (
    <Styles theme={theme} maxWidth={maxWidth}>
      <div className="label-uploder">
        <label>{label}</label>
      </div>

      <div className="input-uploder">
        <input type="file" multiple={multiple} accept={accept} onChange={onChange} />
      </div>
    </Styles>
  );
}

export default CustomInputFileUploder;
