import i18n from "components/particles/i18n";

const sortOption = [
  { value: "default", name: i18n.t("sort_by") },
  { value: "newest", name: i18n.t("newest") },
  { value: "price", name: i18n.t("price") },
  { value: "rating", name: i18n.t("rating") },
  { value: "discount", name: i18n.t("discount") },
  { value: "number_of_reviews", name: i18n.t("review") },
  { value: "best_selling", name: i18n.t("best_sell") },
];

export default sortOption;
