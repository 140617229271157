import React from "react";
import Styles from "./styles";
import SearchIcon from "@mui/icons-material/Search";
import CloseIcon from "@mui/icons-material/Close";
import CustomTextField from "components/atoms/text-field";
import { useTranslation } from "react-i18next";
import { IconButton } from "@mui/material";

function ProductSearchBox({ value = "", onClickSearch }) {
  const [state, setState] = React.useState({
    keyword: "",
    sent: false,
  });
  const { t } = useTranslation();

  React.useEffect(() => {
    setState((prevState) => ({ ...prevState, keyword: value }));
  }, [value]);

  const handleChangeInput = (event) => {
    let { target: input } = event;
    // click enter
    if (event.keyCode === 13) {
      if (input.value) onClickSearch(state.keyword);
      setState((prevState) => ({ ...prevState, sent: input.value ? true : false }));
    }
    // backspace till value become "" that mean remove search
    if (state.sent && !input.value) {
      onClickSearch("");
      setState((prevState) => ({ ...prevState, sent: false }));
    }
    // by default just change keyword
    setState((prevState) => ({ ...prevState, keyword: input.value }));
  };

  const cancelSearch = () => {
    if (state.sent) onClickSearch("");
    setState({ ...state, keyword: "", sent: false });
  };

  return (
    <Styles>
      <CustomTextField
        fullWidth
        color="primary"
        className="product-search-box"
        placeholder={t("search")}
        value={state.keyword}
        onChange={handleChangeInput}
        onKeyDown={handleChangeInput}
        startAdornment={<SearchIcon color="disabled" />}
        endAdornment={
          state.keyword ? (
            <IconButton size="small" onClick={cancelSearch}>
              <CloseIcon />
            </IconButton>
          ) : null
        }
      />
    </Styles>
  );
}

export default ProductSearchBox;
