import React from "react";
import { useTranslation } from "react-i18next";
import { FaShippingFast } from "react-icons/fa";
import { HiOutlineCube } from "react-icons/hi";
import Styles from "./styles";
function ProductProps({ freeShipping, inStock }) {
  const { t } = useTranslation();
  const renderIcon = () => {
    if (freeShipping)
      return (
        <>
          <FaShippingFast fontSize={28} color="#F46B5B" />
          <span>{t("free_delivery")}</span>
        </>
      );
    if (inStock)
      return (
        <>
          <HiOutlineCube fontSize={28} color="#F46B5B" />
          <span>{t("in_stock")}</span>
        </>
      );
  };

  return <Styles>{renderIcon()}</Styles>;
}

export default ProductProps;
