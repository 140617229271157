import React from "react";
import { Grid, Pagination } from "@mui/material";
import { getAllProducts } from "components/particles/APIs/API";
import http from "components/particles/http";
import { getHome, getProducts } from "constants/navigation";
import { AppContext } from "components/particles/providers/app-context";
import ProductCard from "components/molecules/product-card";
import { useTranslation } from "react-i18next";
import SearchOffIcon from "@mui/icons-material/SearchOff";
import ProductSearchBox from "components/molecules/product-search-box";
import CustomSelect from "components/atoms/select";
import sortOption from "constants/sort-option";
import ProductPageTemplate from "components/templates/products";
import Styles from "./styles";
import paggination from "constants/paggination";

import { useLocation } from "react-router-dom";

function AllProductPage() {
  const [state, setState] = React.useState({
    products: [],
    filter: null,
    keyword: "",
    sortBy: "default",
    currentPage: 1,
    pageCount: 1,
  });
  const { dispatch } = React.useContext(AppContext);
  const { t } = useTranslation();
  const search = useLocation().search;

  React.useEffect(() => {
    // if there is sortBy or Search - check if state goes with this params
    const URLParams = new URLSearchParams(search);
    const sortBy = URLParams.get("sort") || "default";
    const searchKeyword = URLParams.get("search") || "";
    setState((prevState) => ({ ...prevState, sortBy, keyword: searchKeyword }));
    // check if state updated successfully
    if (sortBy !== state.sortBy || searchKeyword !== state.keyword) return;
    // start initialization
    let params = null;

    if (state.keyword)
      params = {
        search: state.keyword,
      };

    if (state.filter)
      params = {
        ...params,
        categories: `[${state.filter.selectedCategoryIds.toString()}]`,
        min_price: state.filter.minPrice,
        max_price: state.filter.maxPrice,
      };

    if (state.sortBy !== "default")
      params = {
        ...params,
        sort_by: state.sortBy,
      };
    params = {
      ...params,
      count: paggination.pageSize,
      page: state.currentPage,
    };
    // calling api from backend
    http
      .get(getAllProducts, { params })
      .then((response) => {
        let { data } = response.data;
        setState((prevState) => ({
          ...prevState,
          products: data.list || [],
          currentPage: data.current_page,
          pageCount: data.current_page + data.next_pages_count,
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [search, state.keyword, state.filter, state.sortBy, state.currentPage]);

  const closeDrawer = () => {
    dispatch({
      type: "setOpenDrawer",
      payload: {
        open: false,
      },
    });
  };

  const onFilter = (filterObject) => {
    closeDrawer();
    setState((prevState) => ({ ...prevState, filter: filterObject }));
  };

  const resetFilter = () => {
    closeDrawer();
    http
      .get(getAllProducts)
      .then((response) => {
        let { list } = response.data.data;
        setState((prevState) => ({ ...prevState, filter: null, products: list || [] }));
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleSearch = (searchKeyword) => {
    setState({ ...state, keyword: searchKeyword });
  };

  const handleSortProduct = ({ target: input }) => {
    setState({ ...state, sortBy: input.value });
  };

  const handlePageChange = (event, page) => {
    setState({ ...state, currentPage: page });
  };

  const ProductCardGrid = () => {
    return (
      <>
        {state.products.map((product, index) => {
          return (
            <Grid key={index} item>
              <ProductCard productObject={product} sx={{ margin: 8 }} />
            </Grid>
          );
        })}
      </>
    );
  };

  const NoItemFound = () => {
    return (
      <div className="no-item-container">
        <SearchOffIcon className="no-item-icon" />
        <p className="no-item-message">{t("no_item_found")}</p>
      </div>
    );
  };

  return (
    <ProductPageTemplate
      filter
      onFilter={onFilter}
      onResetFilter={resetFilter}
      pageName={t(getProducts().name)}
      breadcrumbsRoutes={[getHome()]}
    >
      <Styles>
        <div className="search-sort-container">
          <ProductSearchBox value={state.keyword} onClickSearch={handleSearch} />
          <CustomSelect
            color="primary"
            className="sort-select"
            value={state.sortBy}
            optionList={sortOption}
            onChange={handleSortProduct}
            firstChildHidden
          />
        </div>
        <Grid container justifyContent="center">
          {state.products.length > 0 ? <ProductCardGrid /> : <NoItemFound />}
        </Grid>
        {state.pageCount !== 1 && (
          <div className="paggination-container">
            <Pagination
              color="primary"
              count={state.pageCount}
              variant="outlined"
              shape="rounded"
              page={state.currentPage}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Styles>
    </ProductPageTemplate>
  );
}

export default AllProductPage;
