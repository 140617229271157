export const BASE_URL = "https://api.pawpaw.sl";

export const STORAGE_BASE_URL = "https://storage.googleapis.com/pawpaw-store-public/uploads";

export const IMAGES_BASE_URL = STORAGE_BASE_URL + "/images";

export const ICONS_BASE_URL = STORAGE_BASE_URL + "/icons";

export const AUTH_BASE_URL = BASE_URL + "/auth";

export const getAllRegions = BASE_URL + "/regions";

export const getBannersByref = (ref) => BASE_URL + `/banners/${ref}`;

export const getBannerImageByName = (imageName) => IMAGES_BASE_URL + `/banners/${imageName}`;

export const getCitiesByRegion = (regionId) => BASE_URL + `/regions/${regionId}/cities-towns`;

export const getAllCategories = BASE_URL + `/categories`;

export const getCategoryIconByName = (iconName) => ICONS_BASE_URL + `/categories/${iconName}`;

export const getAllProducts = BASE_URL + "/products";

export const getAllStores = BASE_URL + `/stores`;

export const getStoreProfilePictureByName = (pictureName, size) =>
  IMAGES_BASE_URL + `/stores/${size}/${pictureName}`;

export const getStoreCoverByName = (coverPicture, size = "normal") =>
  IMAGES_BASE_URL + `/stores/covers/${size}/${coverPicture}`;

export const getLoginUrl = AUTH_BASE_URL + "/user";

export const getCustomersUrl = BASE_URL + "/customers";

export const getUsersUrl = BASE_URL + "/users";

export const getStoresUrl = BASE_URL + "/stores";

export const getShoppingCartUrl = BASE_URL + "/shopping-cart-items";

export const getCartCountItemsUrl = getShoppingCartUrl + "/count";

export const getWishlistUrl = BASE_URL + "/wishlist-items";

export const getProductsUrl = BASE_URL + "/products";

export const getBrandsUrl = BASE_URL + "/brands";

export const getUserNotificationById = (userId) => BASE_URL + `/users/${userId}/notifications`;

export const getProductImageByName = (pictureName, size) =>
  IMAGES_BASE_URL + `/products/${size}/${pictureName}`;
