import React from "react";
import { useTheme } from "@emotion/react";
import { CurrencyContext } from "components/particles/providers/currency-context";
import Styles from "./styles";

function StoreBalanceRow({ year, balance }) {
  let theme = useTheme();
  const { currency } = React.useContext(CurrencyContext);

  return (
    <Styles theme={theme}>
      <div className="year-container">{year}</div>
      <div className="total-price-container">
        <span className="total-price">{balance}</span>
        <span className="currency-code">{currency.code.toUpperCase()}</span>
      </div>
    </Styles>
  );
}

export default StoreBalanceRow;
