import React from "react";

import { Badge } from "@mui/material";

import NavIconLink from "./styles";

function IconNavButton({ children, to, itemCount = 0, ...props }) {
  return (
    <NavIconLink exact to={to} {...props}>
      <Badge badgeContent={itemCount} color="primary" style={{ fontSize: 12 }}>
        {children}
      </Badge>
    </NavIconLink>
  );
}

export default IconNavButton;
