import React from "react";
import { useTheme } from "@emotion/react";
import i18n from "components/particles/i18n";

import { StyledCarousel } from "./styles";

function MuiCarousel({ children, interval = 4000 }) {
  const theme = useTheme();
  return (
    <StyledCarousel
      indicators={children.length > 1}
      interval={interval}
      navButtonsAlwaysInvisible={true}
      activeIndicatorIconButtonProps={{
        style: {
          color: theme.palette.primary.main,
        },
      }}
      indicatorContainerProps={{
        style: {
          width: "90%",
          position: "absolute",
          marginTop: "-50px",
          padding: "0px 64px",
          textAlign: i18n.language === "ar" ? "right" : "left",
        },
      }}
    >
      {children}
    </StyledCarousel>
  );
}

export default MuiCarousel;
