import styled from "styled-components";

const Styles = styled.div`
  background: white;
  padding-inline-start: 16px;
  width: 90%;
  margin: auto;
  color: #383838;

  .mini-desc {
    width: 75%;
    margin-block-start: 1.8em;
    margin-block-end: 1.8em;
  }
  .main-container {
    margin-bottom: 32px;
  }
  .bottom-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    a {
      color: #383838;
      text-decoration: none;
      margin-bottom: 16px;
    }
  }
`;

export default Styles;
