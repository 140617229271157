import React, { useContext } from "react";
import { useTheme } from "@emotion/react";
import { Grid } from "@mui/material";
import CustomBreadcrumbs from "components/atoms/bread-crumbs";

import { AuthContext } from "components/particles/providers/auth-context";
import Navbar from "components/organisms/navbar";
import Footer from "components/organisms/footer";
import { getHome } from "constants/navigation";
import AppTemplate from "../app";
import Styles from "./styles";

import { useHistory } from "react-router";
import AppDrawer from "components/organisms/drawer-menu";
import ProfileMenuNavigation from "components/molecules/user-profile-nav";
import { Box } from "@mui/system";

function UserProfileTemplate({ routeList, pageName, children }) {
  const { user, dispatch } = useContext(AuthContext);
  const theme = useTheme();
  let history = useHistory();

  const logoutHandler = (event) => {
    event.preventDefault();
    dispatch({
      type: "logout",
    });
    history.replace(getHome().path);
  };

  return (
    <AppTemplate header={<Navbar />} footer={<Footer />}>
      <Styles theme={theme}>
        <div className="breadcrumbs">
          <Box sx={{ display: { xs: "block", sm: "none" } }}>
            <AppDrawer>
              <ProfileMenuNavigation user={user} logoutHanlder={logoutHandler} />
            </AppDrawer>
          </Box>
          <CustomBreadcrumbs routes={routeList} pageName={pageName} />
        </div>

        <Grid container className="profile-template">
          <Grid
            item
            sx={{
              display: { xs: "none", sm: "block" },
              borderRight: "1px solid #c7c7c7",
            }}
            sm={5}
            md={3}
            xl={2}
          >
            <ProfileMenuNavigation user={user} logoutHanlder={logoutHandler} />
          </Grid>
          <Grid item xs sm={7} md={9} xl={10} sx={{ paddingLeft: { sm: 3, md: 5 } }}>
            {children}
          </Grid>
        </Grid>
      </Styles>
    </AppTemplate>
  );
}

export default UserProfileTemplate;
