import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #d7d7d7;
  border-radius: 24px;
  padding: 16px 8px;
  color: #383838;
  height: 180px;
  margin: auto;

  .image-container {
    display: flex;
    min-width: 200px;
    justify-content: center;
    position: relative;
    top: 0;
    left: 0;
  }
  .product-img {
    padding-inline-end: 16px;
    padding-inline-start: 16px;
  }

  .circle {
    position: absolute;
    left: 0px;
    z-index: 99;
    margin-inline-start: 32px;
    width: 48px;
    height: 48px;
    line-height: 48px;
    border-radius: 50%;
    color: white;
    text-align: center;
    background: ${(props) => props.theme.palette.secondary.main};
  }

  img {
    max-height: 170px;
    max-width: 170px;
    position: relative;
    top: 0;
    left: 0;
  }

  .product-info {
    padding: 8px;
    min-width: 180px;
  }
  .product-name {
    font-size: 16px;
    margin-block-end: 0em;
  }
  .product-link {
    font-size: 16px;
    text-decoration: none;
    color: #383838;
    &:hover {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }
  .product-price {
    font-weight: bold;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }
  .product-rate-sold {
    display: flex;
    align-items: center;
    color: #a7a7a7;
  }
`;

export default Styles;
