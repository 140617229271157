import { IconButton } from "@mui/material";
import React from "react";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

function WishlistButton({ addedToWishlist, children, onClick }) {
  return (
    <>
      <IconButton onClick={onClick} color={addedToWishlist ? "error" : "default"}>
        {addedToWishlist ? <FavoriteIcon /> : <FavoriteBorderIcon />}
      </IconButton>
      {children && (
        <div
          id="wishlistButton_label"
          onClick={onClick}
          onKeyDown={onClick}
          role="button"
          tabIndex={0}
          style={{ cursor: "pointer" }}
        >
          {children}
        </div>
      )}
    </>
  );
}

export default WishlistButton;
