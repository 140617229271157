import { styled } from "@mui/system";
import { NavLink } from "react-router-dom";

const NavIconLink = styled(NavLink)(({ theme }) => ({
  color: "#383838",
  padding: "0px 8px",
  "&.active": {
    color: theme.palette.primary.main,
    "& span.MuiBadge-badge": {
      display: "none !important",
    },
  },
}));

export default NavIconLink;
