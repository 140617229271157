import React from "react";

import Styles from "./styles";

import AppTemplate from "components/templates/app";

import Navbar from "components/organisms/navbar";

import Footer from "components/organisms/footer";

import HomeBanner from "components/organisms/home-staff/home-banner";
import HomeAdsBanner from "components/organisms/home-staff/home-ads-banner";

import HomeCategories from "components/organisms/home-staff/home-categories";
import HomeNewArrival from "components/organisms/home-staff/home-new-arrival";
import HomeStoreAvatar from "components/organisms/home-staff/home-store-avatar";
import HomeTopRatedProduct from "components/organisms/home-staff/home-top-rated";

const HomePage = () => {
  // const [state, setState] = useState({});

  // useEffect(() => {}, []);

  return (
    <AppTemplate header={<Navbar />} footer={<Footer />}>
      <Styles>
        <main>
          <HomeBanner />
          <HomeCategories />
          <HomeNewArrival />
          <HomeStoreAvatar />
          <HomeAdsBanner />
          <HomeTopRatedProduct />
        </main>
      </Styles>
    </AppTemplate>
  );
};

export default HomePage;
