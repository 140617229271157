import React, { useState } from "react";
import { StyledStack } from "./styles";
import CartItemCard from "components/molecules/cart-item";
import CustomCheckBox from "components/atoms/check-box";
import { useTranslation } from "react-i18next";
import CustomButton from "components/atoms/button";

function CartStackItem({
  cartItems,
  changeQuantityHandler,
  onRemoveItemFromCart,
  formatPriceFunc,
}) {
  const [state, setState] = useState({
    selectedItemsId: [],
    allItemSelected: false,
  });
  const { t } = useTranslation();

  const selectAll = (event, checked) => {
    if (checked) {
      let itemIds = [];
      cartItems.forEach((item) => {
        itemIds.push(item.id);
        setState({
          ...state,
          allItemSelected: true,
          selectedItemsId: itemIds,
        });
      });
    } else setState({ ...state, allItemSelected: false, selectedItemsId: [] });
  };

  const setSelected = (event, isNotSelected, itemId) => {
    let selectedIds = [...state.selectedItemsId];
    if (isNotSelected) {
      selectedIds.push(itemId);
      setState({
        ...state,
        selectedItemsId: selectedIds,
      });
      return;
    } else {
      selectedIds = selectedIds.filter((id) => id !== itemId);
      setState({ ...state, allItemSelected: false, selectedItemsId: selectedIds });
    }
  };

  const deleteSelectedHandler = () => {
    let selectedList = [...state.selectedItemsId];
    selectedList.forEach((item) => {
      onRemoveItemFromCart(item);
    });
    setState({ ...state, selectedItemsId: [], allItemSelected: false });
  };

  return (
    <StyledStack direction="column">
      <div className="action-header">
        <CustomCheckBox
          label={t("select_all")}
          checked={state.allItemSelected}
          color="secondary"
          onChange={selectAll}
        />
        <CustomButton
          size="small"
          color="error"
          disabled={state.selectedItemsId.length === 0}
          onClick={deleteSelectedHandler}
        >
          {t("delete_selected")}
        </CustomButton>
      </div>
      <div className="data-table">
        {cartItems.map((item, index) => {
          return (
            <CartItemCard
              key={index}
              productName={item.product.name}
              quantity={item.quantity}
              isSelected={state.selectedItemsId.includes(item.id)}
              onSelect={(e, value) => setSelected(e, value, item.id)}
              productPrice={formatPriceFunc(item.price)}
              maxQuantity={item.product.quantity}
              productImages={item.product.pictures}
              onChangeQuantity={(newQuantity) =>
                changeQuantityHandler(newQuantity, item.quantity, item.id)
              }
              onDelete={() => {
                onRemoveItemFromCart(item.id);
              }}
            />
          );
        })}
      </div>
    </StyledStack>
  );
}

export default CartStackItem;
