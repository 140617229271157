import React from "react";

import CustomBreadcrumbs from "components/atoms/bread-crumbs";

import { useTheme } from "@emotion/react";

import { Styles } from "./styles";
import Navbar from "components/organisms/navbar";
import Footer from "components/organisms/footer";
import AppTemplate from "../app";

function AuthTemplate({ headerTitle, routeList, subHeader, pageName, children }) {
  const theme = useTheme();

  return (
    <AppTemplate header={<Navbar />} footer={<Footer />}>
      <Styles theme={theme}>
        <div className="breadcrumbs">
          <CustomBreadcrumbs routes={routeList} pageName={pageName} />
        </div>

        <div className="form-template">
          <div className="form-header">
            <p>{headerTitle}</p>
            {subHeader}
          </div>

          {children}
        </div>
      </Styles>
    </AppTemplate>
  );
}

export default AuthTemplate;
