import { styled } from "@mui/material/styles";

import { InputLabel, OutlinedInput } from "@mui/material";

export const InputField = styled(OutlinedInput)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(2.5),
  },
  "& .MuiOutlinedInput-input": {
    padding: "12px 14px",
  },
  width: "100%",
}));

export const InputFieldLabel = styled(InputLabel)({
  fontSize: 19,
  color: "#383838",
});
