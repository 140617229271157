import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #d7d7d7;
  border-radius: 24px;
  padding: 16px 8px;
  color: #383838;
  max-width:240px;
  min-width: 240px;
  min-height:334px;
  max-height:334px;
  .image-container {
    display: flex;
    align-items: center;
    position: relative;
    top: 0;
    left: 0;
    height:100%;
    min-height: 150px;
  }


  img {
    max-width: 180px;
    max-height: 144px;
    position: relative;
    top: 0;
    left: 0;
  }

  .cart-button{
    margin-inline-end:8px;
  }
  .cart-button:hover{
    background-color:#f44336
  }

  }
  .circle {
    position: absolute;
    margin-inline-start: 16px;
    width: 48px;
    height: 48px;
    line-height: 48px;
    border-radius: 50%;
    color: white;
    text-align: center;
    background: ${(props) => props.theme.palette.secondary.main};
    z-index:99;
  }

  .product-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 8px;
   
  }
  .product-name {
    font-size: 20px;
    font-weight: 500;
    margin-block-end: 0em;
    text-align: center;
  }
  .product-link {
    font-size: 18px;

    text-decoration: none;
    color: #383838;
    font-weight: 500;
    text-align: center;
    &:hover {
      color: ${(props) => props.theme.palette.primary.main};
    }
  }
  .product-price {
    font-weight: bold;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
    font-weight: 600;
    font-size:16px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
  .product-rate-sold {
    display: flex;
    align-items: center;
    color: #a7a7a7;
  }
  .actions {
    display: flex;
    margin-block-start: 1em;
    button.cart-btn:hover {
       background:#f44336;
    }
  }
  .favorite-button {
    margin-inline-start: 0.3em;
  }
`;

export default Styles;
