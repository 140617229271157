import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  flex-direction: column;

  .profile-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-weight: 600;
      margin-block-end: 0.25em;
    }
    span {
      color: #a7a7a7;
    }

    max-width: 175px;
    margin: auto;
    padding-bottom: 16px;
    border-bottom: 1px solid #c7c7c7;
  }
  .nav-container {
    padding: 24px;
  }
`;

export default Styles;
