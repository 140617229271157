import styled from "styled-components";

const Styles = styled.div`
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .create-account {
    color: #a7a7a7;
  }
`;

export default Styles;
