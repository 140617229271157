import styled from "styled-components";

const Styles = styled.div`
  @media (max-width: 396px) {
    #notificationLink {
      display: none;
    }
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0px;
  width: 90%;
  margin: auto;
  & .MuiGrid-root {
    align-items: center;
  }
  & .icon-nav-container {
    display: flex;
    justify-content: flex-end;
  }
  & .fw-searchbox {
    padding: 12px 0px;
  }
`;

export default Styles;
