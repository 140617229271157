import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${(props) => props.background});
  max-width: 512px;
  height: ${(props) => props.height}px;
  border-radius: 32px;
  .banner-button {
    color: black;
    background-color: #fafafacc;
    &:hover {
      background-color: #fafafa;
      color: ${(props) => props.theme.palette.secondary.main};
    }
  }
`;

export default Styles;
