import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  border: ${(props) => (props.withoutBorder ? "none" : "1px solid #c7c7c7")};
  border-radius: 6px;
  padding: 16px;
  min-width: 208px;

  .clear-filter-btn {
    margin-top: 16px;
    :hover {
      color: #2a797a;
    }
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

export default Styles;
