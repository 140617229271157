import React, { useState } from "react";

import SearchIcon from "@mui/icons-material/Search";

import { SearchBar, SearchButton } from "./styles";

function SearchBox({ placeholder, onClickSearch }) {
  let [state, setState] = useState({
    keyword: "",
  });

  const changeHandler = (event) => {
    let { target: input } = event;

    if (event.keyCode === 13) {
      if (state.keyword) onClickSearch(state.keyword);
    }

    setState((prevState) => ({ ...prevState, [input.name]: input.value }));
  };

  return (
    <SearchBar
      size="small"
      placeholder={placeholder}
      name="keyword"
      value={state.keyword}
      onChange={changeHandler}
      onKeyDown={changeHandler}
      endAdornment={
        <SearchButton
          disableElevation
          variant="contained"
          onClick={() => onClickSearch(state.keyword)}
        >
          <SearchIcon />
        </SearchButton>
      }
    />
  );
}

export default SearchBox;
