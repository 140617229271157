import styled from "styled-components";

const Styles = styled.div`
  margin-inline-end: 16px;
  max-width: 400px;
  width: 100%;
  .product-search-box {
    margin-top: 0px;
    max-width: 360px;

    width: 100%;
    input {
      padding: 8px 14px;
    }
  }
`;

export default Styles;
