import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  .app-logo {
    width: 100%;
  }
`;

export default Styles;
