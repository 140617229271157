import { Stack } from "@mui/material";
import { styled } from "@mui/system";

export const StyledStack = styled(Stack)({
  "& .data-table": {
    overflowX: "auto",
  },

  margin: "32px 8px",
  "& .action-header": {
    display: "flex",
    justifyContent: "space-between",
    borderBottom: "1px solid #c7c7c7",
    alignItems: "center",
    padding: "16px",
    button: {
      height: 30,
    },
  },
});
