import styled from "styled-components";

const Styles = styled.div`
  margin-bottom: 8px;
  .label {
  }
  .children-container {
    color: ${(props) => props.theme.palette.primary.main};
    font-size: 20px;
    font-weight: 500;
  }
`;

export default Styles;
