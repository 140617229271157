import React, { useContext, useEffect } from "react";

import http from "components/particles/http";
import * as ENDPIONTS from "components/particles/APIs/API";
import { AuthContext } from "../auth-context";

// initial state
var initialState = {
  wishlistItemsCount: 0,
  wishlistItems: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setItemsCount":
      return {
        ...state,
        wishlistItemsCount: action.payload.count,
      };
    case "setWishlistItems":
      return {
        ...state,
        wishlistItems: action.payload.wishlistItems,
      };

    case "addToWishlist": {
      let itemIndex = state.wishlistItems.findIndex(
        (item) => item.id === action.payload.wishlistItem.id
      );
      let wishesList = [...state.wishlistItems];
      let ItemsCount = state.wishItemsList;

      // if item is not exists
      if (itemIndex === -1) {
        wishesList.push(action.payload.wishlistItem);
        ItemsCount = state.wishItemsList + 1;
      } else {
        wishesList = state.wishlistItems.map((item) => {
          if (item.id === action.payload.wishlistItem.id) {
            return { ...item, quantity: action.payload.wishlistItem.quantity };
          } else return item;
        });
      }

      return {
        ...state,
        wishlistItems: wishesList,
        wishlistItemsCount: ItemsCount,
      };
    }
    case "removeFromWishlist": {
      // payload as {itemId:1}
      let newWishesList = state.wishlistItems.filter(
        (item) => item.id !== action.payload.itemId
      );
      return {
        ...state,
        wishlistItems: newWishesList,
        wishlistItemsCount: state.wishlistItemsCount - 1,
      };
    }
    // case "removeItemsFromWishlist": {
    //   // payload as {itemsList:[1,2,3] } you can send one item in array also
    //   let totalPrice = 0;
    //   let newCartItems = state.cartItems.filter((item) => {
    //     if (!action.payload.itemsList.include(item.id)) {
    //       totalPrice += item.price * item.quantity;
    //       return item;
    //     }
    //   });

    //   return {
    //     ...state,
    //     cartItems: newCartItems,
    //     cartTotalPrice: totalPrice,
    //     cartItemsCount: state.cartItemsCount - action.payload.itemsList.length,
    //   };
    // }

    case "changeItemQuantity": {
      // payload as {itemId:1, quantity:5}
      var wishesList = state.wishlistItems.map((item) =>
        item.id === action.payload.itemId
          ? {
              ...item,
              quantity: action.payload.quantity,
              total_price: action.payload.quantity * item.price,
            }
          : item
      );
      return {
        ...state,
        wishlistItems: wishesList,
      };
    }

    default:
      return state;
  }
};

const WishListContextProvider = ({ children }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const [state, dispatch] = React.useReducer(reducer, initialState);

  useEffect(() => {
    if (isAuthenticated) {
      http
        .get(ENDPIONTS.getWishlistUrl)
        .then((response) => {
          let { list } = response.data.data;
          // adding price unit to the array
          if (list) {
            list = list.map((item) => {
              return {
                ...item,
                price: item.total_price / item.quantity,
              };
            });
          } else list = [];
          dispatch({ type: "setItemsCount", payload: { count: list.length } });
          dispatch({ type: "setWishlistItems", payload: { wishlistItems: list } });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isAuthenticated]);

  return (
    <WishListContext.Provider
      value={{
        wishlistItemCount: state.wishlistItemCount,
        wishlistItems: state.wishlistItems,
        dispatch,
      }}
    >
      {children}
    </WishListContext.Provider>
  );
};

export default WishListContextProvider;

export const WishListContext = React.createContext();
WishListContext.displayName = "WishListContext";
