import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border: 1px solid transparent;
  border-radius: 12px;
  max-width: 200px;
  margin: auto;
  &:hover {
    border: 1px solid ${(props) => props.theme.palette.primary.main};
    cursor: pointer;
    img {
      filter: invert(67%) sepia(87%) saturate(253%) hue-rotate(131deg) brightness(89%)
        contrast(79%);
    }
  }

  a {
    text-decoration: none;
  }
  img {
    margin-block-start: 1em;
    max-width: 64px;
    filter: invert(89%) sepia(0%) saturate(184%) hue-rotate(206deg) brightness(84%)
      contrast(91%);
  }
  p {
    color: #383838;
    font-weight: 500;
    text-decoration: none;
  }
`;

export default Styles;

// filter to change icon color to #5CBFC0,
// filter: invert(67%) sepia(87%) saturate(253%) hue-rotate(131deg) brightness(89%) contrast(79%);

// filter to change icon color to #B9B9B9,
// filter: invert(89%) sepia(0%) saturate(184%) hue-rotate(206deg) brightness(84%) contrast(91%);
