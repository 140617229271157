import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  align-items: center;
  button {
    color: white;
    min-width: 36px;
    width: 36px;
    height: 36px;
    border-radius: 8px;
  }
  .qantity {
    min-width: 20px;
    padding: 0 8px;
    text-align: center;
    font-size: 16px;
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

export default Styles;
