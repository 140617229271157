import React from "react";
import { Grid } from "@mui/material";
import { useTheme } from "@emotion/react";

import { useTranslation } from "react-i18next";
import { getCart, getHome } from "constants/navigation";
import * as ENDPIONTS from "components/particles/APIs/API";
import UserProfileTemplate from "components/templates/profile";
import { AuthContext } from "components/particles/providers/auth-context";
import { CurrencyContext } from "components/particles/providers/currency-context";
import { ShoppingCartContext } from "components/particles/providers/cart-context";

import CustomStepper from "components/molecules/custom-stepper";
import CartStackItem from "components/organisms/cart-stack-item";
import SummaryTemplate from "components/templates/summary";
import toastError from "components/particles/toast/error";
import sweetAlert from "components/particles/sweet-alert";
import http from "components/particles/http";
import CartCheckout from "./CartCheckout";
import Styles from "./styles";

const CartPage = () => {
  const [state, setState] = React.useState({
    activeStep: 0,
    cartItemList: [],
  });

  const { t } = useTranslation();
  const { currency } = React.useContext(CurrencyContext);
  const { user } = React.useContext(AuthContext);
  const { cartItems, cartTotalPrice, dispatch } = React.useContext(ShoppingCartContext);
  const theme = useTheme();

  const formatPriceWithCurrency = (price) => {
    switch (currency.position) {
      case "start":
        return currency.symbol + price;

      default:
        return price + currency.symbol;
    }
  };

  const changeQuantityHandler = (newValue, oldValue, itemId) => {
    dispatch({ type: "changeItemQuantity", payload: { itemId, quantity: newValue } });
    http
      .post(ENDPIONTS.getShoppingCartUrl + `/${itemId}`, { quantity: parseInt(newValue) })
      .catch((error) => {
        dispatch({ type: "changeItemQuantity", payload: { itemId, quantity: oldValue } });
        toastError(
          error.response.data.message ? error.response.data.message : t("an_error_occure")
        );
      });
  };

  const RemoveItemFromCart = (itemId) => {
    http
      .delete(ENDPIONTS.getShoppingCartUrl + `/${itemId}`)
      .then((response) => {
        console.log(response);
        dispatch({ type: "removeFromCart", payload: { itemId } });
      })
      .catch((error) => {
        toastError(
          error.response.data.message ? error.response.data.message : t("an_error_occure")
        );
      });
  };

  const handleSubmitOrder = (order) => {
    http
      .post(`${ENDPIONTS.getCustomersUrl}/${user.id}/orders/submit`, order)
      .then(() => {
        sweetAlert(
          t("order_submitted_title"),
          t("order_submitted_message"),
          "success",
          t("ok"),
          theme.palette.primary.main
        );
        dispatch({ type: "removeAllItemFromCart" });
      })
      .catch((error) => {
        console.log(error);
        if (error.response.data)
          error.response.data.errors.forEach((err) => {
            toastError(err.message);
          });
      });
  };

  const changeStepHandler = (stepIndex) => {
    setState({ ...state, activeStep: stepIndex });
  };

  const renderMyCart = () => {
    return (
      <Grid container sx={{ marginTop: 4 }}>
        <Grid item xs={12} md={7} lg={8} xl={6}>
          <CartStackItem
            cartItems={cartItems}
            changeQuantityHandler={changeQuantityHandler}
            formatPriceFunc={formatPriceWithCurrency}
            onRemoveItemFromCart={RemoveItemFromCart}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={5}
          lg={4}
          xl={6}
          sx={{ paddingTop: { xs: 2, md: 9 }, paddingLeft: { md: 4 } }}
        >
          <SummaryTemplate
            title={t("summary")}
            actionLabel={t("checkout")}
            onStartAction={() => {
              changeStepHandler(1);
            }}
            linkLabel={t("continue_shopping")}
            link={getHome().path}
            actionDisabled={cartItems.length === 0}
          >
            <div className="total-price-container">
              <div className="gray-total">{t("total")}</div>
              <div>{formatPriceWithCurrency(cartTotalPrice)}</div>
            </div>
          </SummaryTemplate>
        </Grid>
      </Grid>
    );
  };

  return (
    <UserProfileTemplate pageName={t(getCart().name)} routeList={[getHome()]}>
      <Styles theme={theme}>
        <h1>{t("shopping_cart")}</h1>
        {cartItems.length === 0 ? (
          <p className="no-items">{t("no_item_cart")}</p>
        ) : (
          <CustomStepper
            step={state.activeStep}
            disabled={cartItems.length === 0}
            onChangeStep={changeStepHandler}
          />
        )}
        {state.activeStep === 1 ? (
          <CartCheckout
            formatPrice={formatPriceWithCurrency}
            onSubmitOrder={handleSubmitOrder}
          />
        ) : (
          renderMyCart()
        )}
      </Styles>
    </UserProfileTemplate>
  );
};

export default CartPage;
