import React from "react";
import ContactInfo from "components/molecules/contact-info/index";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PropTypes from "prop-types";
import Styles from "./styles";

function ContactInfoBar({ email, phone, direction = "row" }) {
  return (
    <Styles direction={direction}>
      {email && (
        <ContactInfo type="mailto" contact={email} style={{ paddingInlineEnd: 24 }}>
          <MailOutlineIcon fontSize="small" />
        </ContactInfo>
      )}
      {phone && (
        <ContactInfo type="tel" contact={phone} style={{ paddingInlineEnd: 24 }}>
          <LocalPhoneIcon fontSize="small" />
        </ContactInfo>
      )}
    </Styles>
  );
}

ContactInfoBar.prototype = {
  email: PropTypes.string,
  phone: PropTypes.string,
  direction: PropTypes.oneOf(["row", "column"]),
};

export default ContactInfoBar;
