import styled from "styled-components";

const Styles = styled.div`
  button,
  a {
    white-space: nowrap;
    text-transform: capitalize;
  }
`;

export default Styles;
