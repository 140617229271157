import React, { useEffect, useState } from "react";

import Styles from "./styles";

import CustomTextField from "components/atoms/text-field";

import { HiOutlineUserCircle, HiOutlineLockClosed } from "react-icons/hi";

import CustomCheckBox from "components/atoms/check-box";

import CustomButton from "components/atoms/button";

import { useTranslation } from "react-i18next";

import { Link, useHistory } from "react-router-dom";

import { useTheme } from "@emotion/react";

import {
  getHome,
  getForgotPassword,
  getLogin,
  getCustomerSignUp,
  getMyStore,
} from "constants/navigation";

import AuthTemplate from "components/templates/auth";

import * as Yup from "yup";
import http from "components/particles/http";
import { getLoginUrl } from "components/particles/APIs/API";
import { CircularProgress } from "@mui/material";
import { AuthContext } from "components/particles/providers/auth-context";

const LoginPage = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { dispatch } = React.useContext(AuthContext);
  let history = useHistory();
  const [state, setState] = useState({
    email: "",
    password: "",
    remember: true,
    isLoading: false,
  });

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  useEffect(() => {
    return function cleanup() {};
  });

  const loginValidationSchema = Yup.object().shape({
    password: Yup.string()
      .required(t("is_required", { field: t("password") }))
      .min(6, t("password_invalid", { minChar: 8 })),
    email: Yup.string()
      .email(t("is_invalid", { field: t("email") }))
      .required(t("is_required", { field: t("email") })),
  });

  const onChangeInput = ({ target: input }) => {
    setState({ ...state, [input.name]: input.value });
    setErrors({ ...errors, [input.name]: "" });
    // for testing input
    // console.log(input.name, input.value);
  };

  const setLoading = (isLoading) => {
    setState({ ...state, isLoading: isLoading });
  };

  const doLogin = (event) => {
    event.preventDefault();
    let { email, password, remember } = state;
    // validation input data
    loginValidationSchema
      .validate({ email, password })
      .then((userCredential) => {
        console.log(userCredential);
        setLoading(true);
        // calling Api from backend to login
        http
          .post(getLoginUrl, userCredential)
          .then((response) => {
            let { data } = response.data;
            dispatch({
              type: "login",
              payload: {
                user: data.account,
                token: data.token,
                accessRole: data.account.user_type,
                remember: remember,
              },
            });
            setLoading(false);
            history.push(
              data.account.user_type === "S"
                ? `${getMyStore(data.account.username).path}`
                : getHome().path
            );
          })
          .catch((error) => {
            setLoading(false);
            let { status, data } = error.response;
            if (status === 401) {
              setErrors({ email: data.message });
            }
          });
      })
      .catch((err) => {
        setErrors({ [err.path]: err.errors[0] });
      });
  };

  return (
    <Styles theme={theme}>
      <AuthTemplate
        headerTitle={t("login")}
        pageName={t(getLogin().name)}
        routeList={[getHome()]}
        subHeader={
          <div className="create-account">
            {t("dont_have_account")}
            <Link to={getCustomerSignUp().path}>{t("register_here")}</Link>
          </div>
        }
      >
        <form onSubmit={doLogin}>
          <CustomTextField
            fullWidth
            name="email"
            label={t("email")}
            placeholder={t("email_hint")}
            value={state.email}
            onChange={onChangeInput}
            error={!!errors.email}
            helperText={errors.email}
            startAdornment={
              <HiOutlineUserCircle fontSize={24} color={theme.palette.secondary.main} />
            }
          />

          <CustomTextField
            fullWidth
            type="password"
            name="password"
            label={t("password")}
            placeholder={t("enter_password")}
            value={state.password}
            onChange={onChangeInput}
            error={!!errors.password}
            helperText={errors.password}
            startAdornment={
              <HiOutlineLockClosed fontSize={24} color={theme.palette.secondary.main} />
            }
          />

          <CustomButton
            fullWidth
            disableElevation
            style={{ marginTop: 20 }}
            type="submit"
            disabled={state.isLoading}
          >
            {state.isLoading && <CircularProgress size="16px" sx={{ margin: "0px 8px" }} />}
            {t("login")}
          </CustomButton>

          <div className="remember-container">
            <CustomCheckBox
              color="secondary"
              label={t("remember_me")}
              value={state.remember}
              onChange={(event, value) => {
                setState({ ...state, remember: value });
              }}
              defaultChecked
            />
            <Link to={getForgotPassword().path}>{t("forgot_password")}</Link>
          </div>
        </form>
      </AuthTemplate>
    </Styles>
  );
};

export default LoginPage;
