import React from "react";

const initialState = {
  currency: {
    code: "usd",
    name: "US Dollar",
    position: "start",
    symbol: "$",
  },
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setCurrency":
      console.log(action.type, action.payload);
      return {
        ...state,
        currency: action.payload,
      };
    default:
      return state;
  }
};

function AppCurrencyContextProvider(props) {
  const [state, dispatch] = React.useReducer(reducer, initialState);

  return (
    <CurrencyContext.Provider value={{ currency: state.currency, dispatch }}>
      {props.children}
    </CurrencyContext.Provider>
  );
}

export default AppCurrencyContextProvider;

export const CurrencyContext = React.createContext();
CurrencyContext.displayName = "CurrencyContext";
