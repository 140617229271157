import React from "react";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import CustomCheckBox from "components/atoms/check-box";
import QuantityButtonGroup from "../quantity-button-group";

import { useTheme } from "@emotion/react";
import Styles from "./styles";

function CartItemCard({
  productName,
  quantity,
  productPrice,
  productImages,
  isSelected,
  onSelect,
  onChangeQuantity,
  onDelete,
  maxQuantity,
}) {
  let theme = useTheme();
  return (
    <Styles theme={theme}>
      <div className="check-container">
        <CustomCheckBox label="" color="secondary" checked={isSelected} onChange={onSelect} />
      </div>
      <div className="image-container">
        <img src={productImages[0].url} alt={productName} />
      </div>
      <div className="product-name-quantity">
        <p className="product-name">{productName}</p>
        <p className="product-price">{productPrice}</p>
        <QuantityButtonGroup
          quantity={quantity}
          onChange={onChangeQuantity}
          maxQuantity={maxQuantity}
        />
      </div>

      <div className="action-container">
        {onDelete && (
          <IconButton onClick={onDelete} color="secondary">
            <CloseIcon />
          </IconButton>
        )}
      </div>
    </Styles>
  );
}

export default CartItemCard;
