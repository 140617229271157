import HomePage from "components/pages/home";
import LoginPage from "components/pages/auth/login";
import StoreRegisterPage from "components/pages/auth/store-register";
import CustomerRegisterPage from "components/pages/auth/customer-register";
import ForgotPasswordPage from "components/pages/auth/forgot-password";
import i18n from "components/particles/i18n";
import CartPage from "components/pages/cart";
import WishlistPage from "components/pages/wishlist";
import NotificationPage from "components/pages/notification";
import ProductPage from "components/pages/product";
import CategoriesPage from "components/pages/categories";
import AllStoresPage from "components/pages/all-stores";
import ProductCategoryPage from "components/pages/product-category";
import StorePage from "components/pages/store-details";
import MyProfilePage from "components/pages/profile";
import MyOrderPage from "components/pages/my-order";
import MyStorePage from "components/pages/my-store";
import StoreProductPage from "components/pages/store-product";
import StoreOrderPage from "components/pages/store-order";
import CreateProductPage from "components/pages/create-store-product";
import MyStoreBalancePage from "components/pages/my-store-balance";
import MyStoreProfilePage from "components/pages/my-store-profile";
import MyStoreNotificationPage from "components/pages/my-store-notification";
import UpdateProductPage from "components/pages/update-store-product";
import StoreOrderDetailsPage from "components/pages/store-order-details";
import MyOrderDetailsPage from "components/pages/my-order-details";
import AllProductPage from "components/pages/products-page";

/************************************************
here you can write your route as example below
createRoute("/test",MyTestComponent);
add this line to routes array and that is all
************************************************/
let Locales = i18n.options.whitelist.toString().replace(",", "|");
let prefix = `/:lng(${Locales})`;

const createRoute = (route, component, type) => ({
  path: prefix + route,
  component,
  routeType: type,
});

const routes = [
  createRoute("/", HomePage),
  createRoute("/login", LoginPage, "auth"),
  createRoute("/signup/store", StoreRegisterPage, "auth"),
  createRoute("/signup/customer", CustomerRegisterPage, "auth"),
  createRoute("/password/reset", ForgotPasswordPage),
  createRoute("/cart", CartPage),
  createRoute("/wishlist", WishlistPage),
  createRoute("/notification", NotificationPage),
  createRoute("/products/:productId", ProductPage),
  createRoute("/products", AllProductPage),
  createRoute("/categories", CategoriesPage),
  createRoute("/categories/:reference", ProductCategoryPage),
  createRoute("/stores", AllStoresPage),
  createRoute("/stores/:username", StorePage),
  createRoute("/profile", MyProfilePage),
  createRoute("/orders", MyOrderPage),
  createRoute("/orders/:orderId", MyOrderDetailsPage),

  createRoute("/mystore/:username", MyStorePage, "privateStore"),
  createRoute("/mystore/:username/products", StoreProductPage, "privateStore"),
  createRoute("/mystore/:username/products/create", CreateProductPage, "privateStore"),
  createRoute(
    "/mystore/:username/products/update/:productId",
    UpdateProductPage,
    "privateStore"
  ),
  createRoute("/mystore/:username/orders", StoreOrderPage, "privateStore"),
  createRoute("/mystore/:username/orders/:orderId", StoreOrderDetailsPage, "privateStore"),
  createRoute("/mystore/:username/balance", MyStoreBalancePage, "privateStore"),
  createRoute("/mystore/:username/profile", MyStoreProfilePage, "privateStore"),
  createRoute("/mystore/:username/notification", MyStoreNotificationPage, "privateStore"),
];

export default routes;
