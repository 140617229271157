import React from "react";
import AuthRoute from "components/particles/auth-route";
import ScrollToTop from "components/particles/hooks/scroll-top";
import PrivateStoreRoute from "components/particles/private-store-route";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import routes from "routes";

//Note: write your route in the routes module file (routes.js) - it will automaticlly be here
function Router() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          {routes.map(({ path, component, routeType }, key) => {
            if (routeType === "auth")
              return <AuthRoute exact key={key} path={path} component={component} />;
            else if (routeType === "privateStore")
              return <PrivateStoreRoute exact key={key} path={path} component={component} />;
            return <Route exact key={key} path={path} component={component} />;
          })}

          <Redirect to="/en" />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default Router;
