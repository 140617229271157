import React from "react";
import { Grid } from "@mui/material";
import PawLogo from "components/atoms/paw-logo";

import Styles from "./styles";

import pawpawLogo from "images/pawLogo.svg";
import ContactInfoBar from "components/molecules/contact-info-bar";
import {
  contactEmail,
  contactPhone,
  facebook,
  twitter,
  instagram,
} from "constants/contact-info/contact";

import SocialMediaBar from "components/molecules/social-icon-bar";

import { useTranslation } from "react-i18next";
import SeparatorLine from "components/atoms/separator";

import { informationNavList, navigationList } from "constants/navigation";
import FooterPaymentIcon from "components/atoms/payment-footer-icon";
import FooterNav from "components/molecules/footer-nav";

function Footer() {
  let { t } = useTranslation();
  return (
    <Styles>
      <Grid container className="main-container">
        <Grid item xs={12} sm={6} md={5}>
          <PawLogo src={pawpawLogo} />
          <p className="mini-desc">{t("pawpaw_short_desc")}</p>
          <ContactInfoBar email={contactEmail} phone={contactPhone} direction="column" />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <h3>{t("company")}</h3>
          <FooterNav navigationList={navigationList} />
        </Grid>
        <Grid item xs={6} sm={3} md={2}>
          <h3>{t("information")}</h3>
          <FooterNav navigationList={informationNavList} />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <h3>{t("follow_us")}</h3>
          <SocialMediaBar
            size="large"
            facebook={facebook}
            instagram={instagram}
            twitter={twitter}
          />
        </Grid>
      </Grid>
      <SeparatorLine />
      <div className="bottom-footer">
        <p>{t("copyright")}</p>
        <FooterPaymentIcon />
      </div>
    </Styles>
  );
}

export default Footer;
