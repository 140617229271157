import React from "react";
import { useTheme } from "@emotion/react";
import RubbishBinIcon from "components/atoms/rubbish-bin";
import { CurrencyContext } from "components/particles/providers/currency-context";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import CustomButton from "components/atoms/button";
import { useTranslation } from "react-i18next";
import Styles from "./styles";

function StoreProductCard({
  productId,
  productName,
  productPrice,
  productImages,
  onClickDelete,
  onClickEdit,
}) {
  let theme = useTheme();
  const { currency } = React.useContext(CurrencyContext);
  const { t } = useTranslation();
  if (!productId && !productName) {
    return (
      <Styles theme={theme}>
        <div className="proudct-header">
          <div className="product-id">{"#" + t("id")}</div>
          <div className="image-container">
            <div>{t("image")}</div>
          </div>
          <div className="product-name">{t("product_name")}</div>
          <div className="total-price-container">{t("price")}</div>
          <div className="action-container">{t("actions")}</div>
        </div>
      </Styles>
    );
  }
  return (
    <Styles theme={theme}>
      <div className="product-id">{productId}</div>
      <div className="image-container">
        <img src={productImages[0].url} alt={productName} />
      </div>
      <div className="product-name">{productName}</div>
      <div className="total-price-container">
        <span className="total-price">{productPrice}</span>
        <span className="currency-code">{currency.code.toUpperCase()}</span>
      </div>
      <div className="action-container">
        {onClickEdit && (
          <CustomButton
            color="primary"
            variant="outlined"
            className="edit-btn"
            onClick={onClickEdit}
          >
            <ModeEditIcon color="primary" />
          </CustomButton>
        )}
        {onClickDelete && (
          <CustomButton
            color="secondary"
            variant="outlined"
            className="delete-btn"
            onClick={onClickDelete}
          >
            <RubbishBinIcon />
          </CustomButton>
        )}
      </div>
    </Styles>
  );
}

export default StoreProductCard;
