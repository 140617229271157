import styled from "styled-components";

export const StyledImage = styled.img`
  border-radius: 32px;
  width: 100%;
  object-fit: cover;
`;

export const StyledHeader = styled.div`
  position: absolute;
  top: 64px;
  margin-inline-start: 64px;

  a,
  h2 {
    text-decoration: none;
    color: #383838;
    font-size: 36px;
    font-weight: 600;
    margin-block-start: 0px;
    margin-bottom: 0px;
  }
  p {
    color: #a7a7a7;
    font-size: 18px;
    max-width: 60%;
    margin-block-start: 0px;
  }
`;

export const StyledHeaderWithButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 16%;

  h2 {
    text-decoration: none;
    color: white;
    font-size: 36px;
    font-weight: 600;
    margin-block-start: 0px;
    margin-bottom: 0px;
    text-align: center;
  }
  a {
    color: white;
    &:hover {
      background-color: ${(props) => props.theme.palette.primary.dark};
    }
  }
  p {
    color: #a7a7a7;
    font-size: 18px;
    max-width: 60%;
    margin-block-start: 0px;
    text-align: center;
  }
`;
