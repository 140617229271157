import styled from "styled-components";

const Styles = styled.div`
  margin-bottom: 24px;
  .trigger {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    span {
      margin-inline-start: 8px;
    }
    margin-bottom: 8px;
  }
  .not-seen {
    color: ${(props) => props.theme.palette.secondary.main};
  }
  .seen {
    color: ${(props) => props.theme.palette.primary.main};
  }
  .clickable {
    cursor: pointer;
  }
`;

export default Styles;
