import React, { useContext } from "react";

import Styles from "./styles";

import LowerNavbar from "components/molecules/lower-navbar";

import UpperNavbar from "components/molecules/upper-navbar/index";

import SeparatorLine from "components/atoms/separator/index";

import { AuthContext } from "components/particles/providers/auth-context";
import {
  getHome,
  getLogin,
  getMyProfile,
  getMyStore,
  getWishlist,
} from "constants/navigation";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { ShoppingCartContext } from "components/particles/providers/cart-context";

function Navbar() {
  const { isAuthenticated, user, dispatch } = useContext(AuthContext);
  const { cartItemsCount } = useContext(ShoppingCartContext);
  let { name: loginPageName, path: loginPath } = getLogin();
  const { t } = useTranslation();
  let history = useHistory();

  const logout = () => {
    dispatch({
      type: "logout",
    });
    history.replace(getHome().path);
  };

  const accountMenuItems = [
    {
      title: t("my_profile"),
      action: () => {
        history.push(getMyProfile().path);
      },
    },
    {
      title: t("wishlist"),
      action: () => {
        history.push(getWishlist().path);
      },
    },
    {
      title: t("logout"),
      action: logout,
    },
  ];

  const storeMenuItems = [
    {
      title: t("my_store"),
      action: () => {
        history.push(`${getMyStore(user.username).path}`);
      },
    },
    {
      title: t("logout"),
      action: logout,
    },
  ];

  // console.log("this is navbar", isAuthenticated);

  return (
    <Styles>
      <UpperNavbar
        accountMenuTitle={isAuthenticated ? user.name : t(loginPageName)}
        accountMenuLink={isAuthenticated ? null : loginPath}
        accountMenuItems={user && user.user_type === "S" ? storeMenuItems : accountMenuItems}
      />
      <SeparatorLine />
      <LowerNavbar isAuthenticated={isAuthenticated} cartItemsCount={cartItemsCount} />
    </Styles>
  );
}

export default Navbar;
