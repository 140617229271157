import React from "react";
import { useTheme } from "@emotion/react";
import { BsCheck } from "react-icons/bs";
import Styles from "./styles";
function CustomMultiSelect({ value, optionList, onChange, label, error, helperText }) {
  const theme = useTheme();
  return (
    <Styles theme={theme} error={error}>
      <div className="label">{label}</div>
      <div className="select">
        {optionList.map((option, index) => {
          return (
            <div
              key={index}
              onClick={() => onChange(option.value)}
              onKeyDown={() => onChange(option.value)}
              role="button"
              tabIndex={0}
              className="option"
            >
              {option.name}
              {value.includes(option.value) ? <BsCheck /> : null}
            </div>
          );
        })}
      </div>
      <div className="helper-text">{helperText}</div>
    </Styles>
  );
}

export default CustomMultiSelect;
