import React from "react";
import { getCustomersUrl } from "components/particles/APIs/API";
import { useTranslation } from "react-i18next";

import http from "components/particles/http";
import toastError from "components/particles/toast/error";
import Styles from "./styles";

import { getHome, getMyOrderDetails, getMyOrders } from "constants/navigation";
import { useParams } from "react-router-dom";
import UserProfileTemplate from "components/templates/profile";
import { AuthContext } from "components/particles/providers/auth-context";
import ProductOrderItem from "components/molecules/product-order-item";

function MyOrderDetailsPage() {
  const [state, setState] = React.useState({
    productList: [],
  });
  const { user } = React.useContext(AuthContext);
  const { t } = useTranslation();
  const { orderId } = useParams();

  // get order Info
  React.useEffect(() => {
    http
      .get(`${getCustomersUrl}/${user.id}/orders/${orderId}/products`)
      .then((response) => {
        let { list } = response.data.data;
        console.log(list);
        setState((prevState) => ({ ...prevState, productList: list || [] }));
      })
      .catch(() => {
        toastError("an_error_happens");
      });
  }, [orderId, user.id]);

  return (
    <UserProfileTemplate
      routeList={[getHome(), getMyOrders()]}
      pageName={t(getMyOrderDetails().name)}
    >
      <Styles>
        <h1>{t("order_items")}</h1>

        {state.productList.map((orderItem, index) => {
          return (
            <ProductOrderItem
              key={index}
              imageUrl={orderItem.product.pictures[0].url}
              name={orderItem.product.name}
              status={orderItem.status.name}
            />
          );
        })}
      </Styles>
    </UserProfileTemplate>
  );
}

export default MyOrderDetailsPage;
