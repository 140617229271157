import React from "react";
import { getStoresUrl } from "components/particles/APIs/API";
import StoreDashboardTemplate from "components/templates/store-dashboard";
import { useTranslation } from "react-i18next";
import http from "components/particles/http";
import Styles from "./styles";
import { Pagination } from "@mui/material";
import paggination from "constants/paggination";
import StoreBalanceStack from "components/organisms/store-balance-stack";

function MyStoreBalancePage() {
  const [state, setState] = React.useState({
    currentStore: null,
    balanceList: [],
    pageCount: 1,
    currentPage: 1,
  });
  const { t } = useTranslation();

  React.useEffect(() => {
    let currentStore = state.currentStore;
    let params = {
      count: paggination.pageSize,
      page: state.currentPage,
    };
    if (currentStore) {
      http
        .get(`${getStoresUrl}/${currentStore.id}/balance`, { params })
        .then((response) => {
          let { data } = response.data;
          console.log(data);
          setState((prevState) => ({
            ...prevState,
            balanceList: data.list || [],
            currentPage: data.current_page,
            pageCount: data.current_page + data.next_pages_count,
          }));
        })
        .then((error) => {
          console.log(error);
        });
    }
  }, [state.currentStore, state.currentPage]);

  const handlePageChange = (event, page) => {
    setState({ ...state, currentPage: page });
  };

  const updateCurrentStore = (store) => {
    setState((prevState) => ({ ...prevState, currentStore: store }));
  };

  return (
    <StoreDashboardTemplate onLoadStore={updateCurrentStore}>
      <Styles>
        <h1>{t("balance")}</h1>

        <StoreBalanceStack balanceList={state.balanceList} />

        {state.pageCount !== 1 && (
          <div className="paggination-container">
            <Pagination
              color="primary"
              count={state.pageCount}
              variant="outlined"
              shape="rounded"
              page={state.currentPage}
              onChange={handlePageChange}
            />
          </div>
        )}
      </Styles>
    </StoreDashboardTemplate>
  );
}

export default MyStoreBalancePage;
