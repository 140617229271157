import React from "react";
import { getHome } from "constants/navigation";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../providers/auth-context";

const AuthRoute = ({ component: Component, render, ...rest }) => {
  const { isAuthenticated } = React.useContext(AuthContext);
  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAuthenticated)
          return (
            <Redirect
              to={{
                pathname: getHome().path,
                state: { from: props.location },
              }}
            />
          );
        return Component ? <Component {...props} /> : render(props);
      }}
    />
  );
};

export default AuthRoute;
