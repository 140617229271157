import React from "react";

import { useTranslation } from "react-i18next";

import StarIcon from "@mui/icons-material/Star";

import { useTheme } from "@emotion/react";

import Styles from "./styles";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";

function NewArrivalCard({
  productImage,
  productName,
  productPrice,
  productRate,
  productSoldCount,
  to = "",
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Styles theme={theme}>
      <div className="image-container">
        <div className="circle">{t("new")}</div>
        <Link to={to}>
          <img src={productImage} alt={productName} className="product-img" />
        </Link>
      </div>
      <div className="product-info">
        <Tooltip title={productName}>
          <Link to={to} className="product-link">
            {productName.length > 50 ? productName.substring(0, 50) + ".." : productName}
          </Link>
        </Tooltip>

        <p className="product-price">{productPrice}</p>
        <div className="product-rate-sold">
          <StarIcon fontSize="small" style={{ color: "#FFD687" }} />
          {productRate} | {t("sold")} {productSoldCount}
        </div>
      </div>
    </Styles>
  );
}

export default NewArrivalCard;
