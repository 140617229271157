import { Avatar } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Styles from "./styles";

function StoreCard({ avatar, avatarWidth = 160, name, username, to }) {
  return (
    <Styles>
      <Link to={to}>
        <Avatar
          src={avatar}
          alt={name}
          sx={{
            width: avatarWidth,
            height: avatarWidth,
            fontSize: 32,
          }}
        >
          {name.charAt(0)}
        </Avatar>
      </Link>
      <Link to={to} className="name">
        {name}
      </Link>
      <p className="username">@{username}</p>
    </Styles>
  );
}

export default StoreCard;
