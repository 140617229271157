import React, { useEffect, useState } from "react";
import { useTheme } from "@emotion/react";
import Styles from "./styles";

import { getAllProducts } from "components/particles/APIs/API";
import http from "components/particles/http";

import { CurrencyContext } from "components/particles/providers/currency-context";
import { useTranslation } from "react-i18next";

import NewArrivalProductStack from "components/molecules/new-arrival-product-stack";
import paggination from "constants/paggination";
import { getProducts } from "constants/navigation";
import { Link } from "react-router-dom";
function HomeNewArrival() {
  const [state, setState] = useState({
    productList: [],
  });

  const { t } = useTranslation();
  const { currency } = React.useContext(CurrencyContext);
  const theme = useTheme();

  useEffect(() => {
    http
      .get(getAllProducts, {
        params: {
          sort_by: "newest",
          enabled_only: true,
          order: "desc",
          page: 1,
          count: paggination.pageSize,
        },
      })
      .then((response) => {
        let { list } = response.data.data;
        setState((prevState) => ({ ...prevState, productList: list }));
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Styles theme={theme}>
      <div className="new-arrival-header">
        <h1>{t("new_arrival")}</h1>
        <Link to={`${getProducts().path}?sort=newest`}>{t("view_all")}</Link>
      </div>
      <NewArrivalProductStack productList={state.productList} currency={currency} />
    </Styles>
  );
}

export default HomeNewArrival;
