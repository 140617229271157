import { getProducts } from "constants/navigation";
import React from "react";

import NewArrivalCard from "../new-arrival-card";

import StyledStack from "./styles";

function NewArrivalProductStack({ productList, currency }) {
  // function to change price formate base on currency position
  const addCurrencyFormatPrice = (currency, price) => {
    switch (currency.position) {
      case "start":
        return currency.symbol + price;

      default:
        return price + currency.symbol;
    }
  };
  return (
    <StyledStack direction="row" spacing={1}>
      {productList.map((product, index) => {
        return (
          <NewArrivalCard
            key={index}
            to={getProducts().path + "/" + product.id}
            productImage={product.pictures[0].url}
            productName={product.name}
            productPrice={addCurrencyFormatPrice(currency, product.final_price)}
            productRate={product.rating}
            productSoldCount={product.solded_quantity}
          />
        );
      })}
    </StyledStack>
  );
}

export default NewArrivalProductStack;
