import { SwipeableDrawer } from "@mui/material";
import { styled } from "@mui/system";

const CustomSwipeableDrawer = styled(SwipeableDrawer)({
  "& .MuiPaper-root ": {
    width: 260,
    justifyContent: "center",
  },
  "& .close-button": {
    position: "absolute",
    top: 16,
    left: 16,
  },
});

export default CustomSwipeableDrawer;
