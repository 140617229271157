import React from "react";
import { IconButton, TextField } from "@mui/material";

import Styles from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";
// import { useTranslation } from "react-i18next";
import RubbishBinIcon from "components/atoms/rubbish-bin";
import CustomCheckBox from "components/atoms/check-box";

function WishlistItemCard({
  productName,
  quantity,
  productPrice,
  totalPrice,
  productImages,
  currencyCode,
  onChangeQuantity,
  onDelete,
  maxQuantity,
  isSelected,
  onSelect,
}) {
  // const { t } = useTranslation();
  let theme = useTheme();
  return (
    <Styles theme={theme}>
      <div className="check-container">
        <CustomCheckBox color="secondary" label="" checked={isSelected} onChange={onSelect} />
      </div>
      <div className="image-container">
        <img src={productImages[0].url} alt={productName} />
      </div>
      <div className="product-name-quantity">
        <p className="product-name">{productName}</p>
        <div className="quantity-space">
          <TextField
            size="small"
            color="secondary"
            type="number"
            sx={{ width: 80 }}
            value={quantity}
            onChange={onChangeQuantity}
            inputProps={{ min: 1, max: maxQuantity, className: "withButton" }}
          />
          <CloseIcon sx={{ fontSize: 20, padding: 1, color: "#A7A7A7" }} />
          <span className="product-price">{productPrice}</span>
        </div>
      </div>
      <div className="total-price-container">
        <span className="total-price">{totalPrice}</span>
        <span className="currency-code">{currencyCode}</span>
      </div>
      <div className="action-container">
        {onDelete && (
          <IconButton color="secondary" onClick={onDelete}>
            <RubbishBinIcon />
          </IconButton>
        )}
      </div>
    </Styles>
  );
}

export default WishlistItemCard;
