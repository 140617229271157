import styled from "styled-components";

const Styles = styled.div`
  display: flex;
  flex-direction: column;
  a {
    color: #383838;
    text-decoration: none;
    margin-bottom: 16px;
  }
`;

export default Styles;
