import styled from "styled-components";

const Styles = styled.div`
  main {
    width: 90%;
    margin: auto;
  }
`;

export default Styles;
