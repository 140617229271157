import React from "react";
import { useTheme } from "@emotion/react";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Styles from "./styles";

function NotificationCard({ user, seen, event, onClick }) {
  const theme = useTheme();
  return (
    <Styles theme={theme}>
      <div
        className={`${onClick ? "trigger clickable" : "trigger"}`}
        onClick={onClick}
        role="button"
        tabIndex={-1}
        onKeyDown={onClick}
      >
        <AccountCircleIcon /> <span>{user}</span>
      </div>
      <div className={seen ? "seen" : "not-seen"}>{event}</div>
    </Styles>
  );
}

export default NotificationCard;
