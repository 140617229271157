import styled from "styled-components";

const Styles = styled.div`
  & > a {
    display: flex;
    align-items: center;
    color: ${(props) => props.color};
    padding: 4px 12px;
  }
`;

export default Styles;
