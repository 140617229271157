import React from "react";
import WishlistItemCard from "components/molecules/wishlist-item";
import { StyledStack } from "./styles";
import { useTranslation } from "react-i18next";
import CustomButton from "components/atoms/button";
import CustomCheckBox from "components/atoms/check-box";

function WishlistStackItem({ wishlistItem, currency, onDeleteItem, changeQuantityHandler }) {
  const [state, setState] = React.useState({
    selectedItemsId: [],
    allItemSelected: false,
  });
  const { t } = useTranslation();

  const addCurrencyFormatPrice = (price) => {
    switch (currency.position) {
      case "start":
        return currency.symbol + price;

      default:
        return price + currency.symbol;
    }
  };

  const selectAll = (event, checked) => {
    if (checked) {
      let itemIds = [];
      wishlistItem.forEach((item) => {
        itemIds.push(item.id);
        setState({
          ...state,
          allItemSelected: true,
          selectedItemsId: itemIds,
        });
      });
    } else setState({ ...state, allItemSelected: false, selectedItemsId: [] });
  };

  const setSelected = (event, isNotSelected, itemId) => {
    let selectedIds = [...state.selectedItemsId];
    if (isNotSelected) {
      selectedIds.push(itemId);
      setState({
        ...state,
        selectedItemsId: selectedIds,
      });
      return;
    } else {
      selectedIds = selectedIds.filter((id) => id !== itemId);
      setState({ ...state, allItemSelected: false, selectedItemsId: selectedIds });
    }
  };

  const deleteSelectedHandler = () => {
    let selectedList = [...state.selectedItemsId];
    selectedList.forEach((item) => {
      onDeleteItem(item);
    });
    setState({ ...state, selectedItemsId: [] });
  };

  if (wishlistItem.length === 0) return null;
  // else
  return (
    <StyledStack direction="column">
      <div className="action-header">
        <CustomCheckBox
          label={t("select_all")}
          checked={state.allItemSelected}
          color="secondary"
          onChange={selectAll}
        />
        <CustomButton
          size="small"
          color="error"
          disabled={state.selectedItemsId.length === 0}
          onClick={deleteSelectedHandler}
        >
          {t("delete_selected")}
        </CustomButton>
      </div>
      <div className="data-table">
        {wishlistItem.map((item, index) => {
          return (
            <WishlistItemCard
              key={index}
              productName={item.product.name}
              quantity={item.quantity}
              isSelected={state.selectedItemsId.includes(item.id)}
              onSelect={(e, value) => setSelected(e, value, item.id)}
              productPrice={addCurrencyFormatPrice(item.price)}
              totalPrice={item.total_price}
              currencyCode={currency.code.toUpperCase()}
              maxQuantity={item.product.quantity}
              productImages={item.product.pictures}
              onChangeQuantity={({ target: input }) =>
                changeQuantityHandler(input.value, item.quantity, item.id)
              }
              onDelete={() => onDeleteItem(item.id)}
            />
          );
        })}
      </div>
    </StyledStack>
  );
}

export default WishlistStackItem;
